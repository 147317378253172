import axios from 'axios'
import { Loading, Message } from 'element-ui'
import store from '@/store'

// create an axios instance
const service = axios.create({
	baseURL: process.env.VUE_APP_BASE_URL2, // url = base url + request url
	withCredentials: true, // send cookies when cross-domain requests
	timeout: 120000, // request timeout
	headers: {
		'Access-Control-Allow-Origin': '*',
		'Access-Control-Allow-Headers':
			'Authorization,Origin, X-Requested-With, Content-Type, Accept',
		'Access-Control-Allow-Methods': 'GET,POST,PUT,DELETE',
		'Client-Type': 'seller/pc'
	}
})

// const excelUploadService = axios.create({
//   baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
//   withCredentials: true, // send cookies when cross-domain requests
//   timeout: 120000, // request timeout
//   headers: {
//     'Access-Control-Allow-Origin': '*',
//     'Access-Control-Allow-Headers':
//       'Authorization,Origin, X-Requested-With, Content-Type, Accept',
//     'Access-Control-Allow-Methods': 'GET,POST,PUT,DELETE',
//     'Client-Type': 'seller/pc'
//   }
// })

const handleNetworkRequest = {
	loadingInstance: undefined,
	openLoading: function(config) {
		if (config.showLoading) {
			this.loadingInstance = Loading.service({ fullscreen: true })
		}
	},
	closeLoading: function(config) {
		if (!config.showLoading) return
		const requestTime = config.requestEnd - config.requestStart
		// 避免接口响应太快 导致不加载 loading动效
		if (requestTime && requestTime < 100) {
			setTimeout(() => {
				this.loadingInstance.close()
			}, 200)
		} else {
			this.loadingInstance.close()
		}
	},
	changeRequestStatusObj: function(flag, config) {
		if (!config.requestStatusObj) return
		const requestTime = config.requestEnd - config.requestStart
		// requestStatusObj格式
		// {
		//   loading: false
		// }
		// 避免接口响应太快 导致不加载 loading动效
		if (requestTime && requestTime < 100) {
			setTimeout(() => {
				config.requestStatusObj.loading = flag
			}, 200)
		} else {
			config.requestStatusObj.loading = flag
		}
		// config.requestStatusObj && console.log(config, config.requestStatusObj, config.requestEnd - config.requestStart)
	},
	requestCallback: function(config) {
		config.requestStart = new Date()
		this.openLoading(config)
		this.changeRequestStatusObj(true, config)
	},
	requestEndCallback: function(config, flag, result) {
		switch (flag) {
			case 'succeed':
				break
			case 'apiError':
				break
			case 'error':
				// 登录失效，自动退出登录
				if (result.response && result.response.status === 401) {
					setTimeout(() => {
						localStorage.clear()
						location.reload()
						// this.$store.commit('user/LOGOUT')
					}, 1000)
				}
				break
		}
		config.requestEnd = new Date()
		this.closeLoading(config)
		this.changeRequestStatusObj(false, config)
	}
}

// request interceptor
service.interceptors.request.use(
	config => {
		config.headers.xToken =  store.state.user.token || sessionStorage.getItem("token");
		if (!config.headers.xToken) {
			delete config.headers.xToken
		}
		const handelNull = function(item) {
			if (Array.isArray(item)) {
				item.forEach((i, index) => {
					if (item[index] === null) {
						item[index] = undefined
					} else {
						handelNull(i)
					}
				})
			} else if (typeof item === 'object') {
				Object.keys(item).forEach((key) => {
					if (item[key] === null) {
						item[key] = undefined
					} else {
						handelNull(item[key])
					}
				})
			} else if (item === null) {
				item = undefined
			}
		}
		if (config.data && Object.prototype.toString.call(config.data) === '[object Object]') {
			handelNull(config.data)
			// 处理undefined
			config.data = JSON.parse(JSON.stringify(config.data))
		}
		handleNetworkRequest.requestCallback(config)
		// console.log('请求参数------------', config)
		return config
	},
	error => {
		// do something with request error
		// console.log(error) // for debug
		return Promise.reject(error)
	}
)

// response interceptor
service.interceptors.response.use(
	async response => {
		// console.log('请求响应------------', response)
		let res = response.data
		if (res && typeof res !== 'object') {
			res = JSON.parse(res)
		}

		const parseBlobJsonData = async(value) => {
			const result = await value
			return JSON.parse(result)
		}

		if (Object.prototype.toString.call(res) === '[object Blob]' && res.type === 'application/json') {
			res = await parseBlobJsonData(res.text())
		}
		// console.log(response.config.responseType, res.flag, !res.flag, !response.config.responseType)
		// 处理错误
		if (Object.prototype.toString.call(res) === '[object Object]' && (response.status !== 200 || !res.flag)) {
			// return res
			// } else {
			Message({
				message: res.msg || res.message,
				type: 'error',
				duration: 5 * 1000
			})
			handleNetworkRequest.requestEndCallback(response.config, 'apiError', response.data)
			return Promise.reject(res.msg || res.message)
		} else {
			handleNetworkRequest.requestEndCallback(response.config, 'succeed', response.data)
			return res
		}
	},
	error => {
		// console.dir('请求错误------------')
		// console.dir(error)
		let errorMessage = '未知错误'
		/** *** 接收到异常响应的处理开始 *****/
		if (error && error.response) {
			// 1.公共错误处理
			// 2.根据响应码具体处理
			switch (error.response.status) {
				case 401:
					errorMessage = '未授权，请重新登录'
					break
				case 403:
					errorMessage = '拒绝访问'
					break
				case 404:
					errorMessage = '请求错误,未找到该资源'
					break
				case 405:
					errorMessage = '请求方法未允许'
					break
				case 408:
					errorMessage = '请求超时'
					break
				case 500:
					errorMessage = '服务器端出错'
					break
				case 501:
					errorMessage = '网络未实现'
					break
				case 502:
					errorMessage = '网络错误'
					break
				case 503:
					errorMessage = '服务不可用'
					break
				case 504:
					errorMessage = '网络超时'
					break
				case 505:
					errorMessage = 'http版本不支持该请求'
					break
				default:
					errorMessage = `连接错误${error.response.status}`
			}
		} else {
			// 超时处理
			if (JSON.stringify(error).includes('timeout')) {
				errorMessage = '服务器响应超时，请刷新当前页'
			}
			// errorMessage('连接服务器失败')
		}
		handleNetworkRequest.requestEndCallback(error.config, 'error', error)
		Message({
			message: errorMessage,
			type: 'error',
			duration: 5 * 1000
		})
		/** *** 处理结束 *****/
		return Promise.reject(error.response && error.response.data)
	}
)

// export { excelUploadService }
export default service
