// 全局权限
import store from '../store'
// console.log( store.getters.roleType)
// console.log(sessionStorage.getItem('roleType'))
let roleType = store.getters.roleType || sessionStorage.getItem('roleType')
// console.log(roleType)
export default function hasPermission(permId){
    // console.log(permId,roleType)
    if(roleType == 0) {
    //    console.log(permId)
        return true
    }else{
        let boolen = false;
        let permission = store.getters.menuBtnList
        // console.log(permission)
        if(permission.some(n => n.menuCode == permId)){
            // console.log(permId)
            boolen = true
        }
        if(permission.some(n => n.menuCode == "SupplierVerificationSubmit")){
            // console.log("11111")
        }else{
            // console.log("2222")
        }
        return boolen;
    }
    
};
