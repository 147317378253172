import service from "../utils/request";
let baseUrl = process.env.VUE_APP_BASE_URL2;
// 登录
export function login(data) {
  return service({
    // url: `${baseUrl}/sellercenter/digita/camp/login/v263`,
    // url: `${baseUrl}/digita/camp/login/v263`,
    url: `${baseUrl}/sellercenter/smart/login/v276`,
    method: "POST",
    data: data
  });
}

//修改登录密码
export function Modify (params) {
  return service({
    url: `${baseUrl}/sellercenter/update/pwd/v254`,
    method: "POST",
    data: params
  });
}


//个人入驻
export function personRegister(params) {
  return service({
    url: `${baseUrl}/sellercenter/insertPerApplyMerchant/v247`,
    method: "POST",
    data: params
  });
}

//企业入驻
export function insertEntApplyMerchant(params) {
  return service({
    url: `${baseUrl}/sellercenter/insertEntApplyMerchant/v247`,
    method: "POST",
    data: params
  });
}

//获取验证码
export function getMerchant(params) {
  return service({
    url: `${baseUrl}/sellercenter/getMerchantCode`,
    method: "POST",
    data: params
  });
}

// //获取验证码
// export function getMerchant(params){
//   return
//   http.post(`${baseurl}/sellercenter/getMerchantCode`,params)
// }

// //重置密码的身份验证
// export function forgetNextStep(params){
//   return http.post(`${baseurl}/sellercenter/v/forgetNextStep`,params)
// }

//重置密码的身份验证
export function forgetNextStep(params) {
  return service({
    url: `${baseUrl}/sellercenter/v/forgetNextStep`,
    method: "POST",
    data: params
  });
}

//重置密码的确认接口
export function updatePwd(params) {
  return service({
    url: `${baseUrl}/sellercenter/v/updatePwd`,
    method: "POST",
    data: params
  });
}

//重置密码的确认接口
// export function updatePwd(params){
//   return http.post(`${baseurl}/sellercenter/v/updatePwd`,params)
// }

//注册账户接口
// export function userRegister(params){
//   return http.post(`${baseurl}/sellercenter/register`,params)
// }

//注册账户接口
export function userRegister(params) {
  return service({
    url: `${baseUrl}/sellercenter/register`,
    method: "POST",
    data: params
  });
}

//路由
export function adminRoute(params) {
  return service({
    url: `${baseUrl}/sellercenter/queryLeftMenu`,
    method: "POST",
    data: params
  });
}
//路由
// export function adminRoute(params){
//   return http.post(`${baseurl}/sellercenter/queryLeftMenu`,params)
// }
