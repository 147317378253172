<template>
  <div class="main">
    <div class="index-top" :class="'index-top' + shopType">
      <div
        class="index-top-li tbg1"
        ref="indexTopLi"
        :style="{ height: clientHeight + 'px' }"
        @click="go('net', 1)"
      >
        <p>{{ orderData.pendingPaymentCount }}</p>
        <span>待付款</span>
      </div>
      <div
        class="index-top-li tbg2"
        :style="{ height: clientHeight + 'px' }"
        @click="go('net', 2)"
      >
        <p>{{ orderData.toBeShippedCount }}</p>
        <span>待配送/待发货</span>
      </div>
      <div
        class="index-top-li tbg3"
        :style="{ height: clientHeight + 'px' }"
        @click="go('net', 3)"
      >
        <p>{{ orderData.pendingRefundCount }}</p>
        <span>待退款/售后</span>
      </div>
      <div
        class="index-top-li tbg4"
        :style="{ height: clientHeight + 'px' }"
        @click="go('net', 4)"
      >
        <p>{{ orderData.toBeEvaluatedCount }}</p>
        <span>待评价</span>
      </div>
      <div
        class="index-top-li tbg5"
        :style="{ height: clientHeight + 'px' }"
        @click="go('net', 5)"
      >
        <p>{{ orderData.logisticsAnomalyCount }}</p>

        <span>配送/物流异常</span>
      </div>
    </div>
    <div class="index-chart">
      <div class="index-chart-tabs">
        <div
          class="index-chart-tab"
          :class="{ tabActive: currTab == 1 }"
          @click="tabClick(1)"
        >
          实时数据
        </div>
        <div
          class="index-chart-tab"
          :class="{ tabActive: currTab == 2 }"
          @click="tabClick(2)"
        >
          经营状况
        </div>
      </div>
      <div class="index-chart-inner">
        <div class="index-chart-li">
          <div id="linechart1"></div>
          <div id="linechart2"></div>
          <div id="linechart3"></div>
          <div id="linechart4"></div>
        </div>
        <!-- <div class="index-chart-li" v-show="currTab == 2">
          <div id="linechart5" ></div>
          <div id="linechart6" ></div>
          <div id="linechart7" ></div>
          <div id="linechart8" ></div>
        </div> -->
      </div>
    </div>
    <div class="index-mess">
      <div class="index-mess-li">
        <img src="@/assets/icon/m1.png" alt="" />
        <div>
          <p>今日访客</p>
          <span>{{ viewTotal }}</span>
        </div>
      </div>
      <div class="index-mess-li">
        <img src="@/assets/icon/m2.png" alt="" />
        <div>
          <p>你店铺近30天交易金额</p>
          <span>￥{{ monthPayMoney }}</span>
        </div>
      </div>
      <div class="index-mess-li">
        <img src="@/assets/icon/m3.png" alt="" />
        <div>
          <p>今日异常</p>
          <span>{{ todayError }}</span>
        </div>
      </div>
      <div class="index-mess-li">
        <img src="@/assets/icon/m4.png" alt="" />
        <div>
          <p>昨日成交量</p>
          <el-tooltip
            class="panel-btn panel-btn-prey"
            effect="dark"
            :content="'昨日成交金额:' + yesterdaySingularMoney"
            placement="bottom-start"
          >
            <span style="cursor: pointer">{{ yesterdayDeal }}</span>
          </el-tooltip>
        </div>
      </div>
    </div>
    <div class="index-financial">
      <div class="index-financial-left">
        <div class="index-financial-tit">资金中心</div>
        <div class="index-financial-spending">
          <div class="index-financial-spending-num">
            <span
              v-if="showbalance"
              @click="goReconciliation"
              style="cursor: pointer"
              >{{ todayPYmoney }}</span
            >
            <span v-else>********</span>
          </div>
          <div class="divShowbalance">
            <span>今日交易额(元)</span>
            <div @click="showbalance = !showbalance">
              <img v-if="showbalance" src="@/assets/icon/eye2.png" alt="" />
              <img v-else src="@/assets/icon/eye1.png" alt="" />
            </div>
          </div>
        </div>
        <div class="index-financial-link">
          <div class="index-financial-link-li">
            <img src="~@/assets/icon/mo1.png" alt="" />
            <el-tooltip
              class="panel-btn panel-btn-prey"
              effect="dark"
              content="功能正在开发中"
              placement="top-start"
            >
              <el-button>转账</el-button>
            </el-tooltip>
          </div>
          <div class="index-financial-link-li">
            <img src="~@/assets/icon/mo2.png" alt="" />
            <span>提现</span>
            <!-- <router-link to="/financial/account">提现</router-link> -->
          </div>
          <div class="index-financial-link-li">
            <img src="~@/assets/icon/mo3.png" alt="" />
            <span>交易记录</span>
            <!-- <router-link to="/financial/reconciliation">交易记录</router-link> -->
          </div>
        </div>
      </div>
      <div class="index-financial-right">
        <div class="index-financial-moo">
          <div>
            <div>
              <span>总资产</span>
              <div @click="showAll = !showAll">
                <img v-if="showAll" src="@/assets/icon/eye2.png" alt="" />
                <img v-else src="@/assets/icon/eye1.png" alt="" />
              </div>
            </div>
            <div class="spanshow">
              <span v-if="showAll" class="ck3"
                >￥{{
                  fundCenterData.totalAmount == ""
                    ? 0
                    : fundCenterData.totalAmount
                }}</span
              >
              <span class="ck3" v-else>********</span>
            </div>
          </div>
        </div>
        <div class="index-financial-moo">
          <div>
            <div>
              <span>总负债</span>
              <div @click="showLiabilities = !showLiabilities">
                <img
                  v-if="showLiabilities"
                  src="@/assets/icon/eye2.png"
                  alt=""
                />
                <img v-else src="@/assets/icon/eye1.png" alt="" />
              </div>
            </div>
            <div class="spanshow">
              <span v-if="showLiabilities" class="ck3"
                >￥{{
                  fundCenterData.totalLiability == ""
                    ? 0
                    : fundCenterData.totalLiability
                }}</span
              >
              <span class="ck3" v-else>********</span>
            </div>
          </div>
        </div>
        <div class="index-financial-moo">
          <div>
            <div>
              <span>在线订单</span>
              <div @click="showOnline = !showOnline">
                <img v-if="showOnline" src="@/assets/icon/eye2.png" alt="" />
                <img v-else src="@/assets/icon/eye1.png" alt="" />
              </div>
            </div>
            <div class="spanshow">
              <span class="ck3" v-if="showOnline">{{ onlineOrder }}</span>
              <span class="ck3" v-else>********</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="index-audit">
      <div class="index-audit-head">
        <div class="index-audit-tit">我的审核任务</div>
        <div class="index-audit-btn">
          <div class="index-audit-btn1">进度查询</div>
          <div class="index-audit-btn2">进度办理</div>
        </div>
      </div>
      <div class="index-audit-inner">
        <div class="index-audit-tip">
          <img src="~@/assets/icon/Illustration.png" alt="" />
          <span>此功能暂未开放</span>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import {
  realTimeData,
  yoRealTimeData,
  orderStatusStatistics,
  fundCenter,
  yoOrderStatusStatistics,
} from "@/api/yiSan/yiSanAPI";
export default {
  data() {
    return {
      clientHeight: null, //indexTopLi高度
      lh: 48, //indexTop 行高
      currTab: 1,
      showbalance: false,
      showAll: false,
      showLiabilities: false,
      showOnline: false,
      shopNo: this.$store.getters.shopNo,
      shopType: this.$store.getters.shopType,
      orderData: {},
      todayPYmoney: "",
      onlineOrder: "",
      yesterdaySingularMoney: "",
      realTime: {
        fks: {
          title: [
            {
              text: null,
              subtext: null,
              left: "center",
              top: "12%",
              textStyle: {
                fontSize: 18,
                color: "#333333",
                fontWeight: "normal",
              },
              subtextStyle: {
                fontSize: 18,
                color: "#1955FA",
              },
            },
            {
              text: null,
              subtext: null,
              left: "8%",
              bottom: "50px",
              textStyle: {
                fontSize: 16,
                color: "#1955FA",
                fontWeight: "normal",
              },
              subtextStyle: {
                fontSize: 16,
                color: "#FFD110",
              },
            },
          ],
          color: "#1955FA", //["#1955FA",'#FFD110'],
          tooltip: {},
          grid: {
            top: "28px",
            left: "10%",
            right: "10%",
            bottom: "130px",
          },
          xAxis: {
            type: "category",
            boundaryGap: false,
            showMaxLabel: true,
            axisLabel: {
              show: true,
              textStyle: {
                color: "#333333",
                fontSize: "18", //字体大小
              },
              showMaxLabel: true,
              interval: 7,
            },
            splitLine: {
              show: false,
            },
            axisLine: {
              lineStyle: {
                color: "rgba(224, 225, 226, 1)",
              },
            },
            offset: 13,
            data: [],
          },
          yAxis: [
            {
              type: "value",
              axisLabel: {
                show: true,
                textStyle: {
                  color: "#333333",
                  fontSize: "14", //字体大小
                },
              },
              axisLine: {
                lineStyle: {
                  color: "rgba(224, 225, 226, 1)",
                },
              },
              splitLine: {
                show: false,
              },
              show: false,
              max(value) {
                return value.max * 1.6;
              },
            },
          ],
          series: [],
        },
        tkje: {
          title: [
            {
              text: null,
              subtext: null,
              left: "center",
              top: "12%",
              textStyle: {
                fontSize: 18,
                color: "#333333",
                fontWeight: "normal",
              },
              subtextStyle: {
                fontSize: 18,
                color: "#1955FA",
              },
            },
            {
              text: null,
              subtext: null,
              left: "8%",
              bottom: "50px",
              textStyle: {
                fontSize: 16,
                color: "#1955FA",
                fontWeight: "normal",
              },
              subtextStyle: {
                fontSize: 16,
                color: "#FFD110",
              },
            },
          ],
          color: "#1955FA", //["#1955FA",'#FFD110'],
          grid: {
            top: "28px",
            left: "10%",
            right: "10%",
            bottom: "130px",
          },
          xAxis: {
            type: "category",
            boundaryGap: false,
            showMaxLabel: true,
            axisLabel: {
              show: true,
              textStyle: {
                color: "#333333",
                fontSize: "18", //字体大小
              },
              showMaxLabel: true,
              interval: 7,
            },
            splitLine: {
              show: false,
            },
            axisLine: {
              lineStyle: {
                color: "rgba(224, 225, 226, 1)",
              },
            },
            offset: 13,
            data: [],
          },
          yAxis: [
            {
              type: "value",
              axisLabel: {
                show: true,
                textStyle: {
                  color: "#333333",
                  fontSize: "14", //字体大小
                },
              },
              axisLine: {
                lineStyle: {
                  color: "rgba(224, 225, 226, 1)",
                },
              },
              splitLine: {
                show: false,
              },
              show: false,
              max(value) {
                return value.max * 1.6;
              },
            },
          ],
          series: [],
        },
        zfje: {
          title: [
            {
              text: null,
              subtext: null,
              left: "center",
              top: "12%",
              textStyle: {
                fontSize: 18,
                color: "#333333",
                fontWeight: "normal",
              },
              subtextStyle: {
                fontSize: 18,
                color: "#1955FA",
              },
            },
            {
              text: null,
              subtext: null,
              left: "8%",
              bottom: "50px",
              textStyle: {
                fontSize: 16,
                color: "#1955FA",
                fontWeight: "normal",
              },
              subtextStyle: {
                fontSize: 16,
                color: "#FFD110",
              },
            },
          ],
          color: "#1955FA", //["#1955FA",'#FFD110'],
          grid: {
            top: "28px",
            left: "10%",
            right: "10%",
            bottom: "130px",
          },
          xAxis: {
            type: "category",
            boundaryGap: false,
            showMaxLabel: true,
            axisLabel: {
              show: true,
              textStyle: {
                color: "#333333",
                fontSize: "18", //字体大小
              },
              showMaxLabel: true,
              interval: 7,
            },
            splitLine: {
              show: false,
            },
            axisLine: {
              lineStyle: {
                color: "rgba(224, 225, 226, 1)",
              },
            },
            offset: 13,
            data: [],
          },
          yAxis: [
            {
              type: "value",
              axisLabel: {
                show: true,
                textStyle: {
                  color: "#333333",
                  fontSize: "14", //字体大小
                },
              },
              axisLine: {
                lineStyle: {
                  color: "rgba(224, 225, 226, 1)",
                },
              },
              splitLine: {
                show: false,
              },
              show: false,
              max(value) {
                return value.max * 1.6;
              },
            },
          ],
          series: [],
        },
        zfzhl: {
          title: [
            {
              text: null,
              subtext: null,
              left: "center",
              top: "12%",
              textStyle: {
                fontSize: 18,
                color: "#333333",
                fontWeight: "normal",
              },
              subtextStyle: {
                fontSize: 18,
                color: "#1955FA",
              },
            },
            {
              text: null,
              subtext: null,
              left: "8%",
              bottom: "50px",
              textStyle: {
                fontSize: 16,
                color: "#1955FA",
                fontWeight: "normal",
              },
              subtextStyle: {
                fontSize: 16,
                color: "#FFD110",
              },
            },
          ],
          color: "#1955FA", //["#1955FA",'#FFD110'],
          grid: {
            top: "28px",
            left: "10%",
            right: "10%",
            bottom: "130px",
          },
          xAxis: {
            type: "category",
            boundaryGap: false,
            showMaxLabel: true,
            axisLabel: {
              show: true,
              textStyle: {
                color: "#333333",
                fontSize: "18", //字体大小
              },
              showMaxLabel: true,
              interval: 7,
            },
            splitLine: {
              show: false,
            },
            axisLine: {
              lineStyle: {
                color: "rgba(224, 225, 226, 1)",
              },
            },
            offset: 13,
            data: [],
          },
          yAxis: [
            {
              type: "value",
              axisLabel: {
                show: true,
                textStyle: {
                  color: "#333333",
                  fontSize: "14", //字体大小
                },
              },
              axisLine: {
                lineStyle: {
                  color: "rgba(224, 225, 226, 1)",
                },
              },
              splitLine: {
                show: false,
              },
              show: false,
              max(value) {
                return value.max * 1.6;
              },
            },
          ],
          series: [],
        },
      },
      monthTime: {
        fks: {
          title: [
            {
              text: null,
              subtext: null,
              left: "center",
              top: "12%",
              textStyle: {
                fontSize: 18,
                color: "#333333",
                fontWeight: "normal",
              },
              subtextStyle: {
                fontSize: 18,
                color: "#1955FA",
              },
            },
            {
              text: null,
              subtext: null,
              left: "5%",
              bottom: "50px",
              textStyle: {
                width: "100%",
                fontSize: 16,
                color: "#1955FA",
                fontWeight: "normal",
              },
            },
            {
              text: null,
              subtext: null,
              right: "5%",
              bottom: "50px",
              textStyle: {
                width: "100%",
                fontSize: 16,
                color: "#FFD110",
                fontWeight: "normal",
                textAlign: "left",
              },
            },
          ],
          color: ["#1955FA", ""],
          tooltip: {},
          grid: {
            top: "28px",
            left: "10%",
            right: "10%",
            bottom: "130px",
          },
          xAxis: {
            type: "category",
            boundaryGap: false,
            showMaxLabel: true,
            axisLabel: {
              show: true,
              textStyle: {
                color: "#333333",
                fontSize: "18", //字体大小
              },
              interval: 8,
            },
            splitLine: {
              show: false,
            },
            axisLine: {
              lineStyle: {
                color: "rgba(224, 225, 226, 1)",
              },
            },
            offset: 13,
            data: [],
          },
          yAxis: [
            {
              type: "value",
              axisLabel: {
                show: true,
                textStyle: {
                  color: "#333333",
                  fontSize: "14", //字体大小
                },
              },
              axisLine: {
                lineStyle: {
                  color: "rgba(224, 225, 226, 1)",
                },
              },
              splitLine: {
                show: false,
              },
              show: false,
              max(value) {
                return value.max * 1.6;
              },
            },
          ],
          series: [],
        },
        tkje: {
          title: [
            {
              text: null,
              subtext: null,
              left: "center",
              top: "12%",
              textStyle: {
                fontSize: 18,
                color: "#333333",
                fontWeight: "normal",
              },
              subtextStyle: {
                fontSize: 18,
                color: "#1955FA",
              },
            },
            {
              text: null,
              subtext: null,
              left: "5%",
              bottom: "50px",
              textStyle: {
                width: "100%",
                fontSize: 16,
                color: "#1955FA",
                fontWeight: "normal",
              },
            },
            {
              text: null,
              subtext: null,
              right: "5%",
              bottom: "50px",
              textStyle: {
                width: "100%",
                fontSize: 16,
                color: "#FFD110",
                fontWeight: "normal",
                textAlign: "left",
              },
            },
          ],
          color: ["#1955FA", "#FFD110"],
          grid: {
            top: "28px",
            left: "10%",
            right: "10%",
            bottom: "130px",
          },
          xAxis: {
            type: "category",
            boundaryGap: false,
            showMaxLabel: true,
            axisLabel: {
              show: true,
              textStyle: {
                color: "#333333",
                fontSize: "18", //字体大小
              },
              showMaxLabel: true,
              interval: 8,
            },
            splitLine: {
              show: false,
            },
            axisLine: {
              lineStyle: {
                color: "rgba(224, 225, 226, 1)",
              },
            },
            offset: 13,
            data: [],
          },
          yAxis: [
            {
              type: "value",
              axisLabel: {
                show: true,
                textStyle: {
                  color: "#333333",
                  fontSize: "14", //字体大小
                },
              },
              axisLine: {
                lineStyle: {
                  color: "rgba(224, 225, 226, 1)",
                },
              },
              splitLine: {
                show: false,
              },
              show: false,
              max(value) {
                return value.max * 1.6;
              },
            },
          ],
          series: [],
        },
        zfje: {
          title: [
            {
              text: null,
              subtext: null,
              left: "center",
              top: "12%",
              textStyle: {
                fontSize: 18,
                color: "#333333",
                fontWeight: "normal",
              },
              subtextStyle: {
                fontSize: 18,
                color: "#1955FA",
              },
            },
            {
              text: null,
              subtext: null,
              left: "5%",
              bottom: "50px",
              textStyle: {
                width: "100%",
                fontSize: 16,
                color: "#1955FA",
                fontWeight: "normal",
              },
            },
            {
              text: null,
              subtext: null,
              right: "5%",
              bottom: "50px",
              textStyle: {
                width: "100%",
                fontSize: 16,
                color: "#FFD110",
                fontWeight: "normal",
                textAlign: "left",
              },
            },
          ],
          color: ["#1955FA", "#FFD110"],
          grid: {
            top: "28px",
            left: "10%",
            right: "10%",
            bottom: "130px",
          },
          xAxis: {
            type: "category",
            boundaryGap: false,
            showMaxLabel: true,
            axisLabel: {
              show: true,
              textStyle: {
                color: "#333333",
                fontSize: "18", //字体大小
              },
              showMaxLabel: true,
              interval: 8,
            },
            splitLine: {
              show: false,
            },
            axisLine: {
              lineStyle: {
                color: "rgba(224, 225, 226, 1)",
              },
            },
            offset: 13,
            data: [],
          },
          yAxis: [
            {
              type: "value",
              axisLabel: {
                show: true,
                textStyle: {
                  color: "#333333",
                  fontSize: "14", //字体大小
                },
              },
              axisLine: {
                lineStyle: {
                  color: "rgba(224, 225, 226, 1)",
                },
              },
              splitLine: {
                show: false,
              },
              show: false,
              max(value) {
                return value.max * 1.6;
              },
            },
          ],
          series: [],
        },
        zfzhl: {
          title: [
            {
              text: null,
              subtext: null,
              left: "center",
              top: "12%",
              textStyle: {
                fontSize: 18,
                color: "#333333",
                fontWeight: "normal",
              },
              subtextStyle: {
                fontSize: 18,
                color: "#1955FA",
              },
            },
            {
              text: null,
              subtext: null,
              left: "5%",
              bottom: "50px",
              textStyle: {
                width: "100%",
                fontSize: 16,
                color: "#1955FA",
                fontWeight: "normal",
              },
            },
            {
              text: null,
              subtext: null,
              right: "5%",
              bottom: "50px",
              textStyle: {
                width: "100%",
                fontSize: 16,
                color: "#FFD110",
                fontWeight: "normal",
                textAlign: "left",
              },
            },
          ],
          color: ["#1955FA", "#FFD110"],
          grid: {
            top: "28px",
            left: "10%",
            right: "10%",
            bottom: "130px",
          },
          xAxis: {
            type: "category",
            boundaryGap: false,
            showMaxLabel: true,
            axisLabel: {
              show: true,
              textStyle: {
                color: "#333333",
                fontSize: "18", //字体大小
              },
              showMaxLabel: true,
              interval: 8,
            },
            splitLine: {
              show: false,
            },
            axisLine: {
              lineStyle: {
                color: "rgba(224, 225, 226, 1)",
              },
            },
            offset: 13,
            data: [],
          },
          yAxis: [
            {
              type: "value",
              axisLabel: {
                show: true,
                textStyle: {
                  color: "#333333",
                  fontSize: "14", //字体大小
                },
              },
              axisLine: {
                lineStyle: {
                  color: "rgba(224, 225, 226, 1)",
                },
              },
              splitLine: {
                show: false,
              },
              show: false,
              max(value) {
                return value.max * 1.6;
              },
            },
          ],
          series: [],
        },
      },
      saveList: [],
      viewTotal: null,
      monthPayMoney: null,
      todayError: null,
      yesterdayDeal: null,
      fundCenterData: {},
    };
  },
  created() {
    this.$nextTick(() => {
      this.dataInit();
      this.orderStatusStatistics();
      this.fundCenter();
    });
  },
  mounted() {
    this.$nextTick(() => {
      let myChart1 = this.$echarts.init(document.getElementById("linechart1"));
      let myChart2 = this.$echarts.init(document.getElementById("linechart2"));
      let myChart3 = this.$echarts.init(document.getElementById("linechart3"));
      let myChart4 = this.$echarts.init(document.getElementById("linechart4"));
      let that = this;
      if (that.$refs.indexTopLi) {
        let w = that.$refs.indexTopLi.clientWidth;
        let rate = 313 / 129;
        that.clientHeight = w / rate;
      }

      window.addEventListener("resize", () => {
        if (that.$refs.indexTopLi) {
          let w = that.$refs.indexTopLi.clientWidth;
          let rate = 313 / 129;
          that.clientHeight = w / rate;
        }
        myChart1.resize();
        myChart2.resize();
        myChart3.resize();
        myChart4.resize();
      });
    });
  },
  destroyed() {},
  methods: {
    go(tar, n) {
      this.$store.commit("menu/navigation", "首页/订单");
      this.$store.commit("menu/RuoterNavigation","/smart");
      if (n == 1) {
        this.$router.push({ path: "/online", query: { sta: 0 } });
      }
      if (n == 2) {
        this.$router.push({ path: "/online", query: { sta: 1 } });
      }
      if (n == 3) {
        this.$router.push({ path: "/online", query: { sta: 6 } });
      }
      if (n == 4) {
        this.$router.push({ path: "/online", query: { sts: 3 } });
      }
      if (n == 5) {
        this.$router.push({ path: "/online", query: { wl: 1 } });
      }
    },

    drawCharts(n) {
      let myChart1 = this.$echarts.init(document.getElementById("linechart1"));
      let myChart2 = this.$echarts.init(document.getElementById("linechart2"));
      let myChart3 = this.$echarts.init(document.getElementById("linechart3"));
      let myChart4 = this.$echarts.init(document.getElementById("linechart4"));
      // 绘制图表
      if (n == 1) {
        myChart1.setOption(this.realTime.fks);
        myChart2.setOption(this.realTime.tkje);
        myChart3.setOption(this.realTime.zfje);
        myChart4.setOption(this.realTime.zfzhl);
      } else {
        myChart1.setOption(this.monthTime.fks);
        myChart2.setOption(this.monthTime.tkje);
        myChart3.setOption(this.monthTime.zfje);
        myChart4.setOption(this.monthTime.zfzhl);
      }
    },
    tabClick(i) {
      let myChart1 = this.$echarts.init(document.getElementById("linechart1"));
      let myChart2 = this.$echarts.init(document.getElementById("linechart2"));
      let myChart3 = this.$echarts.init(document.getElementById("linechart3"));
      let myChart4 = this.$echarts.init(document.getElementById("linechart4"));
      this.currTab = i;
      myChart1.dispose();
      myChart2.dispose();
      myChart3.dispose();
      myChart4.dispose();
      this.$nextTick(() => {
        this.drawCharts(i);
      });
    },
    async dataInit() {
      let merchantNo = this.$store.getters.userInfo.merchantNo;
      let res;
      res = await yoRealTimeData(merchantNo);
      const { flag, data, msg } = res.data;
      if (flag) {
        let real = data.realTimeData;
        let month = data.monthTimeData;
        this.viewTotal = real.fks.number;
        this.monthPayMoney = month.zfje.number;
        this.todayPYmoney = real.zfje.number;
        for (let key in real) {
          this.realTime[key].title[0].text = real[key].title;
          if (key == "zfzhl") {
            this.realTime[key].title[0].subtext =
              (real[key].number * 100).toFixed(2) + "%";
          } else {
            this.realTime[key].title[0].subtext = real[key].number;
          }
          let n = (real[key].dayMom * 100).toFixed(2);
          this.realTime[key].title[1].text = `日环比:${n}%`;
          //this.realTime[key].title[1].subtext = `月环比:${data[key].monthMom}%`
          this.realTime[key].xAxis.data = real[key].dataTileList;
          this.realTime[key].xAxis.minInterval = 4;
          this.realTime[key].series.push({
            type: "line",
            data: real[key].todayValueList,
            smooth: true,
          });
          this.realTime[key].tooltip = {
            trigger: "axis",
            formatter(params) {
              let idx = params[0].dataIndex;
              let yesValue = real[key].yesterdayValueList[idx];
              let yesName = real[key].dataTileList[idx];
              let toValue = params[0].value;
              let toOrder =
                real[key].todayCountList[idx] == undefined
                  ? 0
                  : real[key].todayCountList[idx];
              let name = params[0].name;
              if (key == "fks") {
                return `<div class='tooltip' style='width:auto;height:auto;boder-radius:1rem;text-align:left;'><p style='font-size:1.6rem;line-height:4rem;color:#ffffff;'>昨日${yesName}时：${yesValue}人</p><p style='font-size:1.6rem;line-height:4rem;color:#ffffff;'>今日${name}时：${toValue}人</p></div>`;
              } else if (key == "zfzhl") {
                return `<div class='tooltip' style='width:auto;height:auto;boder-radius:1rem;text-align:left;'><p style='font-size:1.6rem;line-height:4rem;color:#ffffff;'>昨日${yesName}时：${yesValue}笔订单</p><p style='font-size:1.6rem;line-height:4rem;color:#ffffff;'>今日${name}时：${toValue}笔订单</p></div>`;
              } else {
                return `<div class='tooltip' style='width:auto;height:auto;boder-radius:1rem;text-align:left;''><p style='font-size:1.6rem;line-height:4rem;color:#ffffff;'>昨日${yesName}时：￥${yesValue}元</p><p style='font-size:1.6rem;line-height:4rem;color:#ffffff;'>今日${name}时：￥${toValue}元</p><p style='font-size:1.6rem;line-height:4rem;color:#ffffff;'>今日${name}时：${toOrder}笔订单</p></div>`;
              }
            },
          };
        }
        for (let key in month) {
          this.monthTime[key].title[0].text = month[key].title;
          if (key == "zfzhl") {
            this.monthTime[key].title[0].subtext =
              (month[key].number * 100).toFixed(2) + "%";
          } else {
            this.monthTime[key].title[0].subtext = month[key].number;
          }
          this.monthTime[key].title[1].text = `日环比:${(
            month[key].dayMom * 100
          ).toFixed(2)}%`;
          this.monthTime[key].title[2].text = `月环比:${(
            month[key].monthMom * 100
          ).toFixed(2)}%`;
          this.monthTime[key].xAxis.data = month[key].dataTileList;
          this.monthTime[key].xAxis.minInterval = 4;
          this.monthTime[key].series.push({
            type: "line",
            data: month[key].todayValueList,
            smooth: true,
          });
          this.monthTime[key].tooltip = {
            trigger: "axis",
            formatter(params) {
              let idx = params[0].dataIndex;
              let yesValue = month[key].yesterdayValueList[idx];
              let yesName = month[key].yesterdayTileList[idx].split("/");
              //let yesM = yesName[0] - 1 == 0 ? '12' : yesName[0] - 1
              let yesNameTxt = `${yesName[0]}月${yesName[1]}日`;
              let toValue = params[0].value;
              let toOrder =
                month[key].todayCountList[idx] == undefined
                  ? 0
                  : month[key].todayCountList[idx];
              let name = params[0].name.split("/");
              let nameTxt = `${name[0]}月${name[1]}日`;
              if (key == "fks") {
                return `<div class='tooltip' style='width:auto;height:auto;boder-radius:1rem;text-align:left;'><p style='font-size:1.6rem;line-height:4rem;color:#ffffff;'>${yesNameTxt}：${yesValue}人</p><p style='font-size:1.6rem;line-height:4rem;color:#ffffff;'>${nameTxt}：${toValue}人</p></div>`;
              } else if (key == "zfzhl") {
                return `<div class='tooltip' style='width:auto;height:auto;boder-radius:1rem;text-align:left;'><p style='font-size:1.6rem;line-height:4rem;color:#ffffff;'>${yesNameTxt}：${yesValue}笔订单</p><p style='font-size:1.6rem;line-height:4rem;color:#ffffff;'>${nameTxt}：${toValue}笔订单</p></div>`;
              } else {
                return `<div class='tooltip' style='width:auto;height:auto;boder-radius:1rem;text-align:left;'><p style='font-size:1.6rem;line-height:4rem;color:#ffffff;'>${yesNameTxt}：￥${yesValue}元</p><p style='font-size:1.6rem;line-height:4rem;color:#ffffff;'>${nameTxt}：￥${toValue}元</p><p style='font-size:1.6rem;line-height:4rem;color:#ffffff;'>${nameTxt}：${toOrder}笔订单</p></div>`;
              }
            },
          };
        }
        this.drawCharts(this.currTab);
      } else {
        this.$message({
          type: "error",
          message: msg,
        });
      }
    },
    async orderStatusStatistics() {
      let res;
      let merchantNo = this.$store.getters.userInfo.merchantNo;
      res = await orderStatusStatistics(merchantNo);
      const { flag, data, msg } = res.data;
      if (flag) {
        this.orderData = data;
        this.yesterdayDeal = data.yesterdaySingularNumber;
        this.todayError = data.todayLogisticsAnomalyCount;
        this.yesterdaySingularMoney = data.yesterdaySingularMoney;
        if (this.shopType == 1) {
          this.onlineOrder = data.toBeShippedCount;
        } else {
          this.onlineOrder = data.toBeShippedCount + data.selfRaising;
        }
      } else {
        this.$message({
          type: "error",
          message: msg,
        });
      }
    },
    async fundCenter() {
      let merchantNo = this.$store.getters.userInfo.merchantNo;
      const res = await fundCenter(merchantNo);
      const { flag, data, msg } = res.data;
      if (flag) {
        this.fundCenterData = data;
      } else {
        this.$message({
          type: "error",
          message: msg,
        });
      }
    },
    goReconciliation() {
      this.$router.push({
        path: "/financial/reconciliation",
        query: { ifCheckToday: true },
      });
    },
  },
};
</script>

<style lang='less' scoped>
.el-main {
  width: 100%;
  text-align: left !important;
}
.main {
  // padding: 1.5rem;
  text-align: left !important;
}
.index-top {
  background-color: #ffffff;
  border-radius: 0.3rem;
  padding: 20px;

  .flexSt();
  .index-top-li {
    // width: 19%;
    flex: 1;
    height: 11.5rem;
    border-radius: 0.8rem;
    cursor: pointer;
    // display: flex;
    // align-content: center ;
    // justify-content: center;
    // flex-direction:row;
    &:not(:last-child) {
      margin-right: 1%;
    }
    span {
      display: inline-block;
      //height: 37.2%;
      // width: 6rem;
      height: 2.8rem;
      padding-left: 2.5rem;
      color: #fff;
      border-radius: 2.4rem;
      white-space: nowrap;
      padding-right: 2.2rem;
    }
    p {
      width: 2rem;
      text-align: center;
      font-size: 3.6rem;
      font-family: PingFang-SC-Medium, PingFang-SC;
      font-weight: 500;
      // line-height: 5rem;
      color: #fff;
      white-space: nowrap;
      margin-left: 2.5rem;
      margin-top: 1.8rem;
    }
  }
  .tbg1 {
    background: url("~@/assets/icon/tbg_c1.png") no-repeat 22.7rem 4rem,
      linear-gradient(270deg, #ff8eae 0%, #ff849d);
    // background-size: 20% 40%, 100% 100%;
    background-size: 15% 33%, 100% 100%;
  }
  .tbg2 {
    background: url("~@/assets/icon/tbg_c2.png") no-repeat 22.7rem 4rem,
      linear-gradient(270deg, #ffbc77 0%, #ffac76 100%);
    background-size: 15% 33%, 100% 100%;
  }
  .tbg3 {
    background: url("~@/assets/icon/tbg_c3.png") no-repeat 22.7rem 4rem,
      linear-gradient(270deg, #c38ffe 0%, #cb78fe 100%);
    background-size: 15% 33%, 100% 100%;
  }
  .tbg4 {
    background: url("~@/assets/icon/tbg_c4.png") no-repeat 22.7rem 4rem,
      linear-gradient(270deg, #37dbd2 0%, #55cacb 100%);
    background-size: 15% 33%, 100% 100%;
  }
  // .tbg5 {
  //   background: url("~@/assets/icon/tbg_c.png") no-repeat right top,
  //     linear-gradient(267deg, #ff9964 0%, #ffdd00 100%);
  //   background-size: 46% 89%, 100% 100%;
  // }
  .tbg5 {
    background: url("~@/assets/icon/tbg_c5.png") no-repeat 22.7rem 4rem,
      linear-gradient(270deg, #53c8f4 0%, #49c3eb 100%);
    background-size: 15% 33%, 100% 100%;
  }
  .tbg6 {
    background: url("~@/assets/icon/tbg_c6.png") no-repeat 22.7rem 4rem,
      linear-gradient(270deg, #ffbc77 0%, #ffac76 100%);
    background-size: 15% 33%, 100% 100%;
  }
  .tbg7 {
    background: url("~@/assets/icon/tbg_c7.png") no-repeat 22.7rem 4rem,
      linear-gradient(270deg, #f15252 0%, #ff7d7d 100%);
    background-size: 15% 33%, 100% 100%;
  }
}
.index-chart {
  background: #fff;
  padding: 2.3rem 3.6rem 2rem;
  margin-top: 2.5rem;
  border-radius: 0.3rem;
}
.index-chart-tabs {
  .flexSt();
  .index-chart-tab {
    .box(auto,4.4rem,transparent,0rem);
    .word(1.6rem,#333333,3.4rem,normal,center);
    border: 0.1rem solid #e1e4ea;
    padding: 0 1.8rem;
    margin-right: 0.8rem;
    cursor: pointer;
    border-color: transparent;
    &.tabActive {
      border-color: transparent;
      color: #3f82f0;
      border-bottom: 0.2rem solid #3f82f0;
    }
  }
}
.index-chart-li {
  .flexSt();
  flex-wrap: wrap;
  div {
    flex: 1 1 0;
    height: 41rem;
    //margin-right: 2.3rem;
  }
}
.index-mess {
  margin: 2.4rem 0 2rem 0;
  .flexSt();
  .index-mess-li {
    flex: 1;
    height: 10.6rem;
    background: #fff;
    border-radius: 0.3rem;
    .flexSt();
    &:not(:last-child) {
      margin-right: 2.3rem;
    }
    img {
      display: block;
      .box(6rem,5rem);
      margin: 0 1.6rem 0 2.4rem;
    }
    div {
      flex: 1;
      text-align: left;
      white-space: nowrap;
      overflow: hidden;
      p {
        .word(1.8rem,#333,2.5rem);
      }
      span {
        .word(1.8rem,#999,2.5rem);
      }
    }
  }
}
.index-financial {
  .flexSp();
  .index-financial-left {
    // flex: 1;
    width: 30%;
    height: 32.7rem;
    background: #fff;
    border-radius: 0.3rem;
    margin-right: 3.6rem;
    padding: 1rem 2.5rem 0 2.1rem;
    .index-financial-tit {
      .box(100%,5.3rem);
      .brb(0.1rem,#E0E1E2);
      .word(2.4rem,#333333,5.3rem,normal,left);
      .flex();
      margin-bottom: 2.7rem;
      &:before {
        display: inline-block;
        content: "";
        .box(0.5rem,2.4rem,#fff,0.4rem);
        margin-right: 1.2rem;
      }
    }
    .index-financial-spending {
      // .flexSt();
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 6.6rem;
      margin: 0 auto;
      .divShowbalance {
        padding-top: 5rem;
        .flexSt();
        div {
          margin-left: 1rem;
          margin-top: 1rem;
        }
      }
      > span {
        .word(1.8rem,#666666,2.4rem,normal,left);
        margin-right: 0.9rem;
      }
      .index-financial-spending-num {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 2.8rem;

        width: 13rem;
        .word(2.8rem,#1955fa,3rem,normal,left);
      }
      .index-financial-spending-eye {
        .box(8.2rem,3rem,linear-gradient(270deg, #4519fa 0%, #4a8cff 100%),1.45rem);
        .word(1.4rem,#fff,3rem,normal,center);
        cursor: pointer;
        margin-left: 4.4rem;
      }
    }
    .index-financial-link {
      padding-top: 5rem;
      .flexCen();
      .index-financial-link-li {
        margin: 0 5%;
        .flex();
        cursor: pointer;
        .el-tooltip,
        a {
          text-decoration: none;
          .word(1.8rem,#333333,2.5rem,normal,center);
          margin-left: 1.1rem;
          border: 0;
          background: none;
          padding: 0;
        }
      }
    }
  }
  .index-financial-right {
    .box(70%,33.7rem,#fff,0.3rem);
    // padding-top: 3.7rem;
    // padding-left: 7.6rem;
    padding: 5.6rem;
    .flex();
    // flex-direction: column;
    justify-content: center;
    // align-items: flex-start;
    .index-financial-moo {
      width: 33.33%;
      height: 10.5rem;
      .flex();
      margin-bottom: 4rem;
      align-items: flex-start;
      border-right: 0.2rem solid #c0c0c0;
      .spanshow {
        padding-top: 3rem;
        // font-size: 3.6rem;
      }
      > div {
        margin-left: 1.4rem;
        margin: 0 auto;
        > span {
          display: block;
          .word(2.5rem,#333333,3.6rem,normal,left);
        }
        div {
          .flexSt();
          cursor: pointer;
          span {
            .word(1.8rem,#333333,2.5rem,normal,left);
            margin-right: 1rem;
          }
        }
        img {
          width: 1.8rem;
          height: auto;
        }
      }
    }
    // .solid{
    //   width: 0.2rem;
    //   height: 10.5rem;
    //   background-color: #C0C0C0;
    // }
  }
}
.index-audit {
  .box(100%,auto,#fff,1rem);
  padding: 1rem 2.5rem 0 2.1rem;
  margin: 2.4rem 0 0 0;
  .index-audit-head {
    .flexSp();
    .brb(0.1rem,#E0E1E2);
    .index-audit-tit {
      .word(2.4rem,#333333,5.3rem,normal,left);
      .flex();
      &:before {
        display: inline-block;
        content: "";
        .box(0.5rem,2.4rem,#fff,0.4rem);
        margin-right: 1.2rem;
      }
    }
    .index-audit-btn {
      .flex();
    }
    .index-audit-btn1 {
      // background: #4a84ff;
      .box(9.1rem,3rem,linear-gradient(270deg, #4a84ff 0%, #4a84ff 100%),1.8rem);
      .word(1.4rem,#ffffff,3rem,normal,center);
      margin-right: 0.6rem;
    }
    .index-audit-btn2 {
      .box(9.1rem,3rem,transparent,1.8rem);
      .word(1.4rem,#333333,3rem,normal,center);
      margin-right: 0.6rem;
      .br(0.1rem,#DCDFE6);
    }
  }
  .index-audit-inner {
    padding: 3.7rem 0 6.7rem 0;
    .index-audit-tip {
      .flexCol();
      span {
        .word(1.4rem,#000000,2rem,normal,center);
        margin-top: 1.4rem;
      }
    }
  }
}
.tooltip {
  .box(10rem,8rem,rgba(0,0,0,0.4),1rem);
  span {
    display: block;
    .word(1.4rem,#1955fa,4rem,normal,left);
  }
}

@media screen and (max-width: 1700px) {
  .main {
    width: 1660px;
  }
}

@media screen and (max-width: 1300px) {
  .index-chart-li > div {
    width: 50%;
    flex: initial;
  }
}
@media screen and (max-width: 1200px) {
  .index-top-li span {
    font-size: 1.4rem !important;
    padding: 0 1rem !important;
    height: 2rem;
    line-height: 2rem;
  }
  .index-top-li p {
    font-size: 2rem !important;
  }
  .index-chart-li > div {
    width: 50%;
    flex: initial;
  }
  .index-top .tbg1 {
    background: url("~@/assets/icon/tbg_c1.png") no-repeat 22.7rem 5rem,
      linear-gradient(270deg, #ff8eae 0%, #ff849d);
    background-size: 4% 16%, 100% 100%;
  }
  .index-top .tbg2 {
    background: url("~@/assets/icon/tbg_c2.png") no-repeat 22.7rem 5rem,
      linear-gradient(270deg, #ffbc77 0%, #ffac76 100%);
    background-size: 4% 16%, 100% 100%;
  }
  .index-top .tbg3 {
    background: url("~@/assets/icon/tbg_c3.png") no-repeat 22.7rem 5rem,
      linear-gradient(270deg, #c38ffe 0%, #cb78fe 100%);
    background-size: 4% 16%, 100% 100%;
  }
  .index-top .tbg4 {
    background: url("~@/assets/icon/tbg_c4.png") no-repeat 22.7rem 5rem,
      linear-gradient(270deg, #37dbd2 0%, #55cacb 100%);
    background-size: 4% 16%, 100% 100%;
  }
  .index-top .tbg5 {
    background: url("~@/assets/icon/tbg_c5.png") no-repeat 22.7rem 5rem,
      linear-gradient(270deg, #53c8f4 0%, #49c3eb 100%);
    background-size: 4% 16%, 100% 100%;
  }
  .index-top .tbg6 {
    background: url("~@/assets/icon/tbg_c6.png") no-repeat 22.7rem 5rem,
      linear-gradient(270deg, #ffbc77 0%, #ffac76 100%);
    background-size: 4% 16%, 100% 100%;
  }
  .index-top .tbg7 {
    background: url("~@/assets/icon/tbg_c7.png") no-repeat 22.7rem 5rem,
      linear-gradient(270deg, #f15252 0%, #ff7d7d 100%);
    background-size: 4% 16%, 100% 100%;
  }
}
@media screen and (max-width: 1300px) and (min-width: 1200px) {
  .index-top-li span {
    font-size: 1.6rem !important;
    padding: 0 1.2rem !important;
    height: 2.2rem;
    line-height: 2.2rem;
  }
  .index-top-li p {
    font-size: 2.4rem !important;
  }
  .index-top .tbg1 {
    background: url("~@/assets/icon/tbg_c1.png") no-repeat 22.7rem 5rem,
      linear-gradient(270deg, #ff8eae 0%, #ff849d);
    background-size: 6% 18%, 100% 100%;
  }
  .index-top .tbg2 {
    background: url("~@/assets/icon/tbg_c2.png") no-repeat 22.7rem 5rem,
      linear-gradient(270deg, #ffbc77 0%, #ffac76 100%);
    background-size: 6% 18%, 100% 100%;
  }
  .index-top .tbg3 {
    background: url("~@/assets/icon/tbg_c3.png") no-repeat 22.7rem 5rem,
      linear-gradient(270deg, #c38ffe 0%, #cb78fe 100%);
    background-size: 6% 18%, 100% 100%;
  }
  .index-top .tbg4 {
    background: url("~@/assets/icon/tbg_c4.png") no-repeat 22.7rem 5rem,
      linear-gradient(270deg, #37dbd2 0%, #55cacb 100%);
    background-size: 6% 18%, 100% 100%;
  }
  .index-top .tbg5 {
    background: url("~@/assets/icon/tbg_c5.png") no-repeat 22.7rem 5rem,
      linear-gradient(270deg, #53c8f4 0%, #49c3eb 100%);
    background-size: 6% 18%, 100% 100%;
  }
  .index-top .tbg6 {
    background: url("~@/assets/icon/tbg_c6.png") no-repeat 22.7rem 5rem,
      linear-gradient(270deg, #ffbc77 0%, #ffac76 100%);
    background-size: 6% 18%, 100% 100%;
  }
  .index-top .tbg7 {
    background: url("~@/assets/icon/tbg_c7.png") no-repeat 22.7rem 5rem,
      linear-gradient(270deg, #f15252 0%, #ff7d7d 100%);
    background-size: 6% 18%, 100% 100%;
  }
}
@media screen and (max-width: 1400px) and (min-width: 1300px) {
  .index-top-li span {
    font-size: 1.7rem !important;
    padding: 0 1.4rem !important;
    height: 3rem;
    line-height: 3rem;
  }
  .index-top-li p {
    font-size: 2.4rem !important;
  }
  .index-top .tbg1 {
    background: url("~@/assets/icon/tbg_c1.png") no-repeat 22.7rem 5rem,
      linear-gradient(270deg, #ff8eae 0%, #ff849d);
    background-size: 8% 20%, 100% 100%;
  }
  .index-top .tbg2 {
    background: url("~@/assets/icon/tbg_c2.png") no-repeat 22.7rem 5rem,
      linear-gradient(270deg, #ffbc77 0%, #ffac76 100%);
    background-size: 8% 20%, 100% 100%;
  }
  .index-top .tbg3 {
    background: url("~@/assets/icon/tbg_c3.png") no-repeat 22.7rem 5rem,
      linear-gradient(270deg, #c38ffe 0%, #cb78fe 100%);
    background-size: 8% 20%, 100% 100%;
  }
  .index-top .tbg4 {
    background: url("~@/assets/icon/tbg_c4.png") no-repeat 22.7rem 5rem,
      linear-gradient(270deg, #37dbd2 0%, #55cacb 100%);
    background-size: 8% 20%, 100% 100%;
  }
  .index-top .tbg5 {
    background: url("~@/assets/icon/tbg_c5.png") no-repeat 22.7rem 5rem,
      linear-gradient(267deg, #53c8f4 0%, #49c3eb 100%);
    background-size: 8% 20%, 100% 100%;
  }
  .index-top .tbg6 {
    background: url("~@/assets/icon/tbg_c6.png") no-repeat 22.7rem 4rem,
      linear-gradient(267deg, #ffbc77 0%, #ffac76 100%);
    background-size: 8% 20%, 100% 100%;
  }
  .index-top .tbg7 {
    background: url("~@/assets/icon/tbg_c7.png") no-repeat 22.7rem 4rem,
      linear-gradient(267deg, #f15252 0%, #ff7d7d 100%);
    background-size: 8% 20%, 100% 100%;
  }
}
@media screen and (max-width: 1500px) and (min-width: 1400px) {
  .index-top-li span {
    font-size: 2rem !important;
    height: 3.8rem;
    line-height: 3.8rem;
  }
  .index-top-li p {
    font-size: 2.4rem !important;
  }
  .index-top .tbg1 {
    background: url("~@/assets/icon/tbg_c1.png") no-repeat 22.7rem 5rem,
      linear-gradient(270deg, #ff8eae 0%, #ff849d);
    background-size: 10% 24%, 100% 100%;
  }
  .index-top .tbg2 {
    background: url("~@/assets/icon/tbg_c2.png") no-repeat 22.7rem 5rem,
      linear-gradient(270deg, #ffbc77 0%, #ffac76 100%);
    background-size: 10% 24%, 100% 100%;
  }
  .index-top .tbg3 {
    background: url("~@/assets/icon/tbg_c3.png") no-repeat 22.7rem 5rem,
      linear-gradient(270deg, #c38ffe 0%, #cb78fe 100%);
    background-size: 10% 24%, 100% 100%;
  }
  .index-top .tbg4 {
    background: url("~@/assets/icon/tbg_c4.png") no-repeat 22.7rem 5rem,
      linear-gradient(270deg, #37dbd2 0%, #55cacb 100%);
    background-size: 10% 24%, 100% 100%;
  }
  .index-top .tbg5 {
    background: url("~@/assets/icon/tbg_c5.png") no-repeat 22.7rem 5rem,
      linear-gradient(270deg, #53c8f4 0%, #49c3eb 100%);
    background-size: 10% 24%, 100% 100%;
  }
  .index-top .tbg6 {
    background: url("~@/assets/icon/tbg_c6.png") no-repeat 22.7rem 5rem,
      linear-gradient(270deg, #ffbc77 0%, #ffac76 100%);
    background-size: 10% 24%, 100% 100%;
  }
  .index-top .tbg7 {
    background: url("~@/assets/icon/tbg_c7.png") no-repeat 22.7rem 5rem,
      linear-gradient(270deg, #f15252 0%, #ff7d7d 100%);
    background-size: 10% 24%, 100% 100%;
  }
}
@media screen and (max-width: 1600px) and (min-width: 1500px) {
  .index-top-li span {
    font-size: 1.9rem !important;
    height: 4rem;
    line-height: 4rem;
  }
  .index-top-li p {
    font-size: 2.4rem !important;
  }
  .index-top .tbg1 {
    background: url("~@/assets/icon/tbg_c1.png") no-repeat 22.7rem 5rem,
      linear-gradient(270deg, #ff8eae 0%, #ff849d);
    background-size: 12% 26%, 100% 100%;
  }
  .index-top .tbg2 {
    background: url("~@/assets/icon/tbg_c2.png") no-repeat 22.7rem 5rem,
      linear-gradient(270deg, #ffbc77 0%, #ffac76 100%);
    background-size: 12% 26%, 100% 100%;
  }
  .index-top .tbg3 {
    background: url("~@/assets/icon/tbg_c3.png") no-repeat 22.7rem 5rem,
      linear-gradient(270deg, #c38ffe 0%, #cb78fe 100%);
    background-size: 12% 26%, 100% 100%;
  }
  .index-top .tbg4 {
    background: url("~@/assets/icon/tbg_c4.png") no-repeat 22.7rem 5rem,
      linear-gradient(270deg, #37dbd2 0%, #55cacb 100%);
    background-size: 12% 26%, 100% 100%;
  }
  .index-top .tbg5 {
    background: url("~@/assets/icon/tbg_c5.png") no-repeat 22.7rem 5rem,
      linear-gradient(270deg, #53c8f4 0%, #49c3eb 100%);
    background-size: 12% 26%, 100% 100%;
  }
  .index-top .tbg6 {
    background: url("~@/assets/icon/tbg_c6.png") no-repeat 22.7rem 5rem,
      linear-gradient(270deg, #ffbc77 0%, #ffac76 100%);
    background-size: 12% 26%, 100% 100%;
  }
  .index-top .tbg7 {
    background: url("~@/assets/icon/tbg_c7.png") no-repeat 22.7rem 5rem,
      linear-gradient(270deg, #f15252 0%, #ff7d7d 100%);
    background-size: 12% 26%, 100% 100%;
  }
}
@media screen and (max-width: 1700px) and (min-width: 1600px) {
  .index-top-li span {
    font-size: 1.9rem !important;
    height: 4.2rem;
    line-height: 4.2rem;
  }
  .index-top-li p {
    font-size: 2.6rem !important;
  }
  .index-top .tbg1 {
    background: url("~@/assets/icon/tbg_c1.png") no-repeat 22.7rem 5rem,
      linear-gradient(270deg, #ff8eae 0%, #ff849d);
    background-size: 14% 30%, 100% 100%;
  }
  .index-top .tbg2 {
    background: url("~@/assets/icon/tbg_c2.png") no-repeat 22.7rem 5rem,
      linear-gradient(270deg, #ffbc77 0%, #ffac76 100%);
    background-size: 14% 30%, 100% 100%;
  }
  .index-top .tbg3 {
    background: url("~@/assets/icon/tbg_c3.png") no-repeat 22.7rem 5rem,
      linear-gradient(270deg, #c38ffe 0%, #cb78fe 100%);
    background-size: 14% 30%, 100% 100%;
  }
  .index-top .tbg4 {
    background: url("~@/assets/icon/tbg_c4.png") no-repeat 22.7rem 5rem,
      linear-gradient(270deg, #37dbd2 0%, #55cacb 100%);
    background-size: 14% 30%, 100% 100%;
  }
  .index-top .tbg5 {
    background: url("~@/assets/icon/tbg_c5.png") no-repeat 22.7rem 5rem,
      linear-gradient(270deg, #53c8f4 0%, #49c3eb 100%);
    background-size: 14% 30%, 100% 100%;
  }
  .index-top .tbg6 {
    background: url("~@/assets/icon/tbg_c6.png") no-repeat 22.7rem 5rem,
      linear-gradient(270deg, #ffbc77 0%, #ffac76 100%);
    background-size: 14% 30%, 100% 100%;
  }
  .index-top .tbg7 {
    background: url("~@/assets/icon/tbg_c7.png") no-repeat 22.7rem 5rem,
      linear-gradient(270deg, #f15252 0%, #ff7d7d 100%);
    background-size: 14% 30%, 100% 100%;
  }
}
@media screen and (max-width: 1800px) and (min-width: 1700px) {
  .index-top-li span {
    font-size: 2rem !important;
    height: 4.4rem;
    line-height: 4.4rem;
  }
  .index-top-li p {
    font-size: 2.8rem !important;
  }
  .index-top .tbg1 {
    background: url("~@/assets/icon/tbg_c1.png") no-repeat 22.7rem 5rem,
      linear-gradient(270deg, #ff8eae 0%, #ff849d);
    background-size: 16% 32%, 100% 100%;
  }
  .index-top .tbg2 {
    background: url("~@/assets/icon/tbg_c2.png") no-repeat 22.7rem 5rem,
      linear-gradient(270deg, #ffbc77 0%, #ffac76 100%);
    background-size: 16% 32%, 100% 100%;
  }
  .index-top .tbg3 {
    background: url("~@/assets/icon/tbg_c3.png") no-repeat 22.7rem 5rem,
      linear-gradient(270deg, #c38ffe 0%, #cb78fe 100%);
    background-size: 16% 32%, 100% 100%;
  }
  .index-top .tbg4 {
    background: url("~@/assets/icon/tbg_c4.png") no-repeat 22.7rem 5rem,
      linear-gradient(270deg, #37dbd2 0%, #55cacb 100%);
    background-size: 16% 32%, 100% 100%;
  }
  .index-top .tbg5 {
    background: url("~@/assets/icon/tbg_c5.png") no-repeat 22.7rem 5rem,
      linear-gradient(270deg, #53c8f4 0%, #49c3eb 100%);
    background-size: 16% 32%, 100% 100%;
  }
  .index-top .tbg6 {
    background: url("~@/assets/icon/tbg_c6.png") no-repeat 22.7rem 5rem,
      linear-gradient(270deg, #ffbc77 0%, #ffac76 100%);
    background-size: 16% 32%, 100% 100%;
  }
  .index-top .tbg7 {
    background: url("~@/assets/icon/tbg_c7.png") no-repeat 22.7rem 5rem,
      linear-gradient(270deg, #f15252 0%, #ff7d7d 100%);
    background-size: 16% 32%, 100% 100%;
  }
}
@media screen and (max-width: 1900px) and (min-width: 1800px) {
  .index-top-li span {
    font-size: 2.1rem !important;
    height: 4.6rem;
    line-height: 4.6rem;
  }
  .index-top-li p {
    font-size: 3rem !important;
  }

  .index-top .tbg1 {
    background: url("~@/assets/icon/tbg_c1.png") no-repeat 22.7rem 5rem,
      linear-gradient(270deg, #ff8eae 0%, #ff849d);
    background-size: 18% 36%, 100% 100%;
  }
  .index-top .tbg2 {
    background: url("~@/assets/icon/tbg_c2.png") no-repeat 22.7rem 5rem,
      linear-gradient(270deg, #ffbc77 0%, #ffac76 100%);
    background-size: 18% 36%, 100% 100%;
  }
  .index-top .tbg3 {
    background: url("~@/assets/icon/tbg_c3.png") no-repeat 22.7rem 5rem,
      linear-gradient(270deg, #c38ffe 0%, #cb78fe 100%);
    background-size: 18% 36%, 100% 100%;
  }
  .index-top .tbg4 {
    background: url("~@/assets/icon/tbg_c4.png") no-repeat 22.7rem 5rem,
      linear-gradient(270deg, #37dbd2 0%, #55cacb 100%);
    background-size: 18% 36%, 100% 100%;
  }
  .index-top .tbg5 {
    background: url("~@/assets/icon/tbg_c5.png") no-repeat 22.7rem 5rem,
      linear-gradient(270deg, #53c8f4 0%, #49c3eb 100%);
    background-size: 18% 36%, 100% 100%;
  }
  .index-top .tbg6 {
    background: url("~@/assets/icon/tbg_c6.png") no-repeat 22.7rem 5rem,
      linear-gradient(270deg, #ffbc77 0%, #ffac76 100%);
    background-size: 18% 36%, 100% 100%;
  }
  .index-top .tbg7 {
    background: url("~@/assets/icon/tbg_c7.png") no-repeat 22.7rem 5rem,
      linear-gradient(270deg, #f15252 0%, #ff7d7d 100%);
    background-size: 18% 36%, 100% 100%;
  }
}
@media screen and (min-width: 1900px) {
  .index-top-li span {
    font-size: 2.2rem !important;
    height: 4.8rem;
    line-height: 4.8rem;
  }
  .index-top-li p {
    font-size: 3.5rem !important;
  }
  .index-top-li {
    font-size: 3.5rem !important;
  }
  .index-top .tbg1 {
    background: url("~@/assets/icon/tbg_c1.png") no-repeat 22.7rem 5rem,
      linear-gradient(270deg, #ff8eae 0%, #ff849d);
    background-size: 18% 36%, 100% 100%;
  }
  .index-top .tbg2 {
    background: url("~@/assets/icon/tbg_c2.png") no-repeat 22.7rem 5rem,
      linear-gradient(270deg, #ffbc77 0%, #ffac76 100%);
    background-size: 18% 36%, 100% 100%;
  }
  .index-top .tbg3 {
    background: url("~@/assets/icon/tbg_c3.png") no-repeat 22.7rem 5rem,
      linear-gradient(270deg, #c38ffe 0%, #cb78fe 100%);
    background-size: 18% 36%, 100% 100%;
  }
  .index-top .tbg4 {
    background: url("~@/assets/icon/tbg_c4.png") no-repeat 22.7rem 5rem,
      linear-gradient(270deg, #37dbd2 0%, #55cacb 100%);
    background-size: 18% 36%, 100% 100%;
  }
  .index-top .tbg5 {
    background: url("~@/assets/icon/tbg_c5.png") no-repeat 22.7rem 5rem,
      linear-gradient(270deg, #53c8f4 0%, #49c3eb 100%);
    background-size: 18% 36%, 100% 100%;
  }
  .index-top .tbg6 {
    background: url("~@/assets/icon/tbg_c6.png") no-repeat 22.7rem 5rem,
      linear-gradient(270deg, #ffbc77 0%, #ffac76 100%);
    background-size: 18% 36%, 100% 100%;
  }
  .index-top .tbg7 {
    background: url("~@/assets/icon/tbg_c7.png") no-repeat 22.7rem 5rem,
      linear-gradient(270deg, #f15252 0%, #ff7d7d 100%);
    background-size: 18% 36%, 100% 100%;
  }
}

.ck3 {
  font-size: 3rem !important;
}
</style>
