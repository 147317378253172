// 导入axios
import axios from "axios";
// 使用element-ui Message做消息提醒
import {
	Message
} from "element-ui";
import store from "./../store";
import router from "../router/index";
//1. 创建新的axios实例，
const service = axios.create({
	// baseURL: process.env.API,
	timeout: 120000, // 请求超时时间
	withCredentials: true, // 跨域请求，允许保存cookie
	headers: {
		"Access-Control-Allow-Origin": "*",
		"Access-Control-Allow-Headers": "Authorization,Origin, X-Requested-With, Content-Type, Accept",
		"Access-Control-Allow-Methods": "GET,POST,PUT,DELETE",
		"Client-Type": "seller/pc"
	}
});
// let n = Math.random('')
const arr = ['/login', "/userRegister", '/forgetPassword', '/regBe', '/regSussec', '/personRegister', '/stateRegister',
	'/register', 'initStore'
]
// 2.请求拦截器
service.interceptors.request.use(
	config => {
		//发请求前做的一些处理，数据转化，配置请求头，设置token,设置loading等，根据需求去添加
		config.headers = {
			"Content-Type": "application/json;charset=UTF-8", //配置请求头
			"Client-Type": "seller/pc"
		};
		//  //注意使用token的时候需要引入cookie方法或者用本地localStorage等方法，推荐js-cookie
		const token = store.state.user.token || sessionStorage.getItem("token"); //这里取token之前，你肯定需要先拿到token,存一下

		let url = config.url
		if (!arr.some(n => url.includes(n))) {
			config.headers.xToken = token;
			// config.headers.randomNumber
		}
		// if (!arr.some(n => config.url.includes(n))) {
		//   config.headers.xToken = token;
		// }
		return config;
	},
	error => {
		Promise.reject(error);
	}
);

// 3.响应拦截器
service.interceptors.response.use(
	response => {
		if (response.data.code == 50008) {
			router.push('/')
			sessionStorage.clear()
		}
		//接收到响应数据并成功后的一些共有的处理，关闭loading等
		return response;
	},
	error => {
		/***** 接收到异常响应的处理开始 *****/
		if (error && error.response) {
			// 1.公共错误处理
			// 2.根据响应码具体处理
			switch (error.response.status) {
				case 401:
					Message.message = "未授权，请重新登录";
					break;
				case 403:
					Message.message = "拒绝访问";
					break;
				case 404:
					Message.message = "请求错误,未找到该资源";
					// window.location.href = "/NotFound"
					break;
				case 405:
					Message.message = "请求方法未允许";
					break;
				case 408:
					Message.message = "请求超时";
					break;
				case 500:
					Message.message = "服务器端出错";
					break;
				case 501:
					Message.message = "网络未实现";
					break;
				case 502:
					Message.message = "网络错误";
					break;
				case 503:
					Message.message = "服务不可用";
					break;
				case 504:
					Message.message = "网络超时";
					break;
				case 505:
					Message.message = "http版本不支持该请求";
					break;
				default:
					Message.message = `连接错误${error.response.status}`;
			}
		} else {
			// 超时处理
			if (JSON.stringify(error).includes("timeout")) {
				Message.error("服务器响应超时，请刷新当前页");
			}
			// Message.message('连接服务器失败')
		}
		Message.error(error.message);
		/***** 处理结束 *****/
		//如果不需要错误处理，以上的处理过程都可省略
		return Promise.resolve(error.response);
	}
);
//4.导入文件
export default service;
