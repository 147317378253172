<template>
  <!-- ============= -->
  <div id="main">
    <el-container>
      <el-aside
        :width="isCollapse ? '88px' : '182px'"
        @mouseover.native="showOnline = true"
        @mouseout.native="showOnline = false"
      >
        <el-collapse-transition>
          <el-menu
            :collapse="isCollapse"
            :collapse-transition="false"
            class="el-menu-vertical-demo"
            background-color="#1B2656"
            text-color="#FFFFFF"
            active-text-color="#FFFFFF"
          >
            <p v-if="!isCollapse">数智营运营中心</p>
            <p v-else></p>
            <template v-for="(item, index) in menuList">
              <el-menu-item
                :index="index.toString()"
                :key="item.id"
                @click="menuClick(item.url, item.id, item)"
                @mouseenter.native="More(item.url)"
                @mouseleave.native="isMore = ''"
                :class="{ upIndex: isdefault == item.url }"
              >
                <i :class="'menu-curr-' + item.icon"></i>
                <!-- 消息未读提示 -->
                <!-- <el-badge
                  :value="Unreadable.length"
                  class="item"
                  v-if="item.url == '/communication'"
                >
                  <span>{{ item.menuName }}</span>
                </el-badge> -->
                <!-- v-else -->
                <span>{{ item.menuName }}</span>

                <div v-if="isMore == item.url" class="More">
                  <div v-for="(its,ind) in item.childList" :key="ind" class="li" @click.stop="$router.push(its.url)">
<!--                    <i class="menu-curr-operationCenter"></i>-->
                    <span>{{its.menuName}}</span>
                  </div>
                  <!-- <span class="li" @click.stop="$router.push('/dataStatistics')">数据统计</span> -->
                  <!-- <span class="li">用户运营</span>
                  <span class="li">市场运营</span>
                  <span class="li">活动运营</span>
                  <span class="li">人力运营</span>
                  <span class="li">品牌运营</span>
                  <span class="li">数据运营</span>
                  <span class="li">财务运营</span>
                  <span class="li">供应链运营</span> -->

                </div>
                <!-- </transition> -->
              </el-menu-item>
            </template>
            <transition name="el-fade-in-linear">
              <div
                class="TakeBack"
                @click="TakeBackBtn"
                v-show="showOnline"
                :style="{
                  left: isCollapse ? 89 + 'px' : 183 + 'px',
                  backgroundImage:
                    'url(' +
                    (background ? backgroundImage : backgroundImages) +
                    ')',
                }"
                @mouseover="showOnline = true"
              ></div>
            </transition>
          </el-menu>
        </el-collapse-transition>
        <!-- 帮助与反馈 -->
        <span class="multi-function" @click="getMore"></span>
      </el-aside>

      <el-container>
        <el-header>
          <div class="headerLeft">
            <!-- :to="{ path: '/smart' }" -->

            <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item :to="RuoterNavigation">
<!--                {{-->
<!--                navigation-->
<!--              }}-->
                {{$route.meta.title}}
              </el-breadcrumb-item>

            </el-breadcrumb>

            <!-- <el-breadcrumb
              class="breadcrumb-container"
              separator-class="el-icon-arrow-right"
            >
              <el-breadcrumb-item
                v-for="item in levelList"
                :key="item.path"
                :to="item.path"
                >{{ item.meta.title }}</el-breadcrumb-item
              >
            </el-breadcrumb> -->
          </div>

          <div class="headeRight">
            <!-- <div class="shop-select" @click.stop="handlerShop">
              <span>{{ userInfo.shopName }}</span>
              <i :class="{ roll: shopOptionShow }"></i> -->
            <!-- <transition name="el-zoom-in-top">
                <div
                  class="shop-select-box"
                  v-if="shopOptionShow"
                  id="shopSelectBox"
                > -->
            <!-- <div
                    class="shop-select-li"
                    v-for="(item, index) in userInfo.shopNoList"
                    :key="index"
                    @click.stop="toggleShop(item)"
                  >
                    <span>{{ item.shopName }}</span>
                  </div> -->
            <!-- </div> -->
            <!-- </transition> -->
            <!-- </div> -->
            <span class="flexCen">
              <p>
              商户类型：{{merTypeObj[LoginInfo.merType]}}
              </p>
            </span>
            <span class="flexCen">
              <p @click.stop="handlerAvatar">用户名：{{ LoginInfo.loginName }}</p>
            </span>
            <span style="margin-right: 10px">
              <el-avatar :size="30" :src="LoginInfo.logo" >
                <img src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png"/>
              </el-avatar>
<!--              <img-->
<!--                :src="LoginInfo.logo"-->
<!--                :onerror="$store.getters.realAvatarImage"-->
<!--                alt-->
<!--                class="avatar"-->
<!--                v-if="LoginInfo.logo != '' && LoginInfo.logo != null"-->
<!--              />-->
            </span>

            <div class="exit" @click="loginOut">
              <a>退出</a>
            </div>
          </div>
        </el-header>
        <el-main
          ref="elMain"
          style="overflow-x: hidden"
          id="classifyScroll"
          :class="{ indexClass: $route.path == '/smart' }"
        >
          <!-- mouseover -->
          <keep-alive>
            <router-view v-if="$route.meta.keepAlive" class="page" :key="key" />
          </keep-alive>
          <!-- 首页内容 -->
          <router-view v-if="!$route.meta.keepAlive" class="page" :key="key" />
          <!-- 悬浮球 -->
          <div
            style="display: none"
            class="Floating"
            ref="Floating"
            @mousedown="getMouseXY($event)"
            @mouseup="getitems($event)"
            :style="{
              backgroundImage: 'url(' + coverImgUrl + ')',
              backgroundColor: '#fff',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: '50% 50%',
            }"
          >
            <transition name="el-zoom-in-bottom">
              <div class="ul" v-if="isunfold">
                <div class="nav" v-if="Recently.length > 0">
                  <!-- <span class="th">{{ item.name }}
                    <i class="el-icon-caret-bottom"></i>
                  </span> -->
                  <li v-for="(item, index) in Recently" :key="index">
                    <span>{{ item.lastMsg.to }}</span>
                    <!-- 正常情况下 -->
                    <img
                      v-if="item.isvioce"
                      @mousedown="getVoiceStart(item, index)"
                      src="~@/assets/indexImg/icon_ht.png"
                      alt=""
                    />

                    <!-- 说话情况下 -->
                    <img
                      v-if="item.isvioce == false"
                      @mouseup="getVoiceEnd(item, index)"
                      src="~@/assets/new-icon/icon_shht.png"
                      alt=""
                    />

                    <!-- 播放正常情况下 -->
                    <img
                      @click="playAudioStart(item, index)"
                      v-if="item.isvPale && item.isAutorun"
                      src="~@/assets/indexImg/icon_gbs.png"
                      alt=""
                    />
                    <!--  自动 播放 -->
                    <img
                      v-if="item.isAutorun == false"
                      src="~@/assets/new-icon/icon_shgb.png"
                      alt=""
                    />

                    <!-- <img  src="~@/assets/new-icon/icon_shgb.png" alt="" /> -->
                    <!-- 禁用 -->
                    <!-- <img
                      @click="playAudioEnd(item, index)"
                      v-if="item.isvPale == false"
                      src="~@/assets/new-icon/icon_jygb.png"
                      alt=""
                    /> -->
                  </li>
                </div>
                <span v-else class="isData">暂无数据</span>
                <!-- <div class="opn">
                  <el-button class="btn">按住和所有人说话</el-button>
                </div> -->
              </div>
            </transition>
          </div>
        </el-main>
        <el-footer style="height: -1px">
          <common-footer></common-footer>
        </el-footer>
      </el-container>
    </el-container>

    <el-dialog
      title="帮助与反馈"
      :visible.sync="dialogVisibleMore"
      width="700px"
      :before-close="handleClose"
      :close-on-click-modal="false"
      center
    >
      <span class="textarea"
        ><el-input
          :rows="5"
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 6 }"
          placeholder="请输入您的反馈内容,我们会第一时间处理!"
          v-model="textarea2"
        >
        </el-input
      ></span>
      <span slot="footer" class="dialog-footer">
        <el-button class="footerCor" @click="handleClose">取 消</el-button>
        <el-button class="footerAdd" type="primary" @click="getConfirm"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      :visible.sync="dialogVisibleCh"
      :close-on-click-modal="false"
      width="52.2rem"
      :modal-append-to-body="false"
      :show-close="false"
      class="dialogCall"
      @close="signalingLeave()"
    >
      <div class="dialogWarp">
        <div class="haedr">
          <div class="portrait">小红</div>
          <div class="text">
            <span class="s1">采购员 -小红</span>
            <span class="s2">万家丽门店员工</span>
          </div>
        </div>
        <div class="state" v-if="callPhone == 3">正在呼叫...</div>
        <div class="state" v-if="callPhone == 1">邀请你语音通话</div>
        <div class="operation">
          <div class="colse">
            <div class="colseImg">
              <img
                v-if="mute"
                @click="getmute"
                src="~@/assets/new-icon/icon_voice.png"
                alt=""
              />
              <img
                v-else
                @click="getmute"
                src="~@/assets/new-icon/icon_voice_ac.png"
                alt=""
              />
            </div>
            <span>静音</span>
          </div>

          <div class="open" v-if="callPhone == 1">
            <div class="colseImg">
              <img
                @click="getAnswer"
                src="~@/assets/new-icon/icon_answer.png"
                alt=""
              />
            </div>
            <span>接听</span>
          </div>

          <div class="open" v-if="callPhone == 2">
            <div class="colseImg">
              <img
                v-if="Phone"
                @click="getPhone"
                src="~@/assets/new-icon/icon_phone.png"
                alt=""
              />
              <img
                v-else
                @click="getPhone"
                src="~@/assets/new-icon/icon_phone_ac.png"
                alt=""
              />
            </div>
            <span>挂断</span>
          </div>
        </div>
      </div>
    </el-dialog>

    <audio
      id="resource"
      ref="up"
      :src="audioSrc"
      controls
      style="display: none"
    ></audio>
    <audio id="audioVoice" controls autoplay style="display: none"></audio>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import commonFooter from "@/components/commonFooter";
import modifyOut from "./";
import md5 from "js-md5";
import { Modify } from "@/api/api.js";
import shop from "../store/modules/shop";
import { nim, pushMsg, init } from "@/utils/NIM_WEB_SDK";
import Record from "@/api/record-sdk"; //引入外部js 录音

export default {
  name: "index",
  components: {
    commonFooter,
    modifyOut,
  },
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.ruleForm.checkPass !== "") {
          this.$refs.ruleForm.validateField("checkPass");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入新密码"));
      } else {
        callback();
      }
    };
    var validatePass3 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm.newPwd) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      // mer-普通商户  channel-渠道商  band-连锁品牌商  agent-代理商  platform-平台；
      merTypeObj: {
        "mer": "普通商户",
        "channel": "渠道商",
        "brand": "连锁品牌商",
        "agent": "代理商",
        "platform": "平台",
      },
      ruleForm: {
        oldPwd: "",
        newPwd: "",
        repeat: "",
      },
      rules: {
        oldPwd: [
          { validator: validatePass, trigger: "blur" },
          {
            min: 3,
            max: 15,
            message: "长度在 3 到 15 个字符",
            trigger: "blur",
          },
        ],
        newPwd: [
          { validator: validatePass2, trigger: "blur" },
          {
            min: 3,
            max: 15,
            message: "长度在 3 到 15 个字符",
            trigger: "blur",
          },
        ],
        repeat: [
          { validator: validatePass3, trigger: "blur" },
          {
            min: 3,
            max: 15,
            message: "长度在 3 到 15 个字符",
            trigger: "blur",
          },
        ],
      },
      Changes: false,
      modify: false,
      keepAliveList: ["activity", "onlineManage"],
      shopType: this.$store.getters.shopType,
      LoginInfo: this.$store.getters.LoginInfo,
      menuList: [],
      headOptionShow: false, //头部下拉框
      shopOptionShow: false, //店铺下拉框
      //asideChildIndex: 0,
      upIndex: 0,
      clickNum: 1, // 点击次数
      jianClick: 1, //
      fatherWidth: null,
      childWidth: null,
      jiaWidth: 0,
      action: this.$store.getters.fileServer, //上传图片路径

      // =======
      isCollapse: false, //左侧菜单栏
      isdefault: "/smart", //默认的地址
      showOnline: true, //控制收起菜单按钮

      // ======
      dialogVisibleMore: false,
      textarea2: "",

      isunfold: false,
      positionX: null,
      positionY: null,
      originalX: null,
      originalY: null,
      keys: false, //设置了一个标志 false为点击事件 ture为鼠标移动事件
      firstTime: 0,
      lastTime: 0,
      coverImgUrl:
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAYCAYAAAAPtVbGAAAAAXNSR0IArs4c6QAAA3VJREFUSA2tlWtIk2EUx92czEmBUTOFKBP80EVC+tCHxKTmvAxlBBMiw6BvSUZ0ExTxkkpWFAlFEHShIvJDJct7F00/GBVk0CeLoIxFoRW5LXVbvzP3GIzXbZAPnJ3znsv//5zzPu8zXVyMy2azZczOzrYFAoHlJpOpsrOzczzG0jhDLIlFRUXZMzMzjyFYIfler3c/qlbsWFZUEovFkkUH/YAJwQPELt1ogefn57frdLqk+Pj4g93d3X9UTkQSCDZQNADoSgpq9Xr9c7/fb1fFGnoz8TzESKxcxfXKCNfsKhMCGVEKumlgYKA5PCf82Wg0CvAEstdqtVapuCYJCetJeAJBGgRt/f39daogXBcUFGxxOBwm8TudzglGtZsaH7UthYWF6eIPjgvQrThtBIOzpl0HsTWM50JfX99JSdRaHAjz3Nzc66mpqaH6+vpdiL+3t/cFUzgH3gmfz3eGOoeeuZfhGEUaAD8mQmAdchmCI1rgytfV1fUdexjJGxkZOa78bLYR+yeY9uLi4lQZVxOiY9dVtGoPSS4jqlRFi2nAAtQdIO5B6kpLS1dLLpubJnYL00Cne2RcmTC+BbRdEtQiSZkRNeMZZzytYDS63e4ykoM41D/EV4lkSyeC5ouIFCUI4HVJQeeq1ISEhPdiQ5KuebpUYqya8XwiV0aWqmrMZvMXsSFOWxKSEFhAMMWW5XK5FOHXJSHhhK5lLElgy4cYXJzSDDHo5GOQBOO/yKivmIeOGwppeRc2sdFjcro+YGzihOzj+MpsZU329PSMzZuRf+V2oL4aol/Jycn3JJuP1MilWoHPbzAY7kgHpxADiTc5009D8oYRtEpBtEXdFSQJqeno6Pgm+WDILSGX6iNu4896Lr5rdLATx3mYr4pgu5BqumtAL7roIgVwCzWjOTk5lySRzWWhahAvx1huj3+nQR7Uot2N7OYZAGYAavhQWyQGaC4vdBDfRXyHxcdGdjCmV3Txu6SkZJXH4xnFnUFONTmnJUfzhdPiO7qTHU5C1AzQUUnWWgANCoHE+Me8gRKC+3w7bSpfk0SC8uJJtmL+gOgsXRxSRRG0l5gzMTGxnFr5boJr4eNRjnAN+DZGJH+/yyi8DaEALIwrPF/redFOVDJty4yLAXYLQcg/reKx6KgkAsIJHOZK3w7RSx5dvK+7sYCrnL+NT4cq4/F15AAAAABJRU5ErkJggg==",

      // ==========
      // 电话
      dialogVisibleCh: false,
      channelId: "", //房间id
      mute: true, // 静音
      Phone: true, // 静音

      intercomList: [], //对讲的列表
      usersList: [], //对讲的列表  好友列表
      Recently: [], //最近 会话

      isVoice: true, // 语音 发送状态
      isPlay: true, //播放状态
      audioSrc: "", // 地址

      recorder: new Record(),

      FileInformation: "", //音频文件

      sessions: null, //最新的会话

      countdown: "", //语音倒计时
      background: true,
      backgroundImages:
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAADGCAYAAADMtpCaAAAAAXNSR0IArs4c6QAABQlJREFUeF7tnE9oHFUcx3/fN7vbzbYSQbDaCEIVtWJ6SraK/xAEa6jFg7QF8eLFQxNL1QrqwejBS8HGpOQQLBVN3X0LrWIo0XpQqFCQpsE0m90ZUmOyb6hNi4jGtLUkK7OTjfmzu/Pmzczt7W2Zmd9nft/3/f1mZ2fewz0P7e0URX6UIv6g5cE9twxmdMwUMt9HyULLA3vKIPozjsSjU+agGRWsAnKCg2iS4rftEPljf0QBWwZVYMAPmzc1PzcyMnArbNgqkJsZBoTFX4sc5ALYG7aVPRImbF1GS8EXQHhBWHw4LFg9kCPhXywee2wmf2IiDFhdkGsOmkqVjbRlZa4FhTUELZnjbHN827P5fPe/QWCeoEpw4Lht8lejB7k19rYw+WFVmFxGblqLBiu/OFPMDanAfIAq1TwHMh4XZmbML8wfyB2v6eSGRPrS2BezfmD+Qa4VzzVt3fzM5HDfTVmYGsitsUFh5l6JHOS2RPaeXcx+JANTzmgpeBkML4kiP+UFCwpyxms+ZrAnpieyo41gwUHu1VkYRiI9XRi8XA8WCsg1Iv0c25J6+rcfP7tRCxYaaKknctvk+6IHuZl1Cyv3wVpYuBm50csGjH0zZia3EhYFyMnqOsCeKpnZ81VYJCC3JdJlSibbxS+f25Xv1R+QXgWntB24cNem5idHRgbmowW5F8wvhclfjh5E+FVY/D4N8u0JaOl8a7Z0gJZOVTnn9kcXrJp6Wjo13dz/LbTr1NTT0qnppl2nrJuWTku36k5Pd29VQ+juraqc/s2grJyWTkv3vwK6BSm7QUunpVtWQJtBm0GbQdkD8tL1Hu6kd7uP0dw/1wPRPAtWmJxmSrPUdaiPRkYtZZgUyIm+sLBIPf0n6ZP+k7S4WHlXyddHGlSNev6CSZ1v9ZGwr0YLcqL/PTdfGbevhn6ShvnOaGXkU9+clTZKIJADLYnZipReRgkMkjVKKKCqnI6Urx+q/dJhaCDHGO+8/2ndwg4MknVgIJCfmlICOV3C6RBOp5DtEr5BsnZeW8m+QH4KVAkkO+CN+pFnRl9nPlRqor4zYgzSAx4oI+n27LGjp3QaVE8BLZ2yN7R0Wjr5uwllrdYcqF2nrKSWTkunC1bZA1o6Ld16BXRTVXaFlk5Lp5uqsge0dFo6fT0K7gFdR8E11JdyZQ1BuCQsfn/kU7eq6whECgJwplTM7gRQjg4EXIux+Pbq1NXIQAaj3SvnoEcDAvptk+9f6aDQQSCaoDta2sS5I6ue5ocKAtFNgpGuNZM+VBABB22T99QqutBAAL4rFbPPO1aOEnQ1kTBap8YzV+o+owhlPqzBdtmF7OmGD6yCgkA4Kize5dUMA40RCPnYlqa2enPJQ6mjipVZvF0UT1z0ysbZrpwRwA4IM9srA1EGARgWJu+QhaiCZpPJDa2RL20BQofKGjW+xgigXmHmDviRrLqvNAigi7G7U2kZKyu3IBBuwKD2UoGPq2QjbQYwdAVdMstTOhCdFlZul2omkmOEK00bU62To8f9vbBV46waZVQGjA5hZr4Nmk3DMWJAT8nkB8OA1AUBGEtuvTPtZ60ZrxNaJ52zLAWLx9vCWjSqrhkYYX/J4v1eZ+h3++qMgCHb5Lv9BpHZfxkE0O/J1MbtYVi5UQsqM4adpSI/I3N2Kvu4GTF8bBf5myoBZI9xQKO3xx/eEXQBLy8g7n1k77bpcV7w2jHodgQNIHv8f7gjmgO8E/ofAAAAAElFTkSuQmCC",
      backgroundImage:
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAADGCAYAAADMtpCaAAAAAXNSR0IArs4c6QAABQ5JREFUeF7tnF1oHFUUx8+5s7vdbFIiiKk2qUKVxoopqMlWsVUEwRraUK00FfFFBaFtjFX0RZD0pT5EbElDHwJS0dTduw++lBKtDwp9KIgxmGazO0NqTPYOtR+IiPTDYlZmZifuJjs7M3fu9enOW5iZ85vzv/9z7uxm78WOB/sPsiIdBckHtnfuva0RrXexkPlWJgvbN+0tI8AfcUw8Pq+P67JgNsgKjgBzEF+7leU//V0GbBlkwxC/W9fS+tzk5Nht0bAakJMZjjGDvikd5ADIO6aRPSoStiqjSvB/EHAXM+iEKJgXyJLwTxKPPbGYPzUrAuYJcswB86myljaMzLWosIagijnOtcY3P5vPD/0dBeYLsoMjnjR1+pp8kFNj7zOdDvPCgmXkpLWkkfLuxWLuNA8sBMiu5r8QtCeZnpkOCwsHcsZrIbkmkb44/cWVMLDwIMeK55s2rntmbuL4raAwPpBTY+NMz70qHeS0RPKBWcweCQLjzqgSvIwEX2JF+pUfLCrIGq/rMY1sW5jNTjWCRQc5szPTtER6oTB+yQsmBOQYEX6IrU89/ev3n92sBxMGqvREaup0n3yQk9kQM3KHV8LEZuREL2uo7VvUM7lqmAyQldUNRPJUSc/+6MKkgJyWCJcgmexhP39u2n+7L5B+Bcd1HvGnu1tat09Ojl2XC3ImzC+ZTl+RDwL8hRn0fgUK7QlU0oXWrHKDko5XOevjjypYPvWUdHy6Od9bKNfxqaek49NNuY5bNyWdkq7mk57q3ryGUN2bVzn1zsCtnJJOSfefAqoFcbtBSaekW1ZAmUGZQZmB2wPipWtpboIjQ6/DW+/V/7GUkIJ97JFNcHx4AO7d0AYdnf11048EIgRhcP8eeHv/HtA0YgOEgzra74LRjweg+9HOmgyEgl7Ytc0ej7UtqVUyCQG5A/5i33ZPG0YGWQNuSbWho62h17lB9Qa8EYkbNDJ8EBpJtRLKDbLG5aPDb4BlgCAHN8gN3shp1Q8QGWQXo0ftCAdZAS1zWJ3A6ghuN5ACcoN62V2IdCvNUK+ApYDqGUUqqNoou1/+UPw0sTKiZZSlJfvnrquOSPNRkAJ2r1GgMGrVXKukU9ItK6DMoMygzMDtASWdkk69BUX3gKqj6BqqqZxbQyWdkk61IG4PKOmUdOotKLoHVB1F11BN5dwa/p/SXWQGfUD60i13HwGpIEQ8WypmdyBiWR4I8VqMxLe4S1elgTQCfdVr0OWAEE+YOj1QbVXhIASYhTvbu9n5ozekgRDgFqCWrreSXmxGiIdMnR6r+18XUatHEfGbUjH7vGVlmaCriYTWNT+TuezVq8RIp5GdZiF7plFDjAxCwFFm0AG/rhsJhID52Pqmbq+15ELsbVuZxHtY8dQFv2ys89wZIZJBpmdHgkC4QYg4wXTaGxTCC7qSTK7pkr61BQL28uxRE2qMEGGE6bnBMJK51wYGIcKF2D2pdBArc7cgBLyJGvSUCnSGJ5vAZkCCA1G3zPKVDgHOMCO3kzeTgGOEl5uaU11zUyevygSVEbVepme+jgppOEYE8VhJp4dEQDxBiDid3NiWDrPXjN8DrTKDtS0Fice7RW0a5WkGAnigZNATfk8Y9nxtRoinTZ32hQ0S5PplECL8lkw1bxFh5UYtqEwI7igV6dkgT8dzjZMRwU/MIn2XJ0DQeyzQ1B3xh7ZG3cDLD4j3Pdy/eWGGFvwujHoeowYIev+/qqA0A39oKiEAAAAASUVORK5CYII=", //箭头背景图片
      // isMore: "/operationCenter",
      isMore: "",
    };
  },
  beforeMount() {
    console.log(this.menuList,'123123');
  },
  created() {
    // if (JSON.parse(sessionStorage.getItem("info")).type == 2) {
    //   this.$store.getters.menuList.forEach((ele) => {
    //     if (ele.menuCode == "KanbanManagement") {
    //       this.menuList.push(ele);
    //     }
    //   });
    // } else {
    //   this.menuList = this.$store.getters.menuList;
    // }

    this.menuList = this.$store.getters.menuList;
    this.asideMenuList = this.$store.getters.asideMenuList;

    // this.$store.commit("tab/selectMenu", this.asideMenuList[0]);
  },
  mounted() {
    // 登录 im
    init();
    nim.connect();
    let that = this;
    document.addEventListener("click", (e) => {
      let dom = document.getElementById("headOption");
      if (dom) {
        // 如果点击的区域不在自定义dom范围
        if (!dom.contains(e.target)) {
          that.headOptionShow = false;
        }
      }
    });
    document.addEventListener("click", (e) => {
      let dom = document.getElementById("shopSelectBox");
      if (dom) {
        // 如果点击的区域不在自定义dom范围
        if (!dom.contains(e.target)) {
          that.shopOptionShow = false;
        }
      }
    });
    //let t = window.devicePixelRatio; // 获取下载的缩放 125% -> 1.25    150% -> 1.5
    // 如果进行了缩放，也就是不是1
    //document.body.style.zoom = 0.8; // 就去修改页面的缩放比例，这个公式我自己算的，不准确，勉强。
    this.isdefault = this.$route.path;
    this.getIntercom();
  },

  computed: {
    getWD() {
      let obj = this.$route.path;
      return obj;
    },
    ...mapState({
      tags: (state) => state.tab.tabsList,
    }),
    classControl: {
      get() {
        let id = this.asideIndex;
        let ty = this.shopType;
        return id + "cc" + ty;
      },
    },
    asideMenuList: {
      get() {
        return this.$store.getters.asideMenuList(
          this.$store.getters.headIndexId
        );
      },
      set() {},
    },
    asideIndex: {
      get() {
        return this.$store.getters.asideIndex;
      },
      set(val) {
        //this.$store.commit("menu/SET_ASIDE_INDEX", val);
        //let path = this.asideMenuList.find(n=> n.id = val).path
      },
    },
    asideChildIndex: {
      get() {
        return this.$store.getters.asideChildIndex;
      },
      set(val) {
        // console.log(1234123);
        // this.$store.commit("menu/SET_ASIDE_INDEX", null);
        // this.$store.commit("menu/SET_ASIDE_CHILD_INDEX", val);
      },
    },
    key() {
      return this.$route.fullPath;
    },
    //获取面包屑
    navigation() {
      let sum = this.$store.getters.navigation;
      return sum;
    },
    RuoterNavigation() {
      let sum = this.$store.getters.RuoterNavigation;
      return sum;
    },
    Unreadable() {
      return this.$store.getters.Unreadable;
    },
    callType() {
      //聊天的信息
      return this.$store.getters.callType;
    },
    callPhone() {
      return this.$store.getters.callPhone;
    },
    tabsList() {
      return this.$store.getters.tabsList;
    },
    users() {
      return this.$store.getters.users;
    },
    RecentlyList() {
      return this.$store.getters.RecentlyList;
    },
    isAutorun() {
      return this.$store.getters.isAutorun;
    },
    beforeSessions() {
      return this.$store.getters.beforeSessions;
    },
  },
  watch: {
    beforeSessions: {
      handler(newVal, oldVal) {
        this.sessions = newVal;
      },
    },
    isAutorun: {
      handler(newVal, oldVal) {
        // isAutorun = newVal;
        if (newVal) {
          // debugger
          this.getAutorun();
        }
      },
    },
    RecentlyList: {
      handler(newVal, oldVal) {
        this.Recently = newVal;
      },
    },

    users: {
      handler(newVal, oldVal) {
        this.usersList = newVal;
      },
    },
    callType: {
      handler(newVal) {
        //  this.dialogVisibleCh  =  newVal
      },
    },
    callPhone: {
      handler(newVal) {
        // this.dialogVisibleCh = newVal;
      },
    },
    store: {
      handler(newVal) {},
      deep: true,
    },
    $route(to, from) {
      this.isdefault = to.fullPath;
      var idxs = this.keepAliveList.indexOf(to.name);
      if (idxs == -1) {
        this.keepAliveList.push(to.name);
      } else if (to.name != from.name) {
        var idx = this.keepAliveList.indexOf(from.name);
        this.keepAliveList.splice(idx, 1);
      }
    },
  },
  methods: {
    More(Url) {
      if (Url == "/operationCenter") {
        this.isMore = Url;
      }
    },
    getAutorun() {
      //自动播放
      let than = this;
      this.audioSrc = this.sessions.lastMsg.file.mp3Url;

      setTimeout(() => {
        var dom = than.$refs.up;
        dom.play(); //播放

        // 改变页面上的状态
        than.Recently.forEach((item) => {
          if (
            item.msgReceiptTime == than.sessions.msgReceiptTime &&
            than.sessions.lastMsg.type == "audio"
          ) {
            item.isAutorun = false;
            var bur = this.sessions.lastMsg.file.dur;
            // //  计算 播放的时间  改变状态
            setTimeout(() => {
              than.$store.commit("tab/isAutorun", false);
              item.isAutorun = true;
            }, bur);
          }
        });
      }, 1000);
    },

    getVoiceEnd(item, index) {
      window.clearInterval(this.countdown);
      //结束 录制语音
      this.$set(this.Recently[index], "isvioce", true);
      let than = this;

      this.recorder.stopRecord({
        success: (res) => {
          //此处可以获取音频源文件(res)，用于上传等操作
          this.FileInformation = res;
        },
        error: (e) => {
          this.Voice = false;
        },
      });

      nim.sendFile({
        scene: item.lastMsg.scene,
        to: item.lastMsg.to,
        type: "audio",
        blob: than.FileInformation,
        // fastPass: '{"w":200,"h":110,"md5":"xxxxxxxxx"}',
        beginupload: function (upload) {
          // - 如果开发者传入 fileInput, 在此回调之前不能修改 fileInput
          // - 在此回调之后可以取消图片上传, 此回调会接收一个参数 `upload`, 调用 `upload.abort();` 来取消文件上传
        },
        uploadprogress: function (obj) {},
        uploaddone: function (error, file) {},
        beforesend: function (msg) {
          pushMsg(msg);
        },
        done: sendMsgDone,
      });
      function sendMsgDone(error, msg) {
        console.log(
          "发送" +
            msg.scene +
            " " +
            msg.type +
            "消息" +
            (!error ? "成功" : "失败") +
            ", id=" +
            msg.idClient,
          error,
          msg
        );
      }
    },
    getVoiceStart(row, index) {
      //发送语音
      this.$set(this.Recently[index], "isvioce", false);
      // 开始录音
      this.recorder.startRecord({
        success: (res) => {
          this.FileInformation = res;
        },
        error: (e) => {
          this.Voice = false;
          // this.$toast(e);
        },
      });
      var tiem = 60;
      let than = this;
      this.countdown = window.setInterval(() => {
        tiem--;
        if (tiem < 0) {
          than.$message.error("已发送");
          window.clearInterval(than.countdown);
          than.getVoiceEnd();
        }
      }, 1000);
    },
    playAudioStart(item, index) {
      item.isvPale = false;
    },
    playAudioEnd(item) {
      item.isvPale = true;
    },

    getIntercom() {
      return this.$store.getters.IntercomList;
    },
    getAnswer() {
      let callType = this.$store.getters.callType;
      //接听电话
      var param = {
        channelId: callType.channelId, //房间id
        account: callType.from,
        requestId: callType.requestId,
        offlineEnabled: false,
        attachExt: "12",
        autoJoin: false,
        uid: callType.from,
        joinAttachExt: "join ext",

        // channelId: add.channelId,
        // account: "123",
        // requestId: add.requestId,
        // offlineEnabled: false,
        // attachExt: "12",
        // autoJoin: false,
        // uid: "123",
        // joinAttachExt: "join ext",
      };

      nim
        .signalingAccept(param)
        .then((data) => {
          console.warn("独立呼叫信令，接受别人的邀请，data：", data);
          if (autoJoin) {
            console.warn(
              "独立呼叫信令，接受别人的邀请后，自动加入频道成功，data：",
              data
            );
          }
        })
        .catch((error) => {
          console.warn(
            "独立呼叫信令，取消邀请别人加入频道失败，error：",
            error
          );
          if (error.code == 10404) {
            console.warn("独立呼叫信令：频道不存在");
          } else if (error.code == 10408) {
            console.warn("独立呼叫信令：邀请不存在或已过期");
          } else if (error.code == 10409) {
            console.warn("独立呼叫信令：邀请已经拒绝");
          } else if (error.code == 10410) {
            console.warn("独立呼叫信令：邀请已经接受");
          } else if (error.code == 10201) {
            console.warn("独立呼叫信令：对方不在线");
          }
        });
    },
    // 关闭房间 结束通话
    signalingLeave() {
      let callType = this.$store.getters.callType;
      var param = {
        channelId: callType.channelId, //房间id
        offlineEnabled: true,
        ext: "",
      };
      nim
        .signalingClose(param)
        .then((data) => {
          console.warn("独立呼叫信令，关闭频道成功，data：", data);
          this.getSignalingCancel(); //取消邀请
        })
        .catch((error) => {
          console.warn("独立呼叫信令，关闭频道失败，error：", error);
          if (error.code == 10406) {
            console.warn("独立呼叫信令：你不在频道内，无法关闭");
          }
        });
    },
    getPhone() {
      this.dialogVisibleCh = false;
      this.store.commit("tab/callPhone", false);
    },
    // 挂断 静音
    getmute() {
      this.mute = !this.mute;
    },
    // ==================
    stopBubble(e) {
      //如果提供了事件对象，则这是一个非IE浏览器
      if (e && e.stopPropagation)
        //因此它支持W3C的stopPropagation()方法
        e.stopPropagation();
      //否则，我们需要使用IE的方式来取消事件冒泡
      else window.event.cancelBubble = true;
    },
    getunfold(e) {
      // ====
      // this.dialogVisibleCh = true;//
      // let event1 = e.currentTarget;
      // let event2 = e.target;
      // if (event1 == event2) {
      if (this.keys) {
      } else {
        this.isunfold = !this.isunfold;
        let arr =
          "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAYCAYAAAAPtVbGAAAAAXNSR0IArs4c6QAAA8xJREFUSA2tVVtoVGcQ/ufsarKbLCheC6WaUB/qBZE+9EEQq5tA3JdQMFDqxsSKmAQjYqsBJXipCqliaWi2wUvUtUV80QbbXFsv5CWighF8kHgBTdEqmtY92WN294zfnOwvSznrLugPw8yZf+b7zsz/nzmk8lyhUKg0kUi0MHPA5/M1dHZ2DueZqrz5BFZUVCwZHx//EwRTJd6yrBqonWLns3KSBIPBRaigD2BCcB5SKdW4gVf/9LKVFfunlATqW1fRKx1jaMNNg+ATIurH3jTITsMwDrvFaR+TWsiK1o3eN49pn+isJGVlZfNAIC2aCb23v79/X2aim03kWYPYEVTzVTjyslHHuJKUl5eXIOAvEHyApJa+vr5mnfB/vebn2OItZ9kn/lN1/hEi4wtFlFI27a+JxOeK3zkTgH4KwBAAnV7btr0aex+iPT/09vZul0C3VXucZyTj5o2nz8wru5hX7iKyQXQ1HIkdAt62pJ38HnmrDfS9Co5ByG6AfyOCjTmQCAi2uIFr3/Fa9QytGVDMy4cj5rfar4qK9ihS/ypFlfUdsdnSrr0Qwls3ejyeyrQsQ4sa3iRlMVA5q4JJXyM9Tko1bz4SmyWh0WoySRmnlWKvaRlfSrvmoYpbAG3NxAJA5mNW+/T6wuHqiHkAHdjzIqWqEOjgsMG/qZRqYGUvkUoELZUVJY8Nj00nnDA2lunwybb3rtjMaq7r7dKB+eqOBv9DaRmzPVvn2L6Cvx0bN/S9kAgYuotv0enKBI9lOYS40k/eC0l1e/wjnKsfTCMTDNL/ZKnY8D9wSHDI70Zm22vTgFc0CTOFxDYMNSS36x7YFmCMhHF90VtnPe/u7h5K229V4SPxEk6kmhD035RCdVaCN/3BBaP3YyAme1LC+FUq+A7iBdGpZDJ5MS038ZEekIScazzV7rTKUDta1wWeSvzog7HtLEOV1O9HG/2PDAy+DlSwAnuH0bajIrAfQ5pQ3W7orAtDcCZOO0iKBj/eWNwmgTXtsUWYAjuAY5HXK9NjYnb19PRchC3iLPyk5qOiS3jDZhC9woe6X+9l6mhd4J9wW/zzwIzC6zK3NrTz9LFU7LxiNZmJmqIbCu9IvOuBd3V13UZ1QbzNcxDtA9HWTPBMO1rvu9xWRTHxWUnzJAhKYZ6L1hW16DhXEtmUgwdJOcxREB3EpN6kk7JpJrYMogt+b7H8V3AsEyvngAL4Z5hL8vstRuIvIBSAH9HCzRokl85aiU7EuB+EvQrAY0KQ9pt6Px+dk0RAcAMH8CtYCqJreHyM8zqTD7iOeQ1ZC4/2kHCRKAAAAABJRU5ErkJggg==";
        let add =
          "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAYCAYAAAAPtVbGAAAAAXNSR0IArs4c6QAAA3VJREFUSA2tlWtIk2EUx92czEmBUTOFKBP80EVC+tCHxKTmvAxlBBMiw6BvSUZ0ExTxkkpWFAlFEHShIvJDJct7F00/GBVk0CeLoIxFoRW5LXVbvzP3GIzXbZAPnJ3znsv//5zzPu8zXVyMy2azZczOzrYFAoHlJpOpsrOzczzG0jhDLIlFRUXZMzMzjyFYIfler3c/qlbsWFZUEovFkkUH/YAJwQPELt1ogefn57frdLqk+Pj4g93d3X9UTkQSCDZQNADoSgpq9Xr9c7/fb1fFGnoz8TzESKxcxfXKCNfsKhMCGVEKumlgYKA5PCf82Wg0CvAEstdqtVapuCYJCetJeAJBGgRt/f39daogXBcUFGxxOBwm8TudzglGtZsaH7UthYWF6eIPjgvQrThtBIOzpl0HsTWM50JfX99JSdRaHAjz3Nzc66mpqaH6+vpdiL+3t/cFUzgH3gmfz3eGOoeeuZfhGEUaAD8mQmAdchmCI1rgytfV1fUdexjJGxkZOa78bLYR+yeY9uLi4lQZVxOiY9dVtGoPSS4jqlRFi2nAAtQdIO5B6kpLS1dLLpubJnYL00Cne2RcmTC+BbRdEtQiSZkRNeMZZzytYDS63e4ykoM41D/EV4lkSyeC5ouIFCUI4HVJQeeq1ISEhPdiQ5KuebpUYqya8XwiV0aWqmrMZvMXsSFOWxKSEFhAMMWW5XK5FOHXJSHhhK5lLElgy4cYXJzSDDHo5GOQBOO/yKivmIeOGwppeRc2sdFjcro+YGzihOzj+MpsZU329PSMzZuRf+V2oL4aol/Jycn3JJuP1MilWoHPbzAY7kgHpxADiTc5009D8oYRtEpBtEXdFSQJqeno6Pgm+WDILSGX6iNu4896Lr5rdLATx3mYr4pgu5BqumtAL7roIgVwCzWjOTk5lySRzWWhahAvx1huj3+nQR7Uot2N7OYZAGYAavhQWyQGaC4vdBDfRXyHxcdGdjCmV3Txu6SkZJXH4xnFnUFONTmnJUfzhdPiO7qTHU5C1AzQUUnWWgANCoHE+Me8gRKC+3w7bSpfk0SC8uJJtmL+gOgsXRxSRRG0l5gzMTGxnFr5boJr4eNRjnAN+DZGJH+/yyi8DaEALIwrPF/redFOVDJty4yLAXYLQcg/reKx6KgkAsIJHOZK3w7RSx5dvK+7sYCrnL+NT4cq4/F15AAAAABJRU5ErkJggg==";
        if (!this.isunfold) {
          this.coverImgUrl = add;
        } else {
          this.coverImgUrl = arr;
        }
      }
      // }
    },
    getitems(e) {
      let event1 = e.currentTarget;
      let event2 = e.target;
      if (event1 == event2) {
        //抬起事件
        this.lastTime = new Date().getTime();
        if (this.lastTime - this.firstTime < 200) {
          this.keys = false;
          this.getunfold(); //点击
        }
      }
    },
    getMouseXY(e) {
      //拖曳事件
      this.firstTime = new Date().getTime();
      let event1 = e.currentTarget;
      let event2 = e.target;
      if (event1 == event2) {
        let odiv = this.$refs.Floating; //获取目标元素
        //算出鼠标相对元素的位置
        let disX = e.clientX - odiv.offsetLeft;
        let disY = e.clientY - odiv.offsetTop;

        document.onmousemove = (e) => {
          //鼠标按下并移动的事件
          //用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
          let left = e.clientX - disX;
          let top = e.clientY - disY;
          //绑定元素位置到positionX和positionY上面
          this.positionX = top;
          this.positionY = left;
          //移动当前元素
          const mainWidth =
            document.getElementById("classifyScroll").clientWidth;
          if (left > mainWidth) {
            odiv.style.left = mainWidth + "px";
          } else if (left < 200) {
            odiv.style.left = 200 + "px";
          } else {
            odiv.style.left = left + "px";
          }
          const mainHeight = document.documentElement.clientHeight;

          if (top > mainHeight - 100) {
            odiv.style.top = mainHeight - 100 + "px";
          } else if (top < 70) {
            odiv.style.top = 70 + "px";
          } else {
            odiv.style.top = top + "px";
          }
        };

        document.onmouseup = (e) => {
          document.onmousemove = null;
          document.onmouseup = null;
        };
      }
      this.stopBubble(e); //这样就不会再冒泡给父级了
    },
    // 确认
    getConfirm() {
      if (this.textarea2 == "" || this.textarea2 == null)
        return this.$message.error("不能为空");
      this.dialogVisibleMore = false;
    },

    handleClose() {
      this.dialogVisibleMore = false;
    },
    // 帮助与反馈
    getMore() {
      this.dialogVisibleMore = true;
    },
    mouChange() {
      this.showOnline = true;
    },
    TakeBackBtn() {
      this.isCollapse = !this.isCollapse;
      if (this.isCollapse) {
        this.background = false;
      } else {
        this.background = true;
      }
    },
    // tab
    rightClick() {
      if (this.jiaWidth <= 0) {
        return console.log("没有可位移的值");
      } else {
        // 拿到 当前位移的值
        let isWidth = this.$refs.tabRef.$el.style.marginLeft;
        // 判断是否大于 可位移的值 大于就不能位移
        const isjia = parseInt(isWidth.substr(0, isWidth.length - 2));

        if (isjia >= 0) {
          this.$refs.tabRef.$el.style.marginLeft = 0 + "px";
        } else {
          let dqWidth = this.$refs.tabRef.$el.style.marginLeft;
          //  剪切为数字
          let wen = parseInt(dqWidth.substr(0, dqWidth.length - 2));
          let sum = wen + 85;
          this.$refs.tabRef.$el.style.marginLeft = sum + "px";
        }
      }
    },
    leftClick() {
      // 获取tab的宽度
      const tabWidth = document.getElementById("classifyTab").clientWidth;
      // 获取main的宽度
      const mainWidth = document.getElementById("classifyScroll").clientWidth;
      // 获取tab的外边距
      const tabMarginLeft = this.$refs.tabRef.$el.style.marginLeft;

      // 判断tab 的宽度是否大于main的宽度 大于就给margin left 加30px
      if (tabWidth > mainWidth) {
        let number = tabWidth - mainWidth;
        this.jiaWidth = number;
        this.$refs.tabRef.$el.style.marginLeft = -number + "px";
        // this.jianClick++
      } else {
      }
    },

    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert("submit!");
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    Confirm() {
      this.ruleForm.oldPwd = "";
      this.ruleForm.newPwd = "";
      this.ruleForm.repeat = "";
      this.Changes = false;
    },
    // 提交修改
    submit() {
      let userId = window.sessionStorage.getItem("userName");
      // this.resetForms.id = userId.shopType
      let than = this;
      let obj = {
        params: {
          userName: userId,
          oldPwd: md5(than.ruleForm.oldPwd),
          newPwd: md5(than.ruleForm.newPwd),
          repeat: md5(than.ruleForm.repeat),
        },
      };
      Modify(obj).then((res) => {
        const { flag, data, msg } = res.data;
        if (flag) {
          this.Changes = false;
        } else {
          this.$message.error(msg);
        }
      });
    },
    dialogVisible() {
      this.Changes = false;
    },
    //退出登录
    loginOut() {
      this.$store.commit("user/LOGINOUT");
      nim.logout();
    },

    //切换店铺
    toggleShop(obj) {
      window.sessionStorage.setItem("indexObj", obj.shopType);
      this.$store.commit("user/SET_CURR_SHOP", obj);
      // this.shopOptionShow = false;
    },
    // 切换导航
    menuClick(url, i, item) {
      this.$store.commit("menu/RuoterNavigation", url);
      this.isdefault = url; //
      // this.navigation = item.menuName;
      this.$store.commit("menu/navigation", item.menuName);

      let asideMenu = this.$store.getters.asideMenuList(i);
      if (i == 640) {
        this.$router.push("/smart");
      } else {
        this.$router.push(url);
      }
    },
    upIndexChange(val) {
      this.upIndex = val;
    },
    handlerAvatar() {
      this.headOptionShow = !this.headOptionShow;
      this.shopOptionShow = false;
    },
    handlerShop() {
      this.shopOptionShow = !this.shopOptionShow;
      this.headOptionShow = false;
    },
  },
};
</script>
<style scoped lang="less">
.el-container {
  background-color: #f6f7f8;
}
.el-aside {
  background-color: #1b2656;
  position: relative;
  overflow: visible;
}
.el-menu {
  // margin-top: 6.6rem;
  border-right: none;
  font-family: "Microsoft YaHei";
}
.el-menu--collapse {
  width: 88px !important;
}
.el-header {
  background-color: #ffffff;
  height: 6rem;
}
.el-main {
  padding-top: 0.9rem;
  padding-left: 2rem;
  z-index: 99;
}
.multi-function {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url("~@/assets/new-icon/icon_more.png") no-repeat center;
  position: absolute;
  left: 18%;
  bottom: 2%;
  cursor: pointer;
}
.textarea {
  width: 564px;
  height: 207px;
  background: #f9f9f9;
  .el-textarea {
    /deep/ .el-textarea__inner {
      background: #f9f9f9 !important;
      height: 200px !important;
      max-height: 500px !important;
      border: 1px solid #fff;
    }
  }
  /deep/ input {
    outline: none;
  }
  /deep/ .textarea {
    outline: none;
  }
}

.shop-select {
  width: 141px;
  height: 3.2rem;
  line-height: 3.2rem;
  background: #ffffff url("~@/assets/icon/clock2.png") no-repeat 2rem center;
  background-size: 1.5rem 1.8rem;
  border-radius: 2.05rem;
  // padding: 0 4.2rem 0 4.6rem;
  border: 0.1rem solid #d1d1d1;
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: center;

  i {
    display: block;
    width: 0;
    height: 0;
    border-left: 0.4rem solid transparent;
    border-right: 0.4rem solid transparent;
    border-top: 0.6rem solid #333;
    position: absolute;
    right: 1.6rem;
    top: 1.3rem;
    &.roll {
      transform: rotate(180deg);
    }
  }
  > span {
    display: block;
    width: 70px;
    height: 3.2rem;
    font-size: 1.4rem;
    color: #333333;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 1rem;
  }
  .shop-select-box {
    position: absolute;
    left: 0;
    top: 5rem;
    background: #fff;
    border-radius: 0.4rem;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
    width: 140px;
    // height: 150px;
    overflow-x: hidden;
    // overflow: auto;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    z-index: 9999;
    .shop-select-li {
      height: 17px;
      width: 80px;
      color: #666666;
      font-size: 1.2rem;
      line-height: 1.7rem;
      padding: 0.9rem 3.2rem 0.9rem 4.8rem;
      text-align: left;
      overflow: hidden;
      // overflow-y:auto;
      white-space: nowrap;
      text-overflow: ellipsis;
      background: url(~@/assets/icon/t5.png) no-repeat 1.4rem center;
      background-size: 14px 14px;
      background-position: 26px 11px;
      &:hover {
        color: #4a8cff;
        background-color: #dbe8ff !important;
        background: url(~@/assets/icon/t4.png) no-repeat 1.4rem center;
        background-size: 14px 14px;
        background-position: 26px 11px;
      }
    }
    &::after {
      display: block;
      content: "";
      width: 0;
      height: 0;
      border-left: 0.8rem solid transparent;
      border-right: 0.8rem solid transparent;
      border-bottom: 1.4rem solid #fff;
      position: absolute;
      left: 6rem;
      top: -1.4rem;
    }
  }
}

.headerLeft {
  float: left;
  margin-top: 2.1rem;
  color: #000b3c;
  font-weight: 400;
  .el-breadcrumb {
    font-size: 1.8rem !important;
    cursor: pointer !important;
    /deep/ .el-breadcrumb__item:last-child .el-breadcrumb__inner:hover {
      cursor: pointer !important;
    }
  }
}
.headeRight {
  float: right;
  display: flex;
  justify-content: center;
  margin-top: 1.8rem;
  .avatar {
    display: block;
    width: 3.1rem;
    height: 3.25rem;
    border-radius: 50%;
    cursor: pointer;
    margin-left: 42px;
  }
  p {
    max-width: 200px;
    height: 4rem;
    line-height: 3rem;
    font-size: 1.6rem;
    color: #666666;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 4.6rem 0 1rem;
  }
  .exit {
    cursor: pointer;
    a {
      width: 50px;
      height: 32px;
      display: block;
      text-align: center;
      line-height: 32px;
      padding-left: 1rem;
      font-size: 1.6rem;
      background: url(~@/assets/icon/ext.png) no-repeat 1.4rem center;
      background-size: 14px 16px;
      background-position: 0px 8px;
    }
  }
}

.TakeBack {
  display: inline-block;
  cursor: pointer;
  // background: url("~@/assets/bg/icon_shouqi.png") no-repeat;
  // background-image: url("~@/assets/bg/icon_shouqis.png");
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
  height: 9.8rem;
  position: absolute;
  top: 60%;
  width: 1.3rem;
  height: 9.9rem;
  z-index: 9999;
}
.upIndex {
  background-color: #081030 !important ;
  position: relative;
}
.el-menu-vertical-demo p {
  width: 100%;
  height: 6.6rem;
  font-size: 2rem;
  text-align: center;
  line-height: 6.6rem;
  color: #fff;
  letter-spacing: 1.1px;
}
.el-menu-vertical-demo .el-menu-item {
  padding-left: 2.7rem !important;
  font-size: 1.6rem;
  span {
    margin-left: 0.8rem;
  }
  .item {
    /deep/ .el-badge__content {
      margin-top: 10px;
      margin-right: -10px;
      background-color: #ff4b20;
      border: 1px solid #ff4b20;
    }
  }
}

.el-menu-vertical-demo .el-menu-item {
  .menu-curr-smart {
    width: 2rem;
    height: 2rem;
    display: inline-block;
    background: url("~@/assets/new-icon/icon-s1.png") no-repeat;
    background-size: 100%;
  }
  .menu-curr-KanbanManagement {
    //看板管理
    width: 2rem;
    height: 2rem;
    display: inline-block;
    background: url("~@/assets/new-icon/icon-s2.png") no-repeat;
    background-size: 100%;
  }
  .menu-curr-642 {
    width: 2rem;
    height: 2rem;
    display: inline-block;
    background: url("~@/assets/new-icon/icon-s3.png") no-repeat;
    background-size: 100%;
  }
  .menu-curr-643 {
    width: 2rem;
    height: 2rem;
    display: inline-block;
    background: url("~@/assets/new-icon/icon-s4.png") no-repeat;
    background-size: 100%;
  }
  .menu-curr-644 {
    width: 2rem;
    height: 2rem;
    display: inline-block;
    background: url("~@/assets/new-icon/icon-s5.png") no-repeat;
    background-size: 100%;
  }
  .menu-curr-645 {
    width: 2rem;
    height: 2rem;
    display: inline-block;
    background: url("~@/assets/new-icon/icon-s6.png") no-repeat;
    background-size: 100%;
  }
  .menu-curr-646 {
    width: 2rem;
    height: 2rem;
    display: inline-block;
    background: url("~@/assets/new-icon/icon-s7.png") no-repeat;
    background-size: 100%;
  }
  .menu-curr-communication {
    width: 2rem;
    height: 2rem;
    display: inline-block;
    background: url("~@/assets/new-icon/icon-s5.png") no-repeat;
    background-size: 100%;
  }
  // 监控
  .menu-curr-securityCamera {
    width: 2rem;
    height: 2rem;
    display: inline-block;
    background: url("~@/assets/new-icon/icon-s8.png") no-repeat;
    background-size: 100%;
  }
  // 运营中心
  .menu-curr-operationCenter {
    width: 2rem;
    height: 2rem;
    display: inline-block;
    background: url("~@/assets/new-icon/icon-s9.png") no-repeat;
    background-size: 100%;
  }
  // 广播
  .menu-curr-broadcast {
    width: 2rem;
    height: 2rem;
    display: inline-block;
    background: url("~@/assets/new-icon/icon-s11.png") no-repeat;
    background-size: 100%;
  }
  // 广告屏
  .menu-curr-screenAdvertising {
    width: 2rem;
    height: 2rem;
    display: inline-block;
    background: url("~@/assets/new-icon/icon-s10.png") no-repeat;
    background-size: 100%;
  }
}

// ============
#classifyScroll {
  position: relative;
  .Floating {
    z-index: 99999;
    position: fixed;
    width: 48px;
    height: 48px;
    bottom: 10%;
    right: 10%;
    border-radius: 50%;
    box-shadow: 0px 1px 5px #666666;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: url("~@/assets/indexImg/icon_gb.png") #ffffff no-repeat left 50%
      top 50%;
    .isData {
      width: 100%;
      height: 100%;
      font-size: 1.4rem;
      text-align: center;
      line-height: 48px;
    }
    .ul {
      width: 20.4rem;
      height: 27.5rem;
      position: absolute;
      background: #ffffff;
      box-shadow: 0px 1px 5px #666666;
      top: -305px;
      border-radius: 4px;

      padding: 10px;

      .nav {
        overflow-y: auto;
        .th {
          display: block;
          width: calc(100% - 5);
          background: #f2f2f2;
          line-height: 30px;
          height: 30px;
          padding-left: 5px;
          display: flex;
          align-items: center;
          position: relative;
          i {
            position: absolute;
            right: 10px;
          }
        }
        li {
          font-size: 1.4rem;
          display: flex;
          justify-content: space-around;
          margin-top: 15px;

          span {
            width: 70%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          img {
            width: 11px;
            height: 19px;
          }
        }
      }
      .opn {
        width: 100%;
        margin-top: 2rem;
        display: flex;
        justify-content: center;
        .btn {
          background-color: #d9f4fd;
          border: 1px solid #d9f4fd;
          padding: 5px 20px;
          font-size: 14px;
          border-radius: 4px;
          margin: 0 auto;
        }
      }
    }
  }
}
.dialogCall {
  /deep/ .el-dialog {
    background-color: #131f2b !important;
    border-radius: 1.2rem;
    height: 40%;
    min-width: 320px;
    min-height: 320px;
  }
  .dialogWarp {
    .haedr {
      width: calc(100% -6rem);
      height: 6rem;
      display: flex;
      margin-left: 6rem;
      .portrait {
        width: 7rem;
        height: 6rem;
        background-color: #38ccfe;
        border-radius: 50%;
        text-align: center;
        line-height: 6rem;
        color: #fff;
        font-size: 2rem;
        font-weight: 300;
        font-family: PingFangSC-Regular;
      }
      .text {
        padding-left: 1.4rem;
        width: 100%;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        .s1 {
          display: block;
          color: #fff;
          font-size: 2rem;
          font-family: PingFang-SC-Medium;
          font-weight: 400;
        }
        .s2 {
          margin-top: 0.4rem;
          display: block;
          color: #fff;
          font-size: 1.6rem;
          font-family: PingFangSC-Regular;
          font-weight: 300;
        }
      }
    }
    .state {
      height: 10rem;
      color: #fff;
      font-size: 1.8rem;
      text-align: center;
      line-height: 10rem;
      font-family: PingFangSC-Regular;
      font-weight: 300;
    }
    .operation {
      height: 10rem;
      font-size: 1.4rem;
      display: flex;
      justify-content: space-around;
      .colse {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .colseImg {
          width: 5rem;
          height: 5rem;
          background-color: #26313c;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            cursor: pointer;
          }
        }
      }
      .open {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .colseImg {
          width: 5rem;
          height: 5rem;
          background-color: #ef6240;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.More {
  width: 25rem;
  position: absolute;
  right: -25rem;
  top: -7rem;
  min-height: 200px;
  background: #fff;
  z-index: 999999;
  border-radius: 5px;
  padding: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
  // box-shadow: 0 5px 5px rgb(0 0 0 / 7%);
  cursor: pointer;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.3);
  -o-box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.3);
  .li {
    width: 33%;
    font-size: 1.4rem;
    color: #666666;
    margin-left: 0px !important;
    text-align: center;
    line-height: 40px;
  }
  .lis {
    color: #4a8cff;
  }
  .li:hover {
    color: #4a8cff;
  }
}
.More::after {
  content: "";
  left: -14px;
  border: 7px solid transparent;
  border-right-color: #fff;
  position: absolute;
  top: 50%;
}
</style>
