import { getInfo, address, getCodeData } from "../../api/user";
import router from "../../router/index";
import store from "../../store";

const user = {
  namespaced: true,
  state: {
    token: "",
    LoginInfo: JSON.parse(sessionStorage.getItem("LoginInfo")) || {},
    userInfo: JSON.parse(sessionStorage.getItem("userInfo")) || {
      shopType: "",
      shopNo: "",
      shopName: "",
      nickName: "",
      merchantNo: ""
    },
    userName: "" || sessionStorage.getItem("userName"),
    roleType: null || sessionStorage.getItem("roleType"),   // 账户角色权限
    copyright: "v2473", // 版本号
    dict: JSON.parse(localStorage.getItem("dict") == undefined ? "[]" : localStorage.getItem("dict")), // 数据字典
    sysData: JSON.parse(sessionStorage.getItem("sysData")) || [],
    ifUpdate: false,
    fileServer: sessionStorage.getItem("fileServer") || "", // 图片上传路径
    fileRequest: sessionStorage.getItem("fileRequest") || "", // 图片预览
    // coupon_status: JSON.parse(sessionStorage.getItem("coupon_type")) || [],
    // audit_status: JSON.parse(sessionStorage.getItem("audit_type")) || [],
    addressList: JSON.parse(sessionStorage.getItem("addressList")) || [],
    realAvatarImage: "this.src=\"" + require("assets/icon/avater-init.png") + "\"",
    realBrandImage: "this.src=\"" + require("assets/icon/brand-init.png") + "\"",
    realClassImage: "this.src=\"" + require("assets/icon/class-init.png") + "\"",
    realGoodsImage: "this.src=\"" + require("assets/icon/goods-init.png") + "\"",
    srcCode: null || sessionStorage.getItem("srcCode"),

    wyUser: ""
  },
  getters: {
    coupon_status(state) {
      // 活动状态
      if (state.dict.length == 0) return;
      let arr = state.dict.filter(item => item.code == "coupon_type");
      return arr[0].list;
    },
    // audit_status(state) {
    //   // 活动状态
    //   if (state.dict.length == 0) return;
    //   let arr = state.dict.filter(item => item.code == "audit_type");
    //   return arr[0].list;
    // },
    activity_status(state) {
      // 活动状态
      if (state.dict.length == 0) return;
      let arr = state.dict.filter(item => item.code == "activity_status");
      return arr[0].list;
    },
    activity_status(state) {
      // 活动状态
      if (state.dict.length == 0) return;
      let arr = state.dict.filter(item => item.code == "activity_status");
      return arr[0].list;
    },
    ActivityauditType(state) {
      // 审核状态
      if (state.dict.length == 0) return;
      let arr = state.dict.filter(item => item.code == "ActivityauditType");
      return arr[0].list;
    },
    isOnlineType(state) {
      // 是否上架
      if (state.dict.length == 0) return;
      let arr = state.dict.filter(item => item.code == "isOnlineType");
      return arr[0].list;
    },
    couponStatus(state) {
      if (state.dict.length == 0) return;
      let arr = state.dict.filter(item => item.code == "coupon_type");
      return arr[0].list;
    },
    gradeType(state) {
      if (state.dict.length == 0) return;
      let arr = state.dict.filter(item => item.code == "grade_type");
      return arr[0].list;
    },
    marketing_type(state) {
      // 活动状态
      if (state.dict.length == 0) return;
      let arr = state.dict.filter(item => item.code == "marketing_type");
      return arr[0].list;
    },
    equ_status(state) {
      // 激活状态
      if (state.dict.length == 0) return;
      let arr = state.dict.filter(item => item.code == "equ_status");
      return arr[0].list;
    },
    eq_result_status(state) {
      // 激活状态
      if (state.dict.length == 0) return;
      let arr = state.dict.filter(item => item.code == "eq_result_status");
      return arr[0].list;
    },
    comment_type(state) {
      //  评价状态
      if (state.dict.length == 0) return;
      let arr = state.dict.filter(item => item.code == "commentType");
      return arr[0].list;
    },
    comment_star(state) {
      //  评价类型
      if (state.dict.length == 0) return;
      let arr = state.dict.filter(item => item.code == "commentStar");
      return arr[0].list;
    },
    order_state(state) {
      //  订单类型
      if (state.dict.length == 0) return;
      let arr = state.dict.filter(item => item.code == "orderState");
      return arr[0].list;
    },
    net_order_state(state) {
      //  网店订单状态
      if (state.dict.length == 0) return;
      let arr = state.dict.filter(item => item.code == "netOrderState");
      return arr[0].list;
    },
    inventory_Adjust(state) {
      //  库存调整状态原因
      if (state.dict.length == 0) return;
      let arr = state.dict.filter(item => item.code == "adjustmentReason");
      // return arr[0].list;
      return arr[0].list;
    },
    inventory_AdjustType(state) {
      //  库存调整状态类型
      if (state.dict.length == 0) return;
      let arr = state.dict.filter(item => item.code == "inventoryAdjustmentType");
      // return arr[0].list;
      // console.log(arr[0].list );
      // if(arr[0].list> 0){
      return arr[0].list;
      // }else{
      //   return
      // }
    },
    net_order_type(state) {
      //   网店订单类型
      if (state.dict.length == 0) return;
      let arr = state.dict.filter(item => item.code == "netOrderType");
      return arr[0].list;
    },
    netOrderPayType(state) {
      //   网店订单类型
      if (state.dict.length == 0) return;
      let arr = state.dict.filter(item => item.code == "netOrderPayType");
      return arr[0].list;
    },
    shopStatusState(state) {
      //   门店状态
      if (state.dict.length == 0) return;
      let arr = state.dict.filter(item => item.code == "shop_apply_status");
      return arr[0].list;
    },

    weekType(state) {
      //   优店日期类型
      if (state.dict.length == 0) return;
      let arr = state.dict.filter(item => item.code == "shop_apply_status");
      return arr[0].list;
    },

    netDeliveryOrderType(state) {
      //   发货管理状态
      if (state.dict.length == 0) return;
      let arr = state.dict.filter(item => item.code == "netDeliveryOrderType");
      return arr[0].list;
    },
    yoDeliveryOrderType(state) {
      //   发货管理状态(优店)
      if (state.dict.length == 0) return;
      let arr = state.dict.filter(item => item.code == "yoDeliveryOrderType");
      return arr[0].list;
    },

    voicePromptType(state) {
      //   语音提示类型
      if (state.dict.length == 0) return;
      let arr = state.dict.filter(item => item.code == "voice_prompt_type");
      return arr[0].list;
    },


    deliveryMethodType(state) {
      //   配送方式
      if (state.dict.length == 0) return;
      let arr = state.dict.filter(item => item.code == "delivery_method_type");
      return arr[0].list;
    },

    yoPayType(state) {
      //   优店支付方式
      if (state.dict.length == 0) return;
      let arr = state.dict.filter(item => item.code == "yo_pay_type");
      return arr[0].list;
    },
    orderPayType(state) {
      //   支付方式
      if (state.dict.length == 0) return;
      let arr = state.dict.filter(item => item.code == "orderPayType");
      return arr[0].list;
    },
    yoOrderState(state) {
      //   优店订单状态
      if (state.dict.length == 0) return;
      let arr = state.dict.filter(item => item.code == "yoOrderState");
      return arr[0].list;
    },
    yoDistributionType(state) {
      //   优店配送类型
      if (state.dict.length == 0) return;
      let arr = state.dict.filter(item => item.code == "yoDistributionType");
      return arr[0].list;
    },
    orderRefundState(state) {
      //   订单退款状态
      if (state.dict.length == 0) return;
      let arr = state.dict.filter(item => item.code == "orderRefundState");
      return arr[0].list;
    },
    orderRefundGoodsState(state) {
      //   订单退货状态
      if (state.dict.length == 0) return;
      let arr = state.dict.filter(item => item.code == "orderRefundGoodsState");
      return arr[0].list;
    },
    //   优店网店订单状态
    sellerOrderStatus(state) {
      if (state.dict.length == 0) return;
      let arr = state.dict.filter(item => item.code == "seller_order_status");
      return arr[0].list;
    },

    isCashOnDelivery(state) {
      if (state.dict.length == 0) return;
      let arr = state.dict.filter(item => item.code == "is_cash_on_delivery");
      return arr[0].list;
    },

    isToPrint(state) {
      if (state.dict.length == 0) return;
      let arr = state.dict.filter(item => item.code == "is_to_print");
      return arr[0].list;
    },

    isCashOnDelivery(state) {

      if (state.dict.length == 0) return;
      let arr = state.dict.filter(item => item.code == "is_cash_on_delivery");
      return arr[0].list;
    },

    templateType(state) {

      if (state.dict.length == 0) return;
      let arr = state.dict.filter(item => item.code == "template_type");
      return arr[0].list;
    },
    //商品单位
    goodsUnit(state) {
      if (state.dict.length == 0) return;
      let arr = state.dict.filter(item => item.code == "goodsUnit");
      return arr[0].list;
    },
    //商品单位
    calculationType(state) {
      if (state.dict.length == 0) return;
      let arr = state.dict.filter(item => item.code == "calculation_type");
      return arr[0].list;
    },
    areaCalculationType(state) {
      if (state.dict.length == 0) return;
      let arr = state.dict.filter(item => item.code == "area_calculation_type");
      return arr[0].list;
    },
    sex(state) {
      if (state.dict.length == 0) return;
      let arr = state.dict.filter(item => item.code == "sex");
      return arr[0].list;
    },
    //财务 入账状态
    financeAccountingStatus(state) {
      if (state.dict.length == 0) return;
      let arr = state.dict.filter(item => item.code == "financeAccountingStatus");
      return arr[0].list;
    },
    //财务 充值类型
    financeRechargeType(state) {
      if (state.dict.length == 0) return;
      let arr = state.dict.filter(item => item.code == "financeRechargeType");
      return arr[0].list;
    },
    // 财务-收款账户类型
    financeWithdrawalAccountType(state) {
      if (state.dict.length == 0) return;
      let arr = state.dict.filter(item => item.code == "financeWithdrawalAccountType");
      return arr[0].list;
    },
    // 对账-入账类型
    reconciliationAccountingStatus(state) {
      if (state.dict.length == 0) return;
      let arr = state.dict.filter(item => item.code == "reconciliationAccountingStatus");
      return arr[0].list;
    },
    // 财务-提现审核状态
    financeWithdrawalAuditStatus(state) {
      if (state.dict.length == 0) return;
      let arr = state.dict.filter(item => item.code == "financeWithdrawalAuditStatus");
      return arr[0].list;
    },
    // 财务-订单类型
    financeOrderType(state) {
      if (state.dict.length == 0) return;
      let arr = state.dict.filter(item => item.code == "financeOrderType");
      return arr[0].list;
    },

    conversionTypes(state) {
      if (state.dict.length == 0) return;
      let arr = state.dict.filter(item => item.code == "conversion_type");
      return arr[0].list;
    },
    deliveryType(state) {
      if (state.dict.length == 0) return;
      let arr = state.dict.filter(item => item.code == "deliveryType");
      return arr[0].list;
    },
    week_type(state) {
      if (state.dict.length == 0) return;
      let arr = state.dict.filter(item => item.code == "week_type");
      return arr[0].list;
    },
    // 电子秤类型
    electronic_weight_type(state) {
      if (state.dict.length == 0) return;
      let arr = state.dict.filter(item => item.code == "electronic_weight_type");
      return arr[0].list;
    },
    // 秤类型
    electronic_type(state) {
      if (state.dict.length == 0) return;
      let arr = state.dict.filter(item => item.code == "electronic_type");
      return arr[0].list;
    },
    // 端口类型
    port_type(state) {
      if (state.dict.length == 0) return;
      let arr = state.dict.filter(item => item.code == "port_type");
      return arr[0].list;
    },
    // 波特率类型
    baud_rate_type(state) {
      if (state.dict.length == 0) return;
      let arr = state.dict.filter(item => item.code == "baud_rate_type");
      return arr[0].list;
    },
    // 数据位
    data_bit_type(state) {
      if (state.dict.length == 0) return;
      let arr = state.dict.filter(item => item.code == "data_bit_type");
      return arr[0].list;
    },
    // 停止位
    stop_bit_type(state) {
      if (state.dict.length == 0) return;
      let arr = state.dict.filter(item => item.code == "stop_bit_type");
      return arr[0].list;
    },
    // 奇偶校验
    is_parity_check_type(state) {
      if (state.dict.length == 0) return;
      let arr = state.dict.filter(item => item.code == "is_parity_check_type");
      return arr[0].list;
    },
    //秤
    electronic_scale_type(state) {
      if (state.dict.length == 0) return;
      let arr = state.dict.filter(item => item.code == "electronic_scale_type");
      return arr[0].list;
    },
    //单位类型
    unit_type(state) {
      if (state.dict.length == 0) return;
      let arr = state.dict.filter(item => item.code == "unit_type");
      return arr[0].list;
    },
    // 订单来源
    order_source(state) {
      if (state.dict.length == 0) return;
      let arr = state.dict.filter(item => item.code == "order_source");
      return arr[0].list;
    }
  },
  mutations: {

    setPublicCode: (state, data) => {
      state.srcCode = data;
    },
    INITDICT: (state, data) => {
      state.type = data.dict;
      state.sysData = data.sysData;
    },
    SET_ROLE_TYPE: (state, data) => {
      state.roleType = data;
      sessionStorage.setItem("roleType", data);
    },
    SET_USERNAME: (state, data) => {
      state.userName = data;
    },
    pageUpdate: state => {
      state.ifUpdate = false;
      setTimeout(() => {
        state.ifUpdate = true;
      }, 200);
    },
    SET_FILE_SERVER: (state, fileServer) => {
      state.fileServer = fileServer;
    },
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_WYUser: (state, wyUser) => {//网易云信账号
      state.wyUser = wyUser;
      sessionStorage.setItem("wyUser", JSON.stringify(wyUser));
    },
    SET_LoginInfo: (state, LoginInfo) => {//网易云信账号
      state.LoginInfo = LoginInfo;
      sessionStorage.setItem("LoginInfo", JSON.stringify(LoginInfo));
    },
    SET_COUPON_TYPE: (state, list) => {
      state.coupon_status = list;
    },
    SET_AUDIT_TYPE: (state, list) => {
      state.audit_status = list;
    },
    SET_FILE_REQUEST: (state, fileRequest) => {
      state.fileRequest = fileRequest;
    },
    //退出登录
    LOGINOUT: state => {
      window.sessionStorage.clear();
      router.push("/login");
    },
    //切换店铺
    SET_CURR_SHOP: (state, data) => {
      state.userInfo.avatar = data.headUrl;
      state.userInfo.shopNo = data.shopNo;
      state.userInfo.shopName = data.shopName;
      state.userInfo.shopType = data.shopType;
      state.shopNo = data.shopNo;
      state.shopType = data.shopType;
      sessionStorage.setItem("userInfo", JSON.stringify(state.userInfo));
      router.push("/smart");
      router.go(0);
    },
    // 账号信息初始化
    SET_SHOP: (state, data) => {
      //
      state.userInfo.name = data.userName;
      state.userInfo.nickName = data.nickName;
      state.userInfo.merchantNo = data.merchantNo;
      state.userInfo.merchantName = data.merchantName;
      state.userInfo.avatar = data.headImg;
      state.userInfo.shopNo = data.shopNo;
      state.userInfo.shopType = data.shopType;
      // state.userInfo.shopNoList = data.shopList;
      // state.userInfo.shopName = data.shopList.find(
      //   item => item.shopNo == data.shopNo
      // ).shopName;
      state.shopNo = data.shopNo;
      state.shopType = data.shopType;
      sessionStorage.setItem("userInfo", JSON.stringify(state.userInfo));
    },
    // 保存省市区信息
    SET_ADDRESS: (state, data) => {
      state.addressList = data;
    }

  },
  actions: {
    // get user info
    getInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        getCodeData("file_server2")
          .then(data => {
            const fileServer = data.data.vale;
            commit("SET_FILE_SERVER", fileServer);
            commit("SET_FILE_REQUEST", fileServer);
            sessionStorage.setItem("fileServer", fileServer);
            sessionStorage.setItem("fileRequest", fileServer);
            resolve(data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    address({ commit }) {
      return new Promise((resolve, reject) => {
        address().then(res => {
          const { data } = res;
          if (!data) {
            reject("Verification failed, please Login again.");
          }
          data.data.forEach(item => {
            item.listChildren.forEach(ele => {
              ele.listChildren.forEach(m => {
                delete m.listChildren;
              });
            });
          });
          commit("SET_ADDRESS", data.data);
          sessionStorage.setItem("addressList", JSON.stringify(data.data));
          resolve(data);
        }).catch(err => {
          reject(err);
        });
      });
    }
  }
};

export default user;
