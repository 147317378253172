<template>
  <div :class="getWD == '/yiSan' ? 'footer-copy' : 'footer-copys'" >
  <!-- <div class="footer-copy" > -->
    <div class="footer-wrap">
      <div class="footer-box">
        <div class="footer-link cflex">
          <a href="https://www.yisandata.com/h-col-130.html" target="_blank">关于我们</a>
          <a href="">招贤纳士</a>
          <a href="">广告服务</a>
          <a href="">开发助手</a>
        </div>
        <div class="footer-code">
          <div class="footer-code-li">
            <span>APP</span>
            <img src="~@/assets/icon/code1.png" alt="">
            <div class="footer-code-li-dialog">
              <img src="~@/assets/icon/code1.png" alt="">
            </div>
          </div>
          <div class="footer-code-li">
            <span>公众号</span>
            <img src="~@/assets/icon/code2.png" alt="">
            <div class="footer-code-li-dialog">
              <img src="~@/assets/icon/code2.png" alt="">
            </div>
            <img :src="srcCode" alt="">
            <div class="footer-code-li-dialog">
              <img :src="srcCode" alt="">
            </div>
          </div>
          <div class="footer-code-li">
            <span>小程序</span>
            <img src="~@/assets/icon/code3.png" alt="">
            <div class="footer-code-li-dialog">
              <img src="~@/assets/icon/code3.png" alt="">
            </div>
          </div>
        </div>
      </div>
      <div class="footer-mess">
        <div class="footer-contact cflex">
          <img src="@/assets/icon/f1.png" alt="" />
          <span>13657351193</span>
          <img src="@/assets/icon/f2.png" alt="" />
          <span>yisandata@163.com</span>
          <img src="@/assets/icon/f3.png" alt="" />
          <span>在线客服</span>
          <p>工作时间</p>
          <p>24小时在线</p>
        </div>
        <div class="footer-copyright cflex">
          <span>©2020 - 壹叁数据技术有限公司 </span>
          <span>湘ICP备19005879号</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
      return{
        srcCode:null,
        width:'1298px',
      }
    },
    created(){  
      this.srcCode = this.$store.getters.srcCode
    },
    computed:{
     getWD(){
        let obj = this.$route.path
        return obj
     }

    },

};
</script>

<style lang="less" scoped>
.footer-copy {
  width: 100%;
  height: 14rem;
  background: #fff;
  // margin-top: 8rem;
  margin-top: 2rem;
  padding-bottom: 2rem;
}
.footer-copys {
  width: 100% ;
  height: 16rem;
  background: #fff;
  margin-top: 2rem;
  min-width: 1072px;
}
.footer-wrap {
  width: 73.23%;
  margin: 0 auto;
}
.footer-box{
padding-top: 2.1rem;
  // padding-bottom: 2rem;
  .flexSt();
}
.footer-code{
  width: 15.8rem;
  .flexSt();
  .footer-code-li{
    width: 4.8rem;
    text-align: center;
    position: relative;
    cursor: pointer;
    &:not(:last-child){
      margin-right: 0.7rem;
    }
    img{
      display: block;
      width: 100%;
      height: 100%;
    }
    &:hover .footer-code-li-dialog{
      display: block;
    }
    .footer-code-li-dialog{
      display: none;
      transition: all 0.5s;
      .box(20rem,20rem,transparent,0.3rem);
      padding: 1rem;
      box-shadow: 0 0 6px 3px #ddd;
      .poslt(50%,-21rem);
      margin-left:-10rem;
      z-index: 100;
    }
  }
}
.footer-link {
  flex:1;
  a {
    display: inline-block;
    height: 15px;
    line-height: 15px;
    padding: 0 1.9rem;
    font-size: 1.4rem;
    text-decoration: none;
    color: #666666;
    &:first-child {
      padding-left: 0;
    }
    &:not(:last-child) {
      border-right: 1px solid #707070;
    }
  }
}
.footer-mess {
  padding-top: 1.8rem;
  line-height: 38px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.footer-contact {
  font-size: 1.4rem;
  color: #666666;
  img {
    margin-right: 0.7rem;
  }
  span {
    margin-right: 3.7rem;
  }
}
.footer-copyright span {
  margin-left: 0.8rem;
}
@media screen  and (max-width: 1700px) {
  .footer-copy {
    // width: 68% !important;
    // width: 1340px!important;
  }
}
@media screen  and (max-width: 1700px) {
  .footer-copys {
    // width: 68% !important;
    // width: 1340px!important;
  }
}
</style>
