const printer = {
    namespaced: true,
    state:{
        width:70,               // 标签纸宽度
        height:38,              // 标签纸高度
        concentration:3,        // 打印浓度
        paperType:1,            // 1间隙纸 2黑标纸 3连续纸 4定孔纸 5透明纸 6标牌
        mode:1,                 // 1热敏 2热转印
        speed:2,                // 打印速度
    },
    mutations:{
        
    },
    getters: {},
    actions: {}
  };
  
  export default printer;
  