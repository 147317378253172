
const tab = {
    namespaced: true,
    state: {
        isLog:false,//监听是否连接成功
        tabsList: null,//左侧菜单
        sessionsList:[],//消息存储
        Unreadable:[],//未读 离线信息的存储
        sessions:null,//会话列表
        callType:null,//通话

        callPhone:false,//通话弹窗
        RecentlyList:[],//最近联系人

        users:[],//好友列表

        IntercomList:[],//对讲列表
        isAutorun:false,//自动播放
        beforeSessions:null,//最新会话



    },
    //调用方法
    mutations: {
        tabsList(state,val) {
            // console.log(state);
            // console.log(val);
            state.tabsList = val
        },
        sessionsList(state,val){
            let data =Object.assign({},state.sessionsList,val)
            state.sessionsList = data
            
        },
        Unreadable(state,val){
            state.Unreadable = val
        },
        isLog(state,val){
            state.isLog = val
        },
        sessions(state,val){
            state.sessions = val
        },
        callType(state,val){
            state.callType = val
        },
        callPhone(state,val){
            state.callPhone = val
        },
        RecentlyList(state,val){
            state.RecentlyList = val
        },
        users(state,val){
            state.users = val
        },
        IntercomList(state,val){
            state.IntercomList = val
        },
        isAutorun(state,val){
            state.isAutorun = val
        },
        beforeSessions(state,val){
            state.beforeSessions = val
        }
        
    },
    actions: {}
};

export default tab;
