const getters = {
  navigation: state => state.menu.navigation,//面包屑
  RuoterNavigation: state => state.menu.RuoterNavigation,//面包屑


  roleType: state => state.user.roleType,
  onsetList: state => state.onset.onsetList,//店铺信息
  ifUpdate: state => state.user.ifUpdate,
  targetPath: state => state.route.targetPath,
  userInfo: state => state.user.userInfo,
  LoginInfo: state => state.user.LoginInfo,
  realAvatarImage: state => state.user.realAvatarImage,
  realBrandImage: state => state.user.realBrandImage,
  realClassImage: state => state.user.realClassImage,
  realGoodsImage: state => state.user.currentMenu,

  BreadCrumbs: state => state.tab.currentMenu,

  menuList: state => {
    return state.menu.menuList;
    // let st = state.user.userInfo.shopType
    // let arr = state.menu.menuList
    // let marketMenu = arr.find(m => m.id == 170)
    // let marketMenuList = marketMenu != undefined ? marketMenu.childList : []
    // let boolen = !marketMenuList.some(n => n.menuType == 'yo') && !marketMenuList.some(n => n.menuType == 'ger')
    // if( boolen && st == 2){
    //   return  arr.filter(n => n.id != 170)
    // }else{
    //   return arr
    // }
  },
  menuBtnList: state => state.menu.menuBtnList,
  asideMenuList: (state, getters) => aid => { // 获取当前二级菜单
    if (state.menu.headIndexId == null) return;
    let shopType = state.user.userInfo.shopType;
    let arr = state.menu.menuList.find(item => item.id == aid);
    arr.childList.sort(sortByKey);   //  二级排序
    arr.childList.forEach(ele => { // 三级排序
      ele.childList.sort(sortByKey);
    });
    if (shopType == "1") {
      return arr.childList.filter(n => n.menuType != "yo");
    }
    if (shopType == "2") {
      return arr.childList.filter(n => n.menuType != "net");
    }
  },
  headIndexId: state => state.menu.headIndexId,   // 当前导航id
  asideIndex: state => state.menu.asideIndex,     // 二级菜单当前id
  asideChildIndex: state => state.menu.asideChildIndex, // 三级菜单当前id
  fileServer: state => state.user.fileServer + "/uploadFile",
  fileRequest: state => state.user.fileRequest,
  fileUrl: state => state.user.fileServer + "/",
  shopNo: state => state.user.userInfo.shopNo,


  shopType: state => state.user.userInfo.shopType,
  merchantNo: state => state.user.userInfo.merchantNo,

  shopName: state => state.user.userInfo.shopName,
  copyright: state => state.user.copyright,
  orderIcon: state => state.shop.icons,
  addressList: state => state.user.addressList,
  srcCode: state => state.user.srcCode,
  getDictList: (state) => code => {
    let arr = state.user.dict.filter(item => item.code == code);
    return arr[0].list;
  },


  // 网易云信
  tabsList: state => state.tab.tabsList,
  sessionsList: state => state.tab.sessionsList,
  Unreadable: state => state.tab.Unreadable,
  isLog: state => state.tab.isLog,
  sessions: state => state.tab.sessions,
  callType: state => state.tab.callType,
  callPhone: state => state.tab.callPhone,
  RecentlyList: state => state.tab.RecentlyList,
  users: state => state.tab.users,
  IntercomList: state => state.tab.IntercomList,
  isAutorun: state => state.tab.isAutorun,
  beforeSessions: state => state.tab.beforeSessions


};

function sortByKey(a, b) {
  //(数组、排序的列)
  return a.taxis - b.taxis;
}

export default getters;
