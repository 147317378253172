// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.

import Vue from "vue";
import App from "./App";
import router from "./router";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "./css/base.css";
import echarts from 'echarts';
import axios from "axios";
import { Message } from "element-ui";
import qs from "qs";
import * as filters from "./utils/filters";
import bus from "./utils/bus";
import locale from "element-ui/lib/locale/lang/zh-CN";
import JsonExcel from 'vue-json-excel';
import { preventReClick } from './utils/preventReClick' //防多次点击，重复提交
import hasPermission from './utils/permissions'  //全局权限
import { clickOutside } from './utils/clickOutside' //点击元素以外触发事件
import BaiduMap from "vue-baidu-map"

import VueClipboard from 'vue-clipboard2' // 剪切板
Vue.use(VueClipboard)

// import VueVideoPlayer from 'vue-video-player'
// import 'video.js/dist/video-js.css'
// import 'vue-video-player/src/custom-theme.css'
// Vue.use(VueVideoPlayer);

// import nim from "./utils/NIM_WEB_SDK";
// import NERTC from "./utils/NIM_NERTC_SDK";
// console.log(nim);
// console.log(NERTC);
// Vue.prototype.nim = nim
// Vue.prototype.NERTC = NERTC
import Print from 'vue-print-nb'
Vue.use(Print)


// import getInstance from '@/utils/jcPrinterSdk_api.js'
// import '@/utils/jcPrinterSdkDemo.js'

// //打印机功能初始化
// getInstance()

Vue.use(clickOutside)
Vue.use(preventReClick)
Vue.prototype.$hasPermission = hasPermission
Vue.component('downloadExcel', JsonExcel)
Vue.use(ElementUI, { locale });
Vue.prototype.$echarts = echarts;
Vue.prototype.$axios = axios;
Vue.prototype.$bus = bus;
Vue.prototype.$message = Message;
Vue.prototype.$qs = qs;

//引用富文本编辑器
import VueQuillEditor from "vue-quill-editor";
//引入富文本css
// require styles
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import store from "./store";

// 获得焦点事件
Vue.directive('fo', {
  inserted(el, binding, vnode) {
    // 聚焦元素
    el.querySelector('input').focus()
  }
})
// 限制input只输入百分比
Vue.directive('enterNumber', {
  inserted: function (el) {
    el.addEventListener("keypress", function (e) {
      e = e || window.event;
      let charcode = typeof e.charCode === 'number' ? e.charCode : e.keyCode;
      let re = /^(100|[1-9]\d|\d)(.\d{1,2})?%$/;
      if (!re.test(String.fromCharCode(charcode)) && charcode > 9 && !e.ctrlKey) {
        if (e.preventDefault) {
          e.preventDefault();
        } else {
          e.returnValue = false;
        }
      }
    });
  }
});
if (process.env.NODE_ENV == 'production') {
  console.log = function () { }   //全局注释console.log
}
//富文本编辑器添加实例
Vue.use(VueQuillEditor /* { default global options } */);

Vue.use(ElementUI);
Vue.config.productionTip = false;
(Vue.prototype.formatWord = function (val) {
  return val.replace(/\n/g, "<br>");
}),

//百度地图
// Vue.use(BaiduMap, {
//   /* Visit http://lbsyun.baidu.com/apiconsole/key for details about app key. */
//   ak: "sWvbbZsyd7tu4bdZLHIkdInGf1o5ZuBS"
// })

Vue.prototype.$time = time;
//全局时间函数
function time(val) {
  var date = new Date(val);
  var YY = date.getFullYear() + "-";
  var MM =
    (date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1) + "-";
  var DD = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
  var HH = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
  var mm = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
  var ss = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();

  // var dataList =
  // return YY + MM + DD;
  return YY + MM + DD + " " + HH + ":" + mm + ":" + ss || YY + MM + DD;
}


Vue.prototype.isNumber = val => {
  var regPos = /^\d+(\.\d+)?$/; //非负浮点数
  var regNeg = /^(-(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*)))$/; //负浮点数
  if (regPos.test(val) || regNeg.test(val)) {
    return true;
  } else {
    return false;
  }
};

// 注册全局过滤器
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key]);
});

// 动态设置meta,title
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面meta */
  // let xToken = sessionStorage.getItem('token')
  // let url = to.path
  let head = document.getElementsByTagName("head");
  let meta = document.createElement("meta");
  // if(!xToken && url != '/' && url !='/userRegister' && url != '/forgetPassword'){
  // }
  if (to.meta.content) {
    meta.content = to.meta.content;
    head[0].appendChild(meta);
  }
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");