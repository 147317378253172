
import NIM from "../static/NIM_Web_NIM_v8.9.0";
// import Netcall from "../static/NIM_Web_Netcall_v8.5.0";//插件版通话SDK
// import WebRTc from "../static/NIM_Web_WebRTC_v8.5.0";//实时音视频通话SDK
import store from "../store";
export var data = {}

// console.log(wyUser);

export var nim= {}

let wyUser;

export function init() {
   wyUser = JSON.parse(sessionStorage.getItem("wyUser") )
   nim = NIM.getInstance({
    // 初始化SDK
    debug: false,
    appKey: "68550e5a73e5b9d4e0da7cb5b4beda6c",
    // account: wyUser.accid || null,
    // token: wyUser.token ||null,
    // account: "132",
    // token:"02b29834cc880ba7efce54d8be34673a",
    account: wyUser.accid,
    token:wyUser.token,
    onconnect: onConnect, //登录连接成功
    onerror: onError,
    onwillreconnect: onWillReconnect,
    ondisconnect: onDisconnect,
    onsessions: onSessions, // 会话
    onupdatesession: onUpdateSession,
    // // 消息
    syncSessionUnread: true,//同步消息读写情况
    syncRoamingMsgs: true,//是否同步漫游信息
    onroamingmsgs: onRoamingMsgs,
    onofflinemsgs: onOfflineMsgs,
    onsyncfriendaction: onSyncFriendAction,
    onmsg: onMsg, //收到消息
    // 好友关系
    onfriends: onFriends,
    // // 同步完成
    onusers: onUsers,
    onsyncdone: onSyncDone,
    // 群处理
    onSuperTeams: onSuperTeams, //超大群列表
    onTeams: onTeams, //群列表
    onTeamMembers: onTeamMembers, //查到群列表成员信息
  
  });

  
  // 在线用户接收到的通知事件
  // 在线用户接收到的通知事件
  nim.on('signalingNotify', signalingNotifyHandler);
  
}

// ===============


function signalingNotifyHandler(event) {
  console.log("signalingOnlineNotify: ", event)
  store.commit("tab/callType", event)
  switch (event.eventType) {
    case 'ROOM_CLOSE':
      /* 该事件的通知内容
        event.eventType 事件类型
        event.channelName 频道名称
        event.channelId 频道ID
        event.channelCreateTime 频道创建时间
        event.channelExpireTime 频道过期时间
        event.creator 频道创建者
        event.from 操作者
        evnet.ext 操作者附加的自定义信息，透传给你
        event.time 操作的时间戳
      */
      console.log("独立呼叫信令：频道关闭事件");
      break;
    case 'ROOM_JOIN':
      /* 该事件的通知内容
        event.eventType 事件类型
        event.channelName 频道名称
        event.channelId 频道ID
        event.channelCreateTime 频道创建时间
        event.channelExpireTime 频道过期时间
        event.creator 频道创建者
        event.from 操作者
        evnet.ext 操作者附加的自定义信息，透传给你
        event.time 操作的时间戳
        event.attach 新加入的成员信息
        event.msgid 消息id
      */
      console.log("独立呼叫信令：加入频道事件");
      break;
    case 'INVITE':
      /* 该事件的通知内容
        event.eventType 事件类型
        event.channelName 频道名称
        event.channelId 频道ID
        event.channelCreateTime 频道创建时间
        event.channelExpireTime 频道过期时间
        event.creator 频道创建者
        event.from 操作者
        evnet.ext 操作者附加的自定义信息，透传给你
        event.time 操作的时间戳
        event.to 接收者的账号
        event.requestId 邀请者邀请的请求id，用于被邀请者回传request_id_作对应的回应操作
        event.pushInfo 推送信息

      */
      console.log("独立呼叫信令： 邀请事件");
      store.commit("tab/callPhone", 1)
      break;
    case 'CANCEL_INVITE':
      /* 该事件的通知内容
        event.eventType 事件类型
        event.channelName 频道名称
        event.channelId 频道ID
        event.channelCreateTime 频道创建时间
        event.channelExpireTime 频道过期时间
        event.creator 频道创建者
        event.from 操作者
        evnet.ext 操作者附加的自定义信息，透传给你
        event.time 操作的时间戳
        event.to 接收者的账号
        event.requestId 邀请者邀请的请求id，用于被邀请者回传request_id_作对应的回应操作
      */
      console.log("独立呼叫信令：取消邀请事件");
      store.commit("tab/callPhone", 2)
      break;
    case 'REJECT':
      /* 该事件的通知内容
        event.eventType 事件类型
        event.channelName 频道名称
        event.channelId 频道ID
        event.channelCreateTime 频道创建时间
        event.channelExpireTime 频道过期时间
        event.creator 频道创建者
        event.from 操作者
        evnet.ext 操作者附加的自定义信息，透传给你
        event.time 操作的时间戳
        event.to 接收者的账号
        event.requestId 邀请者邀请的请求id，用于被邀请者回传request_id_作对应的回应操作
      */
      console.log("独立呼叫信令：拒绝邀请事件");
      break;
    case 'ACCEPT':
      /* 该事件的通知内容
        event.eventType 事件类型
        event.channelName 频道名称
        event.channelId 频道ID
        event.channelCreateTime 频道创建时间
        event.channelExpireTime 频道过期时间
        event.creator 频道创建者
        event.from 操作者
        evnet.ext 操作者附加的自定义信息，透传给你
        event.time 操作的时间戳
        event.to 接收者的账号
        event.requestId 邀请者邀请的请求id，用于被邀请者回传request_id_作对应的回应操作
      */
      console.log("独立呼叫信令：接受邀请事件");
      break;
    case 'LEAVE':
      /* 该事件的通知内容
        event.eventType 事件类型
        event.channelName 频道名称
        event.channelId 频道ID
        event.channelCreateTime 频道创建时间
        event.channelExpireTime 频道过期时间
        event.creator 频道创建者
        event.from 操作者
        event.to 接收者
        evnet.ext 操作者附加的自定义信息，透传给你
        event.time 操作的时间戳
      */
      console.log("独立呼叫信令：离开频道事件");
      break;
    case 'CONTROL':
      /* 该事件的通知内容
        event.eventType 事件类型
        event.channelName 频道名称
        event.channelId 频道ID
        event.channelCreateTime 频道创建时间
        event.channelExpireTime 频道过期时间
        event.creator 频道创建者
        event.from 操作者
        event.to 接收者
        evnet.attachExt 操作者附加的自定义信息，透传给你
        event.time 操作的时间戳
      */
      console.log("独立呼叫信令：自定义控制事件");
      break;
  }
}

// =========
function onConnect() {
  console.log("连接成功");
  nim.getTeams({
    //获取群列表
    done: getTeamsDone,
  });
  let tath = this;
  function getTeamsDone(error, teams) {
    // console.log(error);
    console.log(teams); //群列表
    if (teams) {
      teams.forEach((item) => {
        nim.getTeamMembers({
          teamId: item.teamId, //群id
          done: getTeamMembersDone,
        });
      });
      function getTeamMembersDone(error, obj) {
        console.log(obj);
        let arr = [];
        teams.forEach((items) => {
          if (items.teamId == obj.teamId) {
            items.chri = obj.members;
            arr.push();
          }
        });
        console.log(arr);
      
        store.commit("tab/tabsList", teams);
        // console.log("获取群成员" + (!error ? "成功" : "失败"));
       let tabList =  store.getters.tabsList
        let add = []

      //  tabList.forEach(item => {
      //     item.chri.forEach(items => {
      //       add.push(items)
      //     })

      //   });
      //   console.log(add);
      //   store.commit("tab/IntercomList", add);
      }
      //   console.log(teams);
    }
    console.log("获取群列表" + (!error ? "成功" : "失败"));
  }
}
//合并好友列表
export function onAddFriend(friend) {
  data.friends = nim.mergeFriends(data.friends, friend);
  console.log(data.friends);
  // store.commit("tab/users", data.friends)
}

function onSyncFriendAction(obj) {
  console.log('收到好友操作', obj);
  switch (obj.type) {
    case 'addFriend':
      console.log('你在其它端直接加了一个好友' + obj);
      onAddFriend(obj.friend);
      break;
    case 'applyFriend':
      console.log('你在其它端申请加了一个好友' + obj);
      break;
    case 'passFriendApply':
      console.log('你在其它端通过了一个好友申请' + obj);
      onAddFriend(obj.friend);
      break;
    case 'rejectFriendApply':
      console.log('你在其它端拒绝了一个好友申请' + obj);
      break;
    case 'deleteFriend':
      console.log('你在其它端删了一个好友' + obj);
      onDeleteFriend(obj.account);
      break;
    case 'updateFriend':
      console.log('你在其它端更新了一个好友', obj);
      onUpdateFriend(obj.friend);
      break;
  }
}
function onUpdateFriend(friend) {
  data.friends = nim.mergeFriends(data.friends, friend);
}



export function onFriends(friends) {
  console.log('收到好友列表', friends);
  data.friends = nim.mergeFriends(data.friends, friends);
  data.friends = nim.cutFriends(data.friends, friends.invalid);
  console.log(data.friends);
  // store.commit("tab/users", data.friends)
}

export function onUsers(users) {
  console.log('收到用户名片列表', users);
  data.users = nim.mergeUsers(data.users, users);
  store.commit("tab/users", data.users)
}

// 已读未读
function isUnreadable(row) {
  // console.log(row);

}
function onError(error, obj) {
  console.log("发生错误", error, obj);
}
function onWillReconnect(error, teams) {//即将重连的回调
  console.log(error);
  // console.log(teams);

}
// 断开连接后的回调
function onDisconnect(error, teams) {
  // console.log(error);
  // console.log(teams);
}

function onSessions(sessions) {
  console.log('收到会话列表', sessions);
  data.sessions = nim.mergeSessions(data.sessions, sessions);

  console.log(data.sessions);
  data.sessions.forEach(item=>{
    item.isvioce = true
    item.isvPale = true
    item.isAutorun = true
  })
  console.log(data.sessions);

//  var add =  unique(data.sessions)
//  console.log(add);

  store.commit("tab/RecentlyList", data.sessions)

  updateSessionsUI();
}

// 去重
function unique(arr) {
  const res = new Map();
  return arr.filter((arr) => !res.has(arr.to) && res.set(arr.to, 1))
 }

function onUpdateSession(session) {
  console.log('会话更新了', session);

  data.sessions = nim.mergeSessions(data.sessions, session);
  // console.log(data.sessions);
  data.sessions = data.sessions.reverse()
  // console.log(data.sessions);
  // store.commit("tab/sessions", data.sessions)
  if(session.lastMsg.from == wyUser.accid){
   store.commit("tab/isAutorun", false)
  }else{
   store.commit("tab/isAutorun", true)
  }

  store.commit("tab/beforeSessions", session)
  
  updateSessionsUI();
}
function updateSessionsUI() {
  // 刷新界面
}

function onRoamingMsgs(obj) {
  console.warn("漫游消息============================>", obj);
  pushMsg(obj.msgs);
}
export function pushMsg(msgs) {//插入信息
  if (!Array.isArray(msgs)) { msgs = [msgs]; }
  // console.log(msgs);
  if (msgs.length < 1) return
  var sessionId = msgs[0].sessionId;

  data.msgs = data.msgs || {};
  data.msgs[sessionId] = nim.mergeMsgs(data.msgs[sessionId], msgs);
  console.log(data.msgs);
  data.msgs[sessionId].forEach(item=>{
    item.isbj= true
  })
  console.log(data.msgs);
  store.commit("tab/sessionsList", data.msgs)

}

function onOfflineMsgs(obj) {
  console.log('离线消息', obj);
  pushMsg(obj.msgs);
  store.commit("tab/Unreadable", obj.msgs)
  // console.log(store.getters.Unreadable);

}
function onMsg(msg) {
  console.log('收到消息', msg.scene, msg.type, msg);
  pushMsg(msg);
}
function onSyncDone() {
  // console.log("同步完成");
}
function onSuperTeams(superTeams) {
  // console.log('收到超大群列表', superTeams)
  data.superTeams = nim.mergeTeams(data.superTeams, superTeams)//返回合并的一个群列表
  onInvalidSuperTeams(superTeams.invalid)
}
function onInvalidSuperTeams(teams) {
  data.superTeams = nim.cutTeams(data.superTeams, teams)
  data.invalidSuperTeams = nim.mergeTeams(data.invalidSuperTeams, teams)
  refreshSuperTeamsUI()
}
function refreshSuperTeamsUI() {
}
function onTeams(teams) {
  // console.log('群列表', teams);
  data.teams = nim.mergeTeams(data.teams, teams);
  onInvalidTeams(teams.invalid);
}
function onInvalidTeams(teams) {
  data.teams = nim.cutTeams(data.teams, teams);
  data.invalidTeams = nim.mergeTeams(data.invalidTeams, teams);
  refreshTeamsUI();//刷新
}
function onTeamMembers(obj) {
  // console.log('收到群成员', obj);
  var teamId = obj.teamId;
  var members = obj.members;
  data.teamMembers = data.teamMembers || {};
  data.teamMembers[teamId] = nim.mergeTeamMembers(data.teamMembers[teamId], members);
  data.teamMembers[teamId] = nim.cutTeamMembers(data.teamMembers[teamId], members.invalid);
  refreshTeamMembersUI();
}
function refreshTeamMembersUI() {
  // 刷新界面
}






//导出
export default nim;
