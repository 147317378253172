const route = {
    namespaced: true,
    state:{
        targetPath:'',
    },
    mutations:{
        setTarget:(state,data) =>{
            state.targetPath = data
        }
    },
    getters: {},
    actions: {}
  };
  
  export default route;
  