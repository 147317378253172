const onset = {
    namespaced: true,
    state:{
        onsetList:null,
    },

    mutations:{
        ONSET: (state, data) => {
            state.onsetList = data;
          },

    },

    getters: {},
    actions: {}

  };

  
  export default onset;
  