const shangdian = {
  namespaced: true,
  state: {
    asideType: 0,
    asideList: [
      {
        id: "store1",
        label: "店铺信息",
        path: "/store/information",
        ifShow: true
      },
      { id: "store2", label: "优店信息", path: "/store/yoShop", ifShow: true }
    ]
  },
  getters: {},
  mutations: {
    TYPE_CHANGE: (state, payload) => {
      state.asideType = payload;
    }
  },
  actions: {}
};

export default shangdian;
