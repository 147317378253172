import Vue from "vue";
import Router from "vue-router";
import login from "@/pages/login";
import index from "@/pages/index";
import yiSan from "@/pages/index/yiSan";
import store from "../store";
import route from "../store/modules/route";

Vue.use(Router);
const originalPush = Router.prototype.push;
// 重写了原型上的push方法，统一的处理了错误信息
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};
const router = new Router({
  //base:'/ysmall_seller/',
  //mode: "history",
  mode: "hash",
  routes: [
    {
      path: "/",
      name: "login",
      component: login
    },
    {
      path: "/smart",
      name: "index",
      component: index,
      redirect: "/smart",
      children: [
        {
          path: "/smart",
          name: "smart",
          component: yiSan,
          meta: {
            keepAlive: true,
            title: "首页"
            //  看板管理
          }
        },
        {
          path: "/KanbanManagement",
          name: "KanbanManagement",
          component: () => import("@/pages/KanbanManagement/KanbanManagement"),
          meta: {
            keepAlive: true,
            title: "看板管理"
            //  看板管理
          }
        },
        {
          path: "/online",
          name: "online",
          component: () => import("@/pages/online/manage"),
          meta: {
            keepAlive: true,
            title: "订单页面"
            //  订单页面
          }
        },
        {
          path: "/online/childCom/details",
          name: "details",
          component: () => import("@/pages/online/childCom/details"),
          meta: {
            keepAlive: true,
            title: "订单页面详情网店"
            //  订单页面详情网店
          }
        },
        {
          path: "/online/childCom/detailsYo",
          name: "detailsYo",
          component: () => import("@/pages/online/childCom/detailsYo"),
          meta: {
            keepAlive: true,
            title: "订单页面详情优店"
            //  订单页面详情优店
          }
        },
        {
          path: "/communication",
          name: "communication",
          component: () => import("@/pages/communication/communication"),
          meta: {
            keepAlive: true,
            title: "通讯"
            //  通讯
          }
        },
        {
          path: "/securityCamera",
          name: "securityCamera",
          component: () => import("@/pages/securityCamera/securityCamera"),
          meta: {
            keepAlive: true,
            title: "监控视频"
            //  监控视频
          }
        },
        {
          path: "/operationCenter",
          name: "operationCenter",
          component: () => import("@/pages/operationCenter/index"),
          redirect: "/shopManage",
          meta: {
            title: "运营中心"
            //  运营中心
          },
          children: [
            {
              path: "/shopManage",
              name: "shopManage",
              component: () => import("@/pages/shopManage/index"),
              meta: {
                title: "门店管理"
                //  门店管理
              }
            },
            {
              path: "/addshop",
              name: "addshop",
              component: () => import("@/pages/shopManage/addshop"),
              meta: {
                title: "新增店铺"
              }

            },
            {
              path: "/dataStatistics",
              name: "dataStatistics",
              component: () => import("@/pages/dataStatistics/index"),
              meta: {
                title: "数据统计"
              }
            },
            {
              path: "/setshop",
              name: "setshop",
              component: () => import("@/pages/shopManage/setshop"),
              meta: {
                title: "店铺设置"
              }
            }
          ]
        },
        {
          path: "/operationCenter/GoodsIssued",
          name: "GoodsIssued",
          component: () => import("@/pages/operationCenter/GoodsIssued/GoodsIssued"),
          meta: {
            keepAlive: true,
            title: "商品下发"
          }
        },
        {
          path: "/operationCenter/ShopIssued",
          name: "ShopIssued",
          component: () => import("@/pages/operationCenter/ShopIssued/ShopIssued"),
          meta: {
            keepAlive: true,
            title: "店铺下发"
          }
        },
        {
          path: "/broadcast",
          name: "broadcast",
          component: () => import("@/pages/broadcast/index"),
          meta: {
            keepAlive: true,
            title: "广播"
            //  广播
          }
        },
        {
          path: "/screenAdvertising",
          name: "screenAdvertising",
          component: () => import("@/pages/screenAdvertising/index"),
          meta: {
            keepAlive: true,
            title: "广告屏"
            //  广告屏
          }
        },
        {
          path: "/template",
          name: "template",
          component: () => import("@/pages/screenAdvertising/template"),
          meta: {
            keepAlive: true,
            title: "模板设计"
            //  模板设计
          }

        }


      ]
    }
  ]
});
//全局路由守卫
router.beforeEach((to, from, next) => {
  const arr = ["/", "/userRegister", "/forgetPassword", "/regBe", "/regSussec", "/personRegister", "/stateRegister", "/register"];
  const menuArrElse = ["/userRegister", "/forgetPassword", "/regBe", "/regSussec", "/personRegister", "/stateRegister", "/register"];
  let menu = store.getters.menuList;
  let menuBtnList = store.getters.menuBtnList;
  let tar = to.path;
  let isMatch = false;
  let shopType = store.getters.shopType;
  // 判断是否登录
  if (arr.some(n => n == to.path)) {

    next();
  } else {
    var token = sessionStorage.getItem("token");
    let targetPath = to.path;
    //如果没登录,都导向登录页
    if (!token) {
      if (!arr.some(n => n == targetPath)) {
        store.commit("route/setTarget", targetPath);
        next({ path: "/" });
      } else {
        next({ path: "/smart" });
      }
    } else {

      next();
    }
  }
  //导航跳转
  console.log(tar);
  if (tar == "/shop/yoList") {
    store.commit("menu/SET_HEAD_INDEX", 169);
    store.commit("menu/SET_ASIDE_INDEX", 190);
    store.commit("menu/SET_ASIDE_CHILD_INDEX", null);
  } else if (tar == "/inputting/informationsYo") {
    console.log(132);
    store.commit("menu/SET_HEAD_INDEX", 591);
    store.commit("menu/SET_ASIDE_INDEX", 222);
    store.commit("menu/SET_ASIDE_CHILD_INDEX", null);
    console.log(store.getters.headIndexId);
  } else if (tar == "/inputting/informations") {
    console.log(132);
    store.commit("menu/SET_HEAD_INDEX", 591);
    store.commit("menu/SET_ASIDE_INDEX", 222);
    store.commit("menu/SET_ASIDE_CHILD_INDEX", null);
    console.log(store.getters.headIndexId);
  } else if (tar == "/goods/yoList") {
    store.commit("menu/SET_HEAD_INDEX", 169);
    store.commit("menu/SET_ASIDE_INDEX", 190);
    store.commit("menu/SET_ASIDE_CHILD_INDEX", null);
  }
  menu.forEach(item => {
    if (item.childList.length != 0) {  // 有二级列表
      item.childList.forEach(m => {
        if (m.childList.length == 0) {  // 没有3级列表
          if (m.url == tar || tar.includes(m.url)) { // 匹配二级
            if (m.url == "/order/manage" && shopType == 2) {
              store.commit("menu/SET_HEAD_INDEX", 168);
              store.commit("menu/SET_ASIDE_INDEX", 181);
              store.commit("menu/SET_ASIDE_CHILD_INDEX", null);
            } else if (m.url == "/offline/manage" && shopType == 1) {
              store.commit("menu/SET_HEAD_INDEX", 168);
              store.commit("menu/SET_ASIDE_INDEX", 180);
              store.commit("menu/SET_ASIDE_CHILD_INDEX", null);
            } else {
              store.commit("menu/SET_HEAD_INDEX", m.parentId);
              store.commit("menu/SET_ASIDE_INDEX", m.id);
              store.commit("menu/SET_ASIDE_CHILD_INDEX", null);
            }
            isMatch = true;
          } else {
            // if(item.url == tar){
            //   let cur = item.childList[0]
            //   next({ path: cur.url });
            //   store.commit("menu/SET_HEAD_INDEX",cur.parentId)
            //   store.commit("menu/SET_ASIDE_INDEX",cur.id)
            //   store.commit("menu/SET_ASIDE_CHILD_INDEX",null)
            // }
          }
        } else {  // 匹配3级
          let currArr = m.childList.find(item => item.url == tar);
          if (currArr) {
            store.commit("menu/SET_HEAD_INDEX", currArr.grandpaId);
            store.commit("menu/SET_ASIDE_INDEX", currArr.parentId);
            store.commit("menu/SET_ASIDE_CHILD_INDEX", currArr.id);
            isMatch = true;
          }
        }
      });
    } else {
      if (item.url == tar) {
        store.commit("menu/SET_HEAD_INDEX", item.id);
        store.commit("menu/SET_ASIDE_INDEX", null);
        store.commit("menu/SET_ASIDE_CHILD_INDEX", null);
        isMatch = true;
      }
    }
  });
  // if (!isMatch) {
  //   menuBtnList.forEach(item => {
  //     if (tar.includes(item.url)) {
  //       store.commit("menu/SET_HEAD_INDEX", item.grandpaId)
  //       store.commit("menu/SET_ASIDE_INDEX", item.parentId)
  //       store.commit("menu/SET_ASIDE_CHILD_INDEX", item.id)
  //     }
  //   })
  // }
});

export default router;
