import Vue from "vue";
import Vuex from "vuex";
import getters from "./getters";

import marketing from "./modules/marketing";
import user from "./modules/user";
import shangdian from "./modules/shangdian";
import shop from "./modules/shop";
import menu from "./modules/menu";
import route from "./modules/route";
import printer from "./modules/printer";
import onset from "./modules/onset";
import tab from "./modules/tab";
import nim from "./modules/nim";


Vue.use(Vuex);

// 自动注入所有 ./modules 下的 vuex 子模块
// const files = require.context('./modules', false, /\.js$/)
// const modules = {}

// files.keys().forEach((key) => {
//   modules[key.replace(/(\.\/|\.js)/g, '')] = files(key).default
// })

export default new Vuex.Store({
  modules: {
    marketing,
    user,
    shangdian,
    shop,
    menu,
    route,
    onset,
    printer,
    tab,
    nim,
  },
  getters
});
