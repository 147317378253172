import request from "../utils/request";
import newRequest from "../utils/newRequest";

let baseUrl = process.env.VUE_APP_BASE_URL2;

export function getInfo() {
  return request({
    url: `${baseUrl}/sellercenter/getInfo/v247`,
    method: "post"
  });
}

export function getCodeData(code) {
  return newRequest({
    url: `/op/setting/queryByCode/${code}`,
    method: "get"
  });
}

//查询数据字典
export function dataDictionary() {
  return request({
    url: `${baseUrl}/sellercenter/init/data/query`,
    method: "GET"
  });
}

// 获取全国省市区
export function address() {
  return request({
    url: `${baseUrl}/sellercenter/area/getCityList/v247`,
    method: "GET"
  });
}

export function queryAreaByCode(pid) {
  return request({
    url: `${baseUrl}/sellercenter/area/queryAreaByCode/v250/${pid}`,
    method: "GET"
  });
}

export function getAudit(userId) {
  return request({
    url: `${baseUrl}/sellercenter/queryApplyMerchantByUserId/${userId}`,
    method: "GET"
  });
}

export function loginApi(data) {
  return newRequest({
    url: `/op/dcuser/login`,
    data,
    method: "post"
  });
}

export function queryMenu(data) {
  return newRequest({
    url: `/op/dcmenus/queryRoutes`,
    data,
    method: "post"
  });
}

