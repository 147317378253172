import store from "../store";

//全局时间过滤器
export function timeFormat(val, i) {
  let date = new Date(val);
  let YY = date.getFullYear();
  let MM =
    date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1;
  let DD = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
  let hh = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
  let mm = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
  let ss = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
  if (i == 0) {
    return YY + "-" + MM + "-" + DD + " " + hh + ":" + mm;
  } else {
    return YY + "-" + MM + "-" + DD + " " + hh + ":" + mm + ":" + ss;
  }
}
// export function auditFormat(val) {
//   let curr = store.state.user.audit_status.find(item => item.value == val);
//   return curr.text;
// }

// export function activeFormat(val) {
//   let curr = store.state.user.coupon_status.find(item => item.value == val);
//   return curr.text;
// }

export function shelvesFormat(val) {
  if (val == 1) {
    return "是";
  } else {
    return "否";
  }
}

export function resTime(s) {
  s = s / 1000;
  let Day = parseInt(s / 60 / 60 / 24, 10);
  let Hour = parseInt((s / 60 / 60) % 24, 10);
  let Minute = parseInt((s / 60) % 60, 10);
  let Second = parseInt(s % 60, 10);
  let res = {
    d: Day,
    h: (Hour + "").padStart(2, "0"),
    m: (Minute + "").padStart(2, "0"),
    s: (Second + "").padStart(2, "0")
  };
  return res.d + "天" + res.h + "时" + res.m + "分" + res.s + "秒";
}

export function restFormat(val) {
  let date = new Date(val).getTime();
  let now = new Date().getTime();
  let rest = date - now;
  //计算出相差天数
  let days = Math.floor(rest / (24 * 3600 * 1000));
  //计算出小时数
  let leave1 = rest % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
  let hours = days * 24 + Math.floor(leave1 / (3600 * 1000));
  hours = hours > 9 ? hours : "0" + hours;
  //计算相差分钟数
  let leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数
  let minutes = Math.floor(leave2 / (60 * 1000));
  minutes = minutes > 9 ? minutes : "0" + minutes;
  //计算相差秒数
  let leave3 = leave2 % (60 * 1000); //计算分钟数后剩余的毫秒数
  let seconds = Math.round(leave3 / 1000);
  seconds = seconds > 9 ? seconds : "0" + seconds;
  return hours + ":" + minutes + ":" + seconds;
}

export default function getTodayTime(n){
  let date 
  if(n == 0){
    date = new Date(new Date().setHours(0, 0, 0, 0))
  }else{
    date = new Date(new Date().setHours(0, 0, 0, 0) + 24 * 60 * 60 * 1000 - 1)
  }
  let YY = date.getFullYear();
  let MM =
    date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1;
  let DD = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
  let hh = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
  let mm = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
  let ss = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
  return YY + "-" + MM + "-" + DD + " " + hh + ":" + mm + ":" + ss;
}



