const shop = {
  namespaced: true,
  state: {
    icons: [
      {
        normal: require("@/assets/icon/or1.png"),
        active: require("@/assets/icon/or1_ac.png")
      },
      {
        normal: require("@/assets/icon/or2.png"),
        active: require("@/assets/icon/or2_ac.png")
      },
      {
        normal: require("@/assets/icon/or3.png"),
        active: require("@/assets/icon/or3_ac.png")
      },
      {
        normal: require("@/assets/icon/or4.png"),
        active: require("@/assets/icon/or4_ac.png")
      },
      {
        normal: require("@/assets/icon/or5.png"),
        active: require("@/assets/icon/or5_ac.png")
      },
      {
        normal: require("@/assets/icon/or6.png"),
        active: require("@/assets/icon/or6_ac.png")
      },
      {
        normal: require("@/assets/icon/or7.png"),
        active: require("@/assets/icon/or7_ac.png")
      },
      {
        normal: require("@/assets/icon/or8.png"),
        active: require("@/assets/icon/or8_ac.png")
      },
      {
        normal: require("@/assets/icon/or9.png"),
        active: require("@/assets/icon/or9_ac.png")
      }
    ]
  },
  getters: {},
  mutations: {},
  actions: {}
};

export default shop;
