import service from "../../utils/request";
let baseUrl = process.env.VUE_APP_BASE_URL2;

// Echarts
// export function realTimeData(shopNo) {
//     return service({
//       url: `${baseUrl}/statistics/index/net/eChartData/${shopNo}/v250`,
//       method: "GET",
//     });
// }

// // Echarts 优店
// export function yoRealTimeData(shopNo) {
//   return service({
//     url: `${baseUrl}/statistics/index/yo/eChartData/${shopNo}/v250`,
//     method: "GET",
//   });
// }


//资金中心统计
export function fundCenter(merchantNo) {
  return service({
    url: `${baseUrl}/statistics/index/smart/fundCenter/${merchantNo}/v260`,
    method: "GET",
  });
}

// ===== new
// 智慧屏优网店订单状态统计
export function orderStatusStatistics(merchantNo) {
  return service({
    url: `${baseUrl}/statistics/index/smart/orderStatusStatistics/${merchantNo}/v260`,
    method: "GET",
  });
}

// Echarts 数智营 实时数据统计
export function yoRealTimeData(merchantNo) {
  return service({
    url: `${baseUrl}/statistics/index/smart/eChartData/${merchantNo}/v261`,
    method: "GET",
  });
}