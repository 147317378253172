<template>
  <div id="app">
    <router-view  v-if="isRouterAlive" />
  </div>
</template>

<script>
export default {
  name: "App",
  provide(){
    return {
      reload: this.reload
    }
  },
  data(){
    return {
       isRouterAlive:true  // 缓存
    }
  },
  methods:{
    reload() { 
      this.isRouterAlive = false; 
      this.$nextTick(() => (this.isRouterAlive = true)); 
    },
  }
};
</script>

<style lang="less">
@import "css/base.less";
@import "css/system.less";
@import "css/report.less";
@import "css/pageCss.less";
* {
  margin: 0;
  padding: 0;
}
</style>
