const nim = {
  state: {
    leftMenu: null,
    srcCode:"",
  },
  mutations: {
    wen(state, val){
      console.log(state);
      console.log(val);
      state.leftMenu = val
    },
    setPublicCode:(state,data) =>{
      state.srcCode = data
    },
  },
  actions: {

  }
};
export default nim;
 