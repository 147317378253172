import { menuList} from "@/api/common/common";
import store from "../../store";

const menu = {
  namespaced: true,
  state: {
    menuBtnList: JSON.parse(sessionStorage.getItem("menuBtnList")) || [],
    menuList: JSON.parse(sessionStorage.getItem("menuList")) || [], //  头部导航栏
    asideMenu: [], //  侧边栏导航
    headIndexId: sessionStorage.getItem("headIndexId") || null, //  头部导航焦点索引
    asideIndex: sessionStorage.getItem("asideIndex") || null ,//  侧边栏焦点索引
    asideChildIndex: sessionStorage.getItem("asideChildIndex") || null ,//  侧边栏焦点索引

    navigation:"首页",// 面包屑
    RuoterNavigation:null,//跳转地址
  },
  getters: {},
  mutations: {
    SET_MENU_LIST: (state, list) => {
      console.log("SET_MENU_LIST",list);
      state.menuList = list;
      // state.headIndexId = list[0].id;
      // state.asideMenu = list[0].childList;
      // state.asideIndex = list[0].childList.id;
      sessionStorage.setItem(
        "menuList",
        JSON.stringify(list)
      );
    },
    SET_MENU_BTN_LIST: (state, list) => {
      state.menuBtnList = list;
      sessionStorage.setItem(
        "menuBtnList",
        JSON.stringify(list)
      );
    },
    SET_HEAD_INDEX: (state, i ) => {
      if(i == null || i == 167){  // 首页
        state.headIndexId = 167
        sessionStorage.setItem("headIndexId", 167);
        state.asideIndex = null
        sessionStorage.setItem("asideIndex", state.asideIndex);
      }else {
        state.headIndexId = i;
        sessionStorage.setItem("headIndexId", i);
      }
    },
    SET_ASIDE_INDEX: (state, i) => {
      state.asideIndex = i;
      sessionStorage.setItem("asideIndex", i);
    },
    SET_ASIDE_CHILD_INDEX: (state, i) => {
      state.asideChildIndex = i;
      sessionStorage.setItem("asideChildIndex", i);
    },
    navigation(state, val) {
      state.navigation = val
    },
    RuoterNavigation(state, val){
      state.RuoterNavigation = val
    }


  },
  actions: {
    // get user info

    // getMenu({ commit, state, rootState }) {
    //   let version = rootState.user.copyright;
    //   let obj = {
    //     params: {
    //       shopNo: rootState.user.userInfo.shopNo,
    //       shopType: rootState.user.userInfo.shopType,
    //       userName:rootState.user.userInfo.name
    //     },
    //     v: version
    //   };
    //   return new Promise((resolve, reject) => {
    //     menuList(obj).then(response => {
    //         const { data } = response;
    //         console.log(data)
    //         if (!data) {
    //           reject("获取导航列表失败");
    //         }
    //         commit("SET_MENU_LIST", data.data.menuList);
    //         commit("SET_MENU_BTN_LIST", data.data.menuBtnList);
    //
    //         resolve(data);
    //       })
    //       .catch(error => {
    //         reject(error);
    //       });
    //   });
    // },

  }
};

export default menu;
