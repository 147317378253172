<template>
  <div id="app" class="login">
    <div class="login-wrap" id="warp">
      <div class="login-img">
        <span>数智营管理中心</span>
      </div>
      <div class="login-text">
        <span class="login-tit">后台管理系统</span>
      </div>
      <!--      <div style="margin-top:30px">-->
      <!--        <el-radio v-model="type" label="1">普通商户</el-radio>-->
      <!--        <el-radio v-model="type" label="2">渠道商</el-radio>-->
      <!--      </div>-->
      <div class="login-item">
        <span class="login-label"></span>
        <el-input v-model="userName" placeholder="登录帐号"></el-input>
      </div>
      <div class="login-item2">
        <span class="login-label"></span>
        <el-input
          v-model.trim="pwd"
          placeholder="请输入您的密码"
          show-password
          @keyup.enter.native="keydown()"
        ></el-input>
      </div>
      <div class="login-item1">
        <div class="login-itemBox">
          <span class="login-label"></span>
          <el-input v-model="smsCode" placeholder=""></el-input>
        </div>
        <el-button
          type="info"
          class="yanzhengma"
          @click.prevent="getCode()"
          :disabled="!show"
        >{{ content }}
        </el-button
        >
      </div>
      <!-- <div class="login-link">
        <router-link to="/forgetPassword">忘记密码</router-link>
        <router-link to="/userRegister">注册</router-link>
      </div> -->
      <div class="login-btn">
        <el-button @click="login()" class="" @keyup.enter.native="keydown()"
        >立即登录
        </el-button>
      </div>
    </div>
    <div class="login-Rights">
      <span>Copyright © 2021 Yi San Technology All Rights Reserved </span>
    </div>
  </div>
</template>

<script>
import { login, getMerchant } from "@/api/api";
import { dataDictionary, loginApi, queryMenu } from "../api/user";
import md5 from "js-md5";
// import { nim,init } from "@/utils/NIM_WEB_SDK";

export default {
  name: "login",
  data() {
    return {
      loginImg: require("../assets/image/login.png"),
      clock: null,
      content: "获取验证码", // 按钮里显示的内容
      totalTime: 60,
      show: true,
      userName: "",
      pwd: "",
      smsCode: "",
      screeHeight: `height:${document.documentElement.clientHeight}px`, // 屏幕高
      sh: document.documentElement.clientHeight,
      screeWidth: window.innerWidth, //屏幕宽度
      type: "1"
    };
  },
  created() {
  },
  mounted() {
    const that = this;
    // let arr = window.innerWidth
    let warp = document.getElementById("warp");
    let obj = window.innerWidth;
    let j = obj - warp.offsetLeft;
    window.onresize = function temp() {
      that.screeHeight = `height:${document.documentElement.clientHeight}px`;
      that.sh = document.documentElement.clientHeight;
    };
    //获取登录信息
    // that.$store.dispatch("user/getInfo");
    // var t = this;
    // document.onkeydown = function (e) {
    //   if (window.event == 'undefined') {
    //     var key = e.keyCode;
    //   } else {
    //     var key = window.event.keyCode;
    //   }
    //   //enter的ASCII码是13
    //   // if (key == 13) {
    //   //     t.login();
    //   // }
    // };
  },
  destroyed() {
    window.removeEventListener("login", this.login, false);
  },
  computed: {
    targetPath() {
      return this.$store.getters.targetPath;
    }
  },
  methods: {
    checkEmpty(val) {
    },
    async getPublicCode(userId) {
      const res = await this.$axios({
        url:
          process.env.VUE_APP_BASE_URL2 + "/sellercenter/wx/handle/queryTicket",
        // headers:{
        //     xToken:sessionStorage.getItem('token'),
        // },
        method: "POST",
        data: {
          userId,
          codeType: "1"
        }
      });
      const { flag, data, msg } = res.data;
      if (flag) {
        this.$store.commit("user/setPublicCode", data);
        sessionStorage.setItem("srcCode", data);
      } else {
        this.$message.error(msg);
      }
    },
    keydown() {
      if (window.event.keyCode == 13) {
        this.login();
      }
    },
    async getMenu(userUid) {
      const res = await queryMenu({ params: { userUid } });
      const data = res.data;
      console.log("menuData", data);

      const transitionMenu = (menuList) => {
        let childMenu = [];
        return menuList.map((value) => {
          if (value.children.length) {
            childMenu = transitionMenu(value.children);
          }
          return {
            "menuName": value.meta.title,
            "menuCode": value.menuName,
            "url": value.redirect,
            "icon": value.meta.icon,
            "menuType": value.menuName,
            "childList": childMenu,
            "listBtn": []
          };
        });
      };

      const menuList = transitionMenu(data);

      // {
      //   "pid": 0,
      //   "id": 1,
      //   "path": "/smart",
      //   "component": "",
      //   "name": "smart",
      //   "redirect": "/smart",
      //   "hidden": false,
      //   "alwaysShow": false,
      //   "showClose": false,
      //   "meta": {
      //     "title": "首页",
      //     "icon": "smart",
      //     "roles": [
      //       "admin"
      //     ],
      //     "noCache": true
      //   },
      //   "children": []
      // }
      this.$store.commit("menu/SET_MENU_LIST", menuList);
      this.$store.commit("menu/SET_MENU_BTN_LIST", []);
    },
    async login() {
      let that = this;
      if (this.userName === "") {
        this.$message.warning("请输入手机号码");
        return;
      }
      if (this.pwd === "") {
        this.$message.warning("请输入密码");
        return;
      }

      const res = await loginApi({ params: { loginCode: that.userName, password: md5(that.pwd) } });
      const loginData = res.data;
      console.log("loginData", res);
      let info = {
        type: this.type,
        channelMerNo: loginData.merCode
      };
      sessionStorage.setItem("info", JSON.stringify(info));
      sessionStorage.setItem("token", loginData.token);
      sessionStorage.setItem("userId", loginData.userId);

      await this.getMenu(loginData.uid);

      this.$store.commit("user/SET_TOKEN", loginData.token);
      this.$store.commit("user/SET_WYUser", loginData.wyUser);
      this.$store.commit("user/SET_LoginInfo", loginData);
      this.$store.commit("user/SET_SHOP", {
        shopList: [],
        merchantNo: loginData.merCode
      });

      // 获取登录角色的信息
      await this.$store.dispatch("user/getInfo");
      const { type, userName } = loginData;
      //设置账户角色
      this.$store.commit("user/SET_ROLE_TYPE", type);
      sessionStorage.setItem("roleType", type);
      this.$store.commit("user/SET_USERNAME", userName);
      sessionStorage.setItem("userName", userName);
      // //获取导航信息
      // await this.$store.dispatch("menu/getMenu");
      //获取省市区
      await this.$store.dispatch("user/address");

      // let obj = loginData.shopInfo.shopList;
      // if (obj.length < 1) {
      //   await this.$router.push("/store/storeType");
      // } else {
      //   this.$store.commit("user/SET_SHOP", loginData.shopInfo);
      // }

      //数据字典
      this.getDictionary().then(() => {
        setTimeout(() => {
          if (this.targetPath && this.targetPath !== "/login") {
            this.$router.push(this.targetPath);
          } else {
            this.$router.push("/KanbanManagement");
            // this.$router.push("/offline/manage");
          }
        }, 300);
      });

      // let param = {
      //   userName: that.userName,
      //   pwd: md5(that.pwd),
      //   smsCode: that.smsCode,
      //   merType: this.type,
      // };
      // const res = await login(param);
      // if (!res.data.flag) {
      //   this.$message.error(res.data.msg != null ? res.data.msg : "");
      //   if (res.data.data.auditStatus == 0) {
      //     let id = res.data.data.id;
      //     let userName = res.data.data.userName;
      //     this.$router.push({
      //       path: "/stateRegister",
      //       query: {
      //         userId: id,
      //         userName: userName,
      //       },
      //     });
      //     return that.$message.warning(
      //       "此账号尚未注册或未入住,请点击注册或入住"
      //     );
      //   }
      // }
      // if (res.data.flag) {
      //   let info = {
      //     type: this.type,
      //     channelMerNo: res.data.data.channelMerNo,
      //   };
      //   sessionStorage.setItem("info", JSON.stringify(info));
      //
      //   sessionStorage.setItem("token", res.data.data.token);
      //   let wyUser = JSON.stringify(res.data.data.wyUser);
      //   sessionStorage.setItem("wyUser", wyUser);
      //   sessionStorage.setItem("userId", res.data.data.userId);
      //   // this.getPublicCode(res.data.data.userId);
      //   if (res.data.data.auditStatus == 0) {
      //     let id = res.data.data.id;
      //     let userName = res.data.data.userName;
      //     this.$router.push({
      //       path: "/stateRegister",
      //       query: {
      //         userId: id,
      //         userName: userName,
      //       },
      //     });
      //     return that.$message.warning(
      //       "此账号尚未注册或未入住,请点击注册或入住"
      //     );
      //   } else if (res.data.data.auditStatus == 1) {
      //     this.$router.push("/regSussec");
      //   } else if (res.data.data.auditStatus == 2) {
      //     that.$store.commit("user/SET_TOKEN", res.data.data.token);
      //     let wyUser = JSON.stringify(res.data.data.wyUser);
      //     that.$store.commit("user/SET_WYUser", wyUser);
      //     let obj = res.data.data.shopInfo.shopList;
      //
      //     // 获取登录角色的信息
      //     this.$store.dispatch("user/getInfo");
      //     const { type, userName } = res.data.data;
      //     //设置账户角色
      //     this.$store.commit("user/SET_ROLE_TYPE", type);
      //     sessionStorage.setItem("roleType", type);
      //     this.$store.commit("user/SET_USERNAME", userName);
      //     sessionStorage.setItem("userName", userName);
      //     //获取导航信息
      //     this.$store.dispatch("menu/getMenu");
      //     //获取省市区
      //     this.$store.dispatch("user/address");
      //     if (obj.length < 1) {
      //       this.$router.push("/store/storeType");
      //     } else {
      //       this.$store.commit("user/SET_SHOP", res.data.data.shopInfo);
      //     }
      //     //数据字典
      //     that.getDictionary().then(() => {
      //       setTimeout(() => {
      //         if (this.targetPath && this.targetPath != "/login") {
      //           this.$router.push(this.targetPath);
      //         } else {
      //           this.$router.push("/KanbanManagement");
      //           // this.$router.push("/offline/manage");
      //         }
      //       }, 300);
      //     });
      //   } else {
      //     let auditStatus = res.data.data.auditStatus;
      //     that.$router.push("/regBe");
      //     return that.$message.warning(
      //       auditStatus == 3
      //         ? "审核未通过了,请重新填写入驻信息"
      //         : "请填写入驻信息"
      //     );
      //   }
      // } else
      // {
      //   if (res.data.data.auditStatus == 0) {
      //     that.$router.push("/stateRegister");
      //     return that.$message.warning(
      //       "此账号尚未注册或未入住,请点击注册或入住"
      //     );
      //   } else if (res.data.data.auditStatus == 1) {
      //     this.$router.push("/regSussec");
      //   } else if (res.data.data.auditStatus == 3) {
      //     let auditStatus = res.data.data.auditStatus;
      //     that.$router.push("/regBe");
      //     //  that.$router.push("/stateRegister");
      //     return that.$message.warning(
      //       auditStatus == 3
      //         ? "审核未通过了,请重新填写入驻信息"
      //         : "请填写入驻信息"
      //     );
      //   }
      // }
    },
    //忘记密码
    forgetPasswordEtle() {
      this.$router.push("/forgetPassword");
      // to="/forgetPassword"
    },

    // 获取短信验证码
    async getCode() {
      if (this.userName == "" || !this.userName) {
        this.$alert("手机号码不能为空", "提示", {
          confirmButtonText: "确定",
          callback: (action) => {
            this.$message({
              type: "info",
              message: `action: ${action}`
            });
          }
        });
        return;
      }

      this.show = false;
      this.content = this.totalTime + "s后重新发送"; //这里解决60秒不见了的问题
      this.clock = window.setInterval(() => {
        this.totalTime--;
        this.content = this.totalTime + "s后重新发送";
        if (this.totalTime < 0) {
          //当倒计时小于0时清除定时器
          clearInterval(this.clock);
          this.content = "重新发送验证码";
          (this.totalTime = 60), (this.show = true);
        }
      }, 1000);

      // 验证码
      //  const res = await this.$axios.post(`sellercenter/getMerchantCode`,{
      //    telphone:this.userName,
      //    smsType:'_login'
      //  })
      let param = {
        telphone: this.userName,
        smsType: "_login"
      };
      getMerchant(param).then((res) => {
      });
    },
    // 加载数据字典
    async getDictionary() {
      const res = await dataDictionary();
      const { data } = res.data;
      let payload = {
        dict: data.dict,
        sysData: data.sysData
      };
      localStorage.setItem("dict", JSON.stringify(data.dict));
      this.$store.commit("user/INITDICT", payload);
    }
  },
  beforeDestroy() {
    clearInterval(this.clock);
    this.clock = null;
  }
};
</script>

<style lang="less" scoped>
/deep/ .el-row {
  margin: 0 !important;
}

.wizi {
  // width: 480px;
  margin: 0;
}

.wizi .el-input {
  width: 100%;
}

a {
  text-decoration: none;
}

.leftImg {
  position: relative;
  left: 0;
  right: 0;
  margin: 0 auto;
  overflow: hidden;
}

.img-responsive {
  height: 100%;
  width: auto;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.rightView {
  position: relative;
}

.dengLu {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .el-input {
    width: 100%;
  }
}

.biaoti > span:first-child {
  font-size: 2.4rem;
  font-family: Microsoft YaHei;
  font-weight: bold;
  padding-right: 0%;
  padding-left: 0%;
  display: block;
}

.biaoti > span:nth-child(2) {
  width: 14.7rem;
  height: 3.6rem;
  background: #21d08a;
  border-radius: 18px;
  color: #ffffff;
  font-size: 16px;
  text-align: center;
  line-height: 36px;
  display: inline-block;
  margin-left: 0%;
  margin-top: 10px;
}

.dengLu > p:nth-child(2) {
  margin-top: 110px;
  font-size: 28px;
  font-family: Microsoft YaHei;
  font-weight: bold;
}

.dengLu > :nth-child(3) {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.6);
}

.dengLu > span {
  display: block;
  margin-top: 15px;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 16px;
}

.tiaozhuan {
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
}

.tiaozhuan > :first-child {
  padding-left: 1rem;
  color: #ff0000;
}

.tiaozhuan > :last-child {
  color: #1d1d1d;
}

.btn {
  width: 100%;
  margin-top: 47px;
  margin-bottom: 10%;
}

.yanzheng {
  /* position: absolute;
  top: 0;
  right: 0px; */
  background: #e0e1e2;
  border-color: #e0e1e2;
  color: #333333;
  margin-left: 1px;
}

// .sysattribute .el-input {

//   width: 70%;

.sysattribute {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .el-input {
    /* position: relative; */
    width: 36.3rem;
  }
}

.login {
  width: 100vw;
  height: 100vh;
  background: url("~@/assets/bg/pc.png") no-repeat;
  background-size: 100% 100%;
  .flexCol();
  // > img {
  //   width: 47%;
  //   height: auto;
  //   margin: 0px 0 0 9%;
  // }
  // display: flex;
  // // flex-direction: ;
  // align-items: center;
  // justify-content: center;
  position: relative;
}

.login .login-Rights {
  position: absolute;
  bottom: 8.4rem;
  font-size: 1.6rem;
  color: #fff;
  margin: 0 auto;
}

.login-wrap {
  width: 18rem;
  height: 56rem;
  .box(48rem, auto, #fff, 10px);
  padding: 3.7rem 4rem;

  .login-img {
    width: 100%;
    display: flex;
    justify-content: center;

    span {
      width: 29.1rem;
      color: #1b2656;
      font-size: 3.6rem;
      text-align: center;
      white-space: nowrap;
    }
  }

  .login-text {
    width: 100%;
    display: flex;
    justify-content: center;

    .login-tit {
      width: 18.7rem;
      display: inline-block;

      .word(2.2rem, #cccccc, 1.8rem, normal, left);
      margin: 0 auto;
      white-space: nowrap;
      text-align: center;
    }
  }
}

.login-item {
  margin-bottom: 25px;
  position: relative;
  margin-top: 31px;
  border-bottom: 1px solid #e2e2e2;
  .flexSp();

  /deep/ .el-input {
    flex: 1;
    height: 54px;

    input {
      width: 100%;
      height: 63px;
      .word(18px, #000000, 6.3rem, normal, left);
      background: none !important;
      // box-shadow: 0 0 10px 33px #f2f2f2 inset;
      border: 0;
      border-radius: 10px;
      padding-left: 1.8rem;
    }

    // input:-webkit-autofill {
    // opacity:0.2;
    // box-shadow: 0 0 0px 1000px #fff inset !important; //改成你想要的背景色
    // -webkit-text-fill-color: #fff !important;
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      transition-delay: 999999999s;
      transition: color 999999999s ease-out,
      background-color 999999999s ease-out;
      -webkit-transition-delay: 999999999s;
      -webkit-transition: color 999999999s ease-out,
      background-color 999999999s ease-out;
      // -webkit-text-fill-color: #fff;
    }

    // }
    ::placeholder {
      .word(18px, #999999, 54px, normal, left);
    }
  }

  .login-label {
    background: url("~@/assets/bg/icon_zhanghao.png") no-repeat;
    background-size: 100%;
    background-size: cover;
  }
}

.login-item2 {
  margin-bottom: 25px;
  position: relative;
  margin-top: 31px;
  border-bottom: 1px solid #e2e2e2;
  .flexSp();

  /deep/ .el-input {
    flex: 1;
    height: 54px;

    input {
      width: 100%;
      height: 63px;
      .word(18px, #000000, 54px, normal, left);
      background: none !important;
      // box-shadow: 0 0 10px 33px #f2f2f2 inset;
      border: 0;
      border-radius: 10px;
      padding: 1.8rem;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      transition-delay: 999999999s;
      transition: color 999999999s ease-out,
      background-color 999999999s ease-out;
      -webkit-transition-delay: 999999999s;
      -webkit-transition: color 999999999s ease-out,
      background-color 999999999s ease-out;
      // -webkit-text-fill-color: #fff;
    }

    ::placeholder {
      .word(18px, #999999, 54px, normal, left);
    }
  }

  .login-label {
    background: url("~@/assets/bg/icon_mima.png") no-repeat;
    background-size: 100%;
  }
}

.login-item1 {
  margin-bottom: 18px;
  position: relative;
  .flexSp();

  .login-label {
    display: block;

    .box(2.6rem, 3rem, transparent, 0);
    // .word(18px,#000000,54px,normal,left);
    .word(2rem, #000000, 3rem, normal, left);
    // padding: 0 0px 0px 17px;
    margin-bottom: 1px;
    white-space: nowrap;
    box-sizing: initial;
    background: url("~@/assets/bg/code.png") no-repeat;
    background-size: 100%;
  }
}

.login-label {
  display: block;

  .box(2.6rem, 3rem, transparent, 0);
  // .word(18px,#000000,54px,normal,left);
  .word(2rem, #000000, 3rem, normal, left);
  // padding: 0 0px 0px 17px;
  margin-bottom: 1px;
  white-space: nowrap;
  box-sizing: initial;
  background: url("~@/assets/bg/icon_zhanghao.png") no-repeat;
  background-size: 100%;
}

.login-itemBox {
  .flexSt();
  flex: 203 1 0;
  margin-right: 7px;
  border-bottom: 1px solid #e2e2e2;

  /deep/ .el-input {
    flex: 1;
    width: 100%;
    height: 54px;

    input {
      width: 100%;
      height: 54px;
      .word(18px, #000000, 54px, normal, left);
      background: none !important;
      border: 0;

      padding-left: 1.8rem;
    }

    .el-input__inner {
      border-radius: 0;
    }
  }

  ::placeholder {
    .word(18px, #999999, 54px, normal, left);
  }
}

.yanzhengma {
  width: 3rem;
  flex: 124 1 0;
  height: 54px;
  background: #1b2656 !important;
  .word(1.5rem, #FFFFFF, 54px, normal, center);
  padding: 0;
  border-radius: 0.6rem;
}

.login-link {
  margin-bottom: 36px;
  .flexSp();

  a {
    .word(16px, #1b2656, 24px, normal, left);
  }

  a:first-child {
    color: #666666;
  }
}

.login-btn {
  margin: 0;
  padding: 0 4rem;
  height: 5.6rem;

  .el-button {
    .box(100%, 6rem, #1b2656, 36px);
    .word(2rem, #FFFFFF, 5.6rem, normal, center);
    letter-spacing: 0.2rem;
    padding: 0;
  }
}

// =============== 适配
@media screen and (max-width: 1024px) {
  .login {
    min-width: 1024px;
  }
}

/*最大屏幕分辨率为1280px*/
@media screen and (max-width: 1280px) {
  .login > img {
    width: 35%;
  }

  .login-wrap {
    width: 30%;
    padding: 3.7rem 3rem 2.6rem 3rem;

    .login-img span {
      font-size: 2.6rem;
    }

    .login-text {
      .login-tit {
        font-size: 1.4rem;
      }
    }
  }

  .login-wrap .login-img img {
    width: 22.6rem;
    height: 6.3rem;
  }

  .login-item {
    margin-top: 20px;
    height: 5.4rem;
    .flexSp();

    /deep/ .el-input {
      flex: 1;
      height: 5.4rem;

      input {
        width: 100%;
        height: 5.4rem;
        .word(1.6rem, #000000, 54px, normal, left);
        background: none !important;
        border: 0;
        border-radius: 10px;
        padding-left: 1.8rem;
      }

      ::placeholder {
        .word(18px, #999999, 54px, normal, left);
      }
    }
  }

  .login-itemBox {
    .login-label {
      height: 3.4rem;
      line-height: 5.4rem;
      font-size: 1.8rem;
    }

    /deep/ .el-input {
      flex: 1;
      height: 5.4rem;

      input {
        width: 100%;
        height: 5.4rem;
      }
    }
  }

  .yanzhengma {
    height: 4.4rem;
    line-height: 4.4rem;
  }

  .login-btn {
    padding: 0px 4rem;

    .el-button {
      height: 5rem;
      line-height: 5rem;
      font-size: 1.6rem;
    }
  }
}

/*最小屏幕分辨率为1280px且最大为1440px*/
@media (min-width: 1280px) and (max-width: 1440px) {
  .login > img {
    width: 47%;
  }

  .login-wrap {
    width: 30%;
    padding: 3.7rem 3rem 2.6rem 3rem;

    .login-img span {
      font-size: 3rem;
    }

    .login-text {
      .login-tit {
        font-size: 1.8rem;
      }
    }
  }

  .login-wrap .login-img img {
    width: 22.6rem;
    height: 6.3rem;
  }

  .login-item {
    margin-top: 20px;
    height: 5.4rem;
    .flexSp();

    /deep/ .el-input {
      flex: 1;
      height: 5.4rem;

      input {
        width: 100%;
        height: 5.4rem;
        .word(1.6rem, #000000, 54px, normal, left);
        background: none !important;
        border: 0;
        border-radius: 10px;
        padding-left: 1.8rem;
      }

      ::placeholder {
        .word(18px, #999999, 54px, normal, left);
      }
    }
  }

  .login-itemBox {
    .login-label {
      height: 3.4rem;
      line-height: 5.4rem;
      font-size: 1.8rem;
    }

    /deep/ .el-input {
      flex: 1;
      height: 5.4rem;

      input {
        width: 100%;
        height: 5.4rem;
      }
    }
  }

  .yanzhengma {
    height: 5.4rem;
    line-height: 5.4rem;
  }

  .login-btn {
    padding: 0px 6rem;

    .el-button {
      height: 5rem;
      line-height: 5rem;
      font-size: 2rem;
    }
  }
}

/*最小屏幕分辨率为1440px且最大为1680px*/
@media (min-width: 1440px) and (max-width: 1680px) {
  .login > img {
    width: 45%;
  }

  .login-wrap {
    width: 30%;
    padding: 3.7rem 3rem 2.6rem 3rem;

    .login-img span {
      font-size: 3.2rem;
    }

    .login-text {
      .login-tit {
        font-size: 2rem;
      }
    }
  }

  .login-item {
    margin-bottom: 25px;
    position: relative;
    margin-top: 31px;
    border-bottom: 1px solid #e2e2e2;
    .flexSp();

    /deep/ .el-input {
      flex: 1;
      height: 54px;

      input {
        width: 100%;
        height: 63px;
        .word(18px, #000000, 6.3rem, normal, left);
        background: none !important;
        border: 0;
        border-radius: 10px;
        padding: 1.8rem;
      }

      ::placeholder {
        .word(18px, #999999, 54px, normal, left);
      }
    }

    .login-label {
      background: url("~@/assets/bg/icon_zhanghao.png") no-repeat;
      background-size: 100%;
      background-size: cover;
    }
  }

  .login-item2 {
    margin-bottom: 25px;
    position: relative;
    margin-top: 31px;
    border-bottom: 1px solid #e2e2e2;
    .flexSp();

    /deep/ .el-input {
      flex: 1;
      height: 54px;

      input {
        width: 100%;
        height: 63px;
        .word(18px, #000000, 54px, normal, left);
        background: none !important;
        border: 0;
        border-radius: 10px;
        padding: 1.8rem;
      }

      ::placeholder {
        .word(18px, #999999, 54px, normal, left);
      }
    }

    .login-label {
      background: url("~@/assets/bg/icon_mima.png") no-repeat;
      background-size: 100%;
    }
  }

  .login-item1 {
    margin-bottom: 18px;
    position: relative;
    .flexSp();

    .login-label {
      display: block;

      .box(2.6rem, 3rem, transparent, 0);
      // .word(18px,#000000,54px,normal,left);
      .word(2rem, #000000, 3rem, normal, left);
      // padding: 0 0px 0px 17px;
      margin-bottom: 1px;
      white-space: nowrap;
      box-sizing: initial;
      background: url("~@/assets/bg/code.png") no-repeat;
      background-size: 100%;
    }
  }

  .login-label {
    display: block;

    .box(2.6rem, 3rem, transparent, 0);
    // .word(18px,#000000,54px,normal,left);
    .word(2rem, #000000, 3rem, normal, left);
    // padding: 0 0px 0px 17px;
    margin-bottom: 1px;
    white-space: nowrap;
    box-sizing: initial;
    background: url("~@/assets/bg/icon_zhanghao.png") no-repeat;
    background-size: 100%;
  }

  .login-itemBox {
    .flexSt();
    flex: 203 1 0;
    margin-right: 7px;
    border-bottom: 1px solid #e2e2e2;

    /deep/ .el-input {
      flex: 1;
      width: 100%;
      height: 54px;

      input {
        width: 100%;
        height: 54px;
        .word(18px, #000000, 54px, normal, left);
        background: none !important;
        border: 0;

        padding-left: 1.8rem;
      }

      .el-input__inner {
        border-radius: 0;
      }
    }

    ::placeholder {
      .word(18px, #999999, 54px, normal, left);
    }
  }

  .yanzhengma {
    width: 3rem;
    flex: 124 1 0;
    height: 54px;
    background: #1b2656 !important;
    .word(1.5rem, #FFFFFF, 54px, normal, center);
    padding: 0;
    border-radius: 10px;
  }

  .login-link {
    margin-bottom: 36px;
    .flexSp();

    a {
      .word(16px, #1b2656, 24px, normal, left);
    }

    a:first-child {
      color: #666666;
    }
  }

  .login-btn {
    margin: 0;
    padding: 0 5rem;
    height: 5.4rem;

    .el-button {
      .box(100%, 6rem, #1b2656, 36px);
      .word(2rem, #FFFFFF, 5.4rem, normal, center);
      padding: 0;
    }
  }
}

/*最小屏幕分辨率为1920px*/
@media screen and (min-width: 1920px) {
  .login {
    max-width: 100%;
  }

  .login > img {
    width: 40%;
    max-width: 101rem;
  }

  .login-item {
    margin-bottom: 25px;
    position: relative;
    margin-top: 31px;
    border-bottom: 1px solid #e2e2e2;
    .flexSp();

    /deep/ .el-input {
      flex: 1;
      height: 54px;

      input {
        width: 100%;
        height: 63px;
        .word(18px, #000000, 6.3rem, normal, left);
        background: none !important;
        border: 0;
        border-radius: 10px;
        padding: 1.8rem;
      }

      ::placeholder {
        .word(18px, #999999, 54px, normal, left);
      }
    }

    .login-label {
      background: url("~@/assets/bg/icon_zhanghao.png") no-repeat;
      background-size: 100%;
      background-size: cover;
    }
  }

  .login-item2 {
    margin-bottom: 25px;
    position: relative;
    margin-top: 31px;
    border-bottom: 1px solid #e2e2e2;
    .flexSp();

    /deep/ .el-input {
      flex: 1;
      height: 54px;

      input {
        width: 100%;
        height: 63px;
        .word(18px, #000000, 54px, normal, left);
        background: none !important;
        border: 0;
        border-radius: 10px;
        padding: 1.8rem;
      }

      ::placeholder {
        .word(18px, #999999, 54px, normal, left);
      }
    }

    .login-label {
      background: url("~@/assets/bg/icon_mima.png") no-repeat;
      background-size: 100%;
    }
  }

  .login-item1 {
    margin-bottom: 18px;
    position: relative;
    .flexSp();

    .login-label {
      display: block;

      .box(2.6rem, 3rem, transparent, 0);
      // .word(18px,#000000,54px,normal,left);
      .word(2rem, #000000, 3rem, normal, left);
      // padding: 0 0px 0px 17px;
      margin-bottom: 1px;
      white-space: nowrap;
      box-sizing: initial;
      background: url("~@/assets/bg/code.png") no-repeat;
      background-size: 100%;
    }
  }

  .login-label {
    display: block;

    .box(2.6rem, 3rem, transparent, 0);
    // .word(18px,#000000,54px,normal,left);
    .word(2rem, #000000, 3rem, normal, left);
    // padding: 0 0px 0px 17px;
    margin-bottom: 1px;
    white-space: nowrap;
    box-sizing: initial;
    background: url("~@/assets/bg/icon_zhanghao.png") no-repeat;
    background-size: 100%;
  }

  .login-itemBox {
    .flexSt();
    flex: 203 1 0;
    margin-right: 7px;
    border-bottom: 1px solid #e2e2e2;

    /deep/ .el-input {
      flex: 1;
      width: 100%;
      height: 54px;

      input {
        width: 100%;
        height: 54px;
        .word(18px, #000000, 54px, normal, left);
        background: none !important;
        border: 0;

        padding-left: 1.8rem;
      }

      .el-input__inner {
        border-radius: 0;
      }
    }

    ::placeholder {
      .word(18px, #999999, 54px, normal, left);
    }
  }

  .yanzhengma {
    width: 3rem;
    flex: 124 1 0;
    height: 54px;
    background: #1b2656 !important;
    .word(1.5rem, #FFFFFF, 54px, normal, center);
    padding: 0;
    border-radius: 0.6rem;
  }

  .login-link {
    margin-bottom: 36px;
    .flexSp();

    a {
      .word(16px, #1b2656, 24px, normal, left);
    }

    a:first-child {
      color: #666666;
    }
  }

  .login-btn {
    margin: 0;
    padding: 0 4rem;
    height: 5.6rem;

    .el-button {
      .box(100%, 6rem, #1b2656, 36px);
      .word(2rem, #FFFFFF, 5.6rem, normal, center);
      padding: 0;
    }
  }
}
</style>
